import { useSearchParams } from "react-router-dom"
import { ReactDDL } from "../CommonDDL/ReactDDL"
import { HeaderDashboard } from "../HeaderDashboard"
import { Sidebar } from "../Sidebar"
import { useState } from "react"
import AcknowledgeAddPopUp from "./AcknowledgeAddPopUp"
import RefundAddPopUp from "./RefundAddPopUp"

export const ReceivedPayment = () => {
    const [searchParams] = useSearchParams()
    const Name = searchParams.get("Name")

    const [AddPopUpAcknowledge, setAddPopUpAcknowledge] = useState(false)
    const handleAcknowledge = () => {
        setAddPopUpAcknowledge(false)
    }
    const [AddPopUpRefund, setAddPopUpRefund] = useState(false)
    const handleRefund = () => {
        setAddPopUpRefund(false)
    }

    return (
        <>
            <div className="container-scroller">
                <HeaderDashboard />
                <div className="container-fluid page-body-wrapper m-0">

                    <Sidebar active='dashboard' />

                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="card-body grid-margin">
                                <h6 className="d-flex justify-content-between pb-2 green" style={{ fontSize: '19px' }}>{Name}
                                    {/* <button class="btn btn-success py-1" type="submit" */}
                                    {/* // onClick={() => { setProductOffers(true) }} */}
                                    {/* > */}
                                    {/* <i class="fa-solid fa-plus pe-1"></i> Add</button> */}
                                </h6>

                                <div className="row p-2 m-0 bg-white rounded" >
                                    <div className="bgcolor pb-2">
                                        <div className="row">
                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Order Number</label>
                                                <input type="text" class="form-control" />
                                            </div>

                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Consumer Name</label>
                                                <input type="text" class="form-control" />
                                            </div>

                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">From Date</label>
                                                <input type="date" class="form-control" />
                                            </div>

                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">To Date</label>
                                                <input type="date" class="form-control" />
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-12 table_scroll p-0">
                                        <table className="table  text-center bg-white mt-2 " >
                                            <thead className="parrot_green">
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Order Date</th>
                                                    {Name != "Pending Payment" ?
                                                        <th>Order Number</th> :
                                                        <></>
                                                    }
                                                    {Name == "Pending Payment" ?
                                                        <th>Order ID</th> :
                                                        <></>
                                                    }
                                                    <th>Consumer Name</th>
                                                    <th>Product Category</th>
                                                    <th>Product Name</th>
                                                    <th>Product Size Unit</th>
                                                    <th>Quantity</th>
                                                    <th>Product Amount (Rs.)</th>
                                                    <th>Receive Amount(Rs.)</th>
                                                    {(Name == "Received Payment" || Name == "Pending Payment") ?
                                                        <th>Pending Amount(Rs.)</th> :
                                                        <></>}

                                                    {Name == "Acknowledge Payment" ?
                                                        <th>Customer Order Status</th> :
                                                        <></>}

                                                    {Name == "Received Payment" ?
                                                        <th>Action</th> :
                                                        <></>}

                                                    {(Name == "Acknowledge Payment" || Name == "Pending Payment") ?
                                                        <th>Action</th> :
                                                        <></>}
                                                    {Name == "Refund Payment" ?
                                                        <th>Refunded Amount</th> :
                                                        <></>}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr >
                                                    <td> 1 </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    {(Name == "Received Payment" || Name == "Pending Payment") ?
                                                        <td></td> :
                                                        <></>}

                                                    {Name == "Acknowledge Payment" ?
                                                        <td></td> :
                                                        <></>}
                                                    {(Name == "Received Payment" || Name == "Pending Payment") ?
                                                        <td><span className="fs-8 span_btn"
                                                            onClick={() => { setAddPopUpAcknowledge(true) }}>Acknowledge</span>
                                                        </td> :
                                                        <></>}

                                                    {Name == "Acknowledge Payment" ?
                                                        <td><span className="fs-8 span_btn"
                                                            onClick={() => { setAddPopUpRefund(true) }}>Refund</span>
                                                        </td> :
                                                        <></>}
                                                    {Name == "Refund Payment" ?
                                                        <td></td> :
                                                        <></>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {
                AddPopUpAcknowledge ?
                    <AcknowledgeAddPopUp
                        cancelbtn={handleAcknowledge}
                        heading={Name}
                    /> : <></>
            }

            {
                AddPopUpRefund ?
                    <RefundAddPopUp
                        cancelbtn={handleRefund}
                        heading={Name}
                    /> : <></>
            }

        </>
    )
}
import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const PalviAgrico = () => {
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="PalviAgrico" subactive="Verticle" />
                    <HerosectionsAll />
                    <div class="row align-items-center py-5 p-3 px-lg-5 mb-5 mx-auto">
                        <div class="col-12 col-lg-5 pt-lg-5 mt-lg-4 d-flex  justify-content-center align-items-center">
                            <img src="Static/Assets/Img/gallery/farm2-02.png" className="fertilizer_img"
                                alt="" />
                        </div>
                        <div class="col-12 col-lg-7 pt-lg-5 mt-lg-4 pe-lg-5" >
                            <h3 class="fw-bold mb-2 green">	Palvi Agrico </h3>
                            <p class="mb-3" style={{ textAlign: 'justify' }}>Palvi Agrico is the digital bridge between top agricultural brands and rural distributors, dealers, and retailers. Our innovative eCommerce platform ensures that branded pesticide products and farming essentials are delivered faster, cheaper, and hassle-free to the most remote areas. We’re not just a marketplace, we’re an enabler—equipping rural businesses with the tools they need to thrive in a competitive market.</p>
                            <h6 className="pb-1 text-danger fw-bold">Key Features </h6>

                            <p>1. Free and rapid delivery to rural areas.</p>
                            <p>2. Competitive pricing and flexible purchasing options. </p>
                            <p>3. Digital helpline support through app and call center for guidance
                                and troubleshooting.</p>
                            <p>4. Empowerment of local rural economies.</p>

                            {/* <MoreBtn /> */}
                        </div>
                    </div>


                    <Footer />
                </div>
            </div>
        </>
    )
}

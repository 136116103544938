import React, { useState } from 'react'
import AllRoutes from './AllRoutes';

const App = () => {

  return (

    <AllRoutes />
  )
}

export default App
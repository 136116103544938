import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const Vision = () => {
    return (
        <>
            <div className="row"  onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="Vision" subactive="About"/>
                    <HerosectionsAll/>
                    <div class="row align-items-center py-5 p-3 px-lg-5 mb-5">
                        <div class="col-12 col-lg-6 pt-lg-5 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/vision.jpg" className="vision_img" srcset="./assets/img/photos/se3@2x.jpg 2x"
                                alt="" />
                        </div>
                        <div class="col-12 col-lg-6 pt-lg-5 ps-lg-5" >
                            <h3 class="fw-bold mb-4"> Our Vision</h3>
                            <p class="mb-5" style={{ textAlign: 'justify' }}>We envision a future where agriculture is not only more efficient and innovative but also deeply rewarding for everyone involved. Our goal is to be the most trusted partner for farmers, business owners, and consumers, transforming how agrochemicals, farm products, and fresh produce are grown, distributed, and consumed.</p>

                            {/* <MoreBtn /> */}
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}

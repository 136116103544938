import { useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../Footer/Footer"
import { useState } from "react";
import { HeaderEcommerce } from "../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";

export const Register = () => {

    const navigate = useNavigate()

    return (
        <>
            <HeaderEcommerce actives="Register" />
            <div className="row p-4 " onLoad={() => window.scrollTo(0, 0)} style={{ background: '#edecec' }}>
                <div className="col-12 col-lg-5  mx-auto  border2 shdows rounded p-3 py-lg-4 px-lg-5 bg-white" style={{ background: 'rgb(2 84 2 / 6%)' }}>
                    {/* <div className="text-center">
                        <img src="Static/Assets/Img/logo.png" onClick={() => navigate('/')} alt="logo here" className="logo_bg" />
                    </div> */}

                    {/* <div className="pt-2 pt-lg-2">
                        <navs aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item cursor-pointer" onClick={() => navigate('/CheckIOut')}>Cart</li>
                                <li class="breadcrumb-item active" ><a href="" className="green fw-bold">User Details</a> </li>
                                <li class="breadcrumb-item active" aria-current="page">Addresses</li>
                                <li class="breadcrumb-item active" aria-current="page">Order Summary</li>
                            </ol>
                        </navs>
                    </div> */}

                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Name</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword">Email ID</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword">Mobile Number</label>
                    </div>

                    <div class="form-floating mb-3">
                    <textarea class="form-control" placeholder="Delivery Address" id="floatingTextarea"></textarea>
                        <label for="floatingPassword">Delivery Address</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword">Password</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword">Confirm Password</label>
                    </div>

                    <div className="pt-3">
                        <button type="button" class=" parrot_green text-white w-100 fw-bold border2 fs-5 py-2"
                            onClick={() => navigate('/LoginPage')}
                        >Register</button>
                    </div>
                    <p className="pt-4  text-center">Already have an Account? <a href="javascript:void(0)" onClick={() => navigate('/LoginPage')}>Login!</a></p>

                    {/* <p className="pt-4 text-center">New here? <a href="javascript:void(0)" onClick={() => navigate('/Signup')}> Create Account</a></p> */}

                </div>


            </div>


            <Footer />



        </>
    )
}

import { useNavigate } from "react-router-dom"

export const OrderAll = (Props) => {
    const { ProductName, Quantity, Price, deliveryDate, Confirmation, Rating, ImgPath, BuyAgain,title, iconcolor,handlenavigate } = Props
    const navigate = useNavigate()

    return (
        <>

            <div className="row border rounded mb-3 cursor_pointer" onClick={() => handlenavigate(title)}>

                <div className="col-12 col-md-2  ">
                    <img src={ImgPath} alt="" className="products_order" />
                </div>
                <div className="col-12 col-md-3 pt-2">
                    <h6 className="">{ProductName}</h6>
                    <p className="fs-8 mb-0">{Quantity}</p>
                    <p className="text-danger fs-8 pt-2 fw-bold cursor_pointer" onClick={() => navigate("/HERBO_STRICK_Products")}>{BuyAgain}</p>
                </div>
                <div className="col-12 col-md-3 pt-3 ">
                    <h6>{Price}</h6>
                </div>
                <div className="col-12 col-md-4 pt-2 ">
                    <p className="pb-1 fs-6 mb-0"><i className={iconcolor == 'red' ? 'fa-solid fa-circle red fs-7 pe-2' : 'fa-solid fa-circle green fs-7 pe-2'} ></i>{deliveryDate}</p>
                    <p className="pb-1 fs-8 mb-0">{Confirmation}</p>
                    <p className="pb-1 fs-6 mb-0 green"> <i class="fa-solid fa-star fs-8"></i>{Rating}</p>
                </div>

            </div>


        </>
    )
}
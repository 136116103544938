import { MoreBtn } from "../../CommonBtn/MoreBtn"


export const OurValues = () => {
    return (
        <>
            <div class="row px-lg-5 align-items-center py-5 p-3 mt-lg-5" id="our_values">
                {/* <div class="col-lg-6 position-relative order-lg-2">
                    <div class="shape bg-dot primary rellax w-16 h-20" data-rellax-speed="1" style={{ top: "3rem", left: '5.5rem' }}>
                    </div>
                    <div class="overlap-grid overlap-grid-2">
                        <div class="item">
                            <figure class="rounded shadow">
                                <img src="Static/Assets/Img/about2.jpg"
                                    srcset="Static/Assets/Img/about2.jpg 2x" alt="" /></figure>
                        </div>
                        <div class="item">
                            <figure class="rounded shadow"><img src="Static/Assets/Img/about1.jpg"
                                srcset="Static/Assets/Img/about1.jpg 2x" alt="" /></figure>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg-12 ">
                    <h2 className=" pb-3 pt-5 pt-lg-0 fw-bold green text-center" data-aos="fade-up" style={{ fontSize: '26px' }}>
                        <span className="text-danger" >OUR</span> VALUES</h2>
                    <p class="lead fs-lg text-uppercase fw-bold" data-aos="fade-up"><span class="logo_text_color">PALVI AGRO </span> <span
                        class="logo_green_color">CHEMICALS & FERTILIZER</span></p>
                    <div className="row">
                        <div className="col-12 col-lg-6 pt-2 pe-lg-5" data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <p>  <span className="green fw-bold">Customer -</span>  Our products and services exceeds expectations.
                            </p>
                            <p> <span className="green fw-bold">Simplicity -</span> We keep business simple and easy to understand and focus on the key issues not making
                                things complicated and keeping things as simple as it can be.
                            </p>
                            <p>  <span className="green fw-bold">Excellence -</span> Do it better today than yesterday.
                            </p>

                            <p>  <span className="green fw-bold">Compassion -</span> We care for the employees and their family.
                            </p>
                            <p> <span className="green fw-bold">Work & life balance -</span> we
                                make employees by giving happy family time and happy work life.
                            </p>
                            <p> <span className="green fw-bold">Transparency -</span> We share information highlighting problems as well as successes. We do not hide
                                information.
                            </p>

                        </div>
                        <div className="col-12 col-lg-6 pt-2 pe-lg-5" data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">

                            <p> <span className="green fw-bold">Virtue -</span> Own our successes and failuresFreedom to innovate - we are full
                                of ideas.
                            </p>
                            <p> <span className="green fw-bold">Integrity -</span> We are honest in all that we do.
                            </p>
                            <p> <span className="green fw-bold">Supportive -</span> We are always there for each other.
                            </p>
                            <p>  <span className="green fw-bold">Innovation -</span> See the standard and improve on it.
                            </p>
                            <p> <span className="green fw-bold">Team Work and Collaboration -</span> We work together sharing ideas in an open constructive way and together we achieve more.
                            </p>
                            <p> <span className="green fw-bold">Accountability -</span> We do what we say.   </p>
                            <p> <span className="green fw-bold">Respectful -</span> We always look for the best in people. We care for each other. We give respect to one and
                                all.
                            </p>

                        </div>
                    </div>

                </div>
            </div>



        </>
    )
}

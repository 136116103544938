import { Link, useNavigate } from "react-router-dom"

export const Header = ({ actives, subactive }) => {
    const navigate = useNavigate()

    return (
        <>
            <div className="row bg-white shadow sticky">
                {/* <div className="col-12 ">
                    <nav class="navbar navbar-expand-lg navbar-light  pe-lg-3">
                        <div class="container-fluid ">
                            <a href="javascript:void(0)"
                                onClick={() => navigate('/')}>
                                <img src="Static/Assets/Img/logo.png" alt="logo here" class="logo_bg" />
                            </a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li class="nav-item">
                                        <Link to="/?ScreenName=Home"
                                            className=
                                            {actives == "Home" ? "actives" : "text-dark"}
                                        >Home
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link to="/About?ScreenName=About"
                                            className=
                                            {actives == "About" ? "actives" : "text-dark"}
                                        >Our Verticals
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to="/Vision?ScreenName=Vision"
                                            className=
                                            {actives == "Vision" ? "actives" : "text-dark"}
                                        >Vision
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to="/Mission?ScreenName=Mission"
                                            className=
                                            {actives == "Mission" ? "actives" : "text-dark"}
                                        >Mission
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link
                                            to="/ManufacturingUnit?ScreenName=Manufacturing Unit"
                                            className=
                                            {actives == "ManufacturingUnit" ? "actives" : "text-dark"}
                                        >Manufacturing Unit</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link
                                            to="/InvestorDetails?ScreenName=Investor Details"
                                            className=
                                            {actives == "InvestorDetails" ? "actives" : "text-dark"}
                                        > Investor Details </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to="/OurTeam?ScreenName=Our Team"
                                            className=
                                            {actives == "OurTeam" ? "actives" : "text-dark"}
                                        >Our Team</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to="/Contact?ScreenName=Contact"
                                            className=
                                            {actives == "Contact" ? "actives" : "text-dark"}
                                        >Contact</Link>
                                    </li>
                                </ul>
                                <span className="ps-lg-4"> <button type="button" class="btn btn-danger btndanger Login"
                                    onClick={event => {
                                        event.preventDefault();
                                        window.open('/Products?ScreenName=Products', '_blank');
                                    }}
                                >Products</button></span>

                            </div>
                        </div>
                    </nav>
                </div> */}
                <div className="col-12">
                    <nav className="bg-white">
                        <div class="wrapper">
                            <div class="logo" data-aos="zoom-in">
                                <a href="javascript:void(0)"
                                    onClick={() => navigate('/')}>
                                    <img src="Static/Assets/Img/logo.png" alt="logo here" class="logo_bg" />
                                </a>
                            </div>
                            <input type="radio" name="slider" id="menu-btn" />
                            <input type="radio" name="slider" id="close-btn" />
                            <ul class="nav-links pe-0 pt-3">
                                <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>
                                <li data-aos="zoom-in">
                                    <Link to="/?ScreenName=Home"
                                        className=
                                        {actives == "Home" ? "actives" : "text-dark"}
                                    >Home
                                    </Link>
                                </li>

                                <li data-aos="zoom-in">
                                    <a href="#"
                                        //  class="desktop-item"
                                        className={subactive == "Verticle" ? "actives" : "text-dark desktop-item "}
                                    > Our Verticals </a>
                                    <input type="checkbox" id="showDrop" />
                                    <label for="showDrop" class="mobile-item"> Our Verticals </label>
                                    <ul class="drop-menu pt-2" >
                                        <li>
                                            <Link to="/PalviAgroChemicals?Name=Our Verticals&ScreenName=Palvi Agro Chemicals and Fertilizers"
                                                className=
                                                {actives == "PalviAgroChemicals" ? "actives palvi_height" : "text-dark palvi_height"}
                                            > <i class="fa-solid fa-circle fa_circle"></i> Palvi Agro Chemicals <span className="ps-3">and Fertilizers</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/PalviAgrico?Name=Our Verticals&ScreenName=Palvi Agrico"
                                                className=
                                                {actives == "PalviAgrico" ? "actives" : "text-dark"}
                                            ><i class="fa-solid fa-circle fa_circle"></i> Palvi Agrico
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/PalviFarmFresh?Name=Our Verticals&ScreenName=Palvi Farm Fresh"
                                                className=
                                                {actives == "PalviFarmFresh" ? "actives" : "text-dark"}
                                            > <i class="fa-solid fa-circle fa_circle"></i> Palvi Farm Fresh
                                            </Link>
                                        </li>

                                    </ul>
                                </li>

                                <li data-aos="zoom-in">
                                    <a href="#"
                                        //  class="desktop-item"
                                        className={subactive == "About" ? "actives" : "text-dark desktop-item "}
                                    >About Us</a>
                                    <input type="checkbox" id="showDrop" />
                                    <label for="showDrop" class="mobile-item">About Us</label>
                                    <ul class="drop-menu">
                                        <li>
                                            <Link to="/About?Name=About Us&ScreenName=About"
                                                className=
                                                {actives == "About" ? "actives" : "text-dark"}
                                            ><i class="fa-solid fa-circle fa_circle ms-1" ></i> About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/Vision?Name=About Us&ScreenName=Vision"
                                                className=
                                                {actives == "Vision" ? "actives" : "text-dark"}
                                            ><i class="fa-solid fa-circle fa_circle ms-1"></i> Vision
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/Mission?Name=About Us&ScreenName=Mission"
                                                className=
                                                {actives == "Mission" ? "actives" : "text-dark"}
                                            ><i class="fa-solid fa-circle fa_circle ms-1"></i> Mission
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/Goals?Name=About Us&ScreenName=Goals"
                                                className=
                                                {actives == "Goals" ? "actives" : "text-dark"}
                                            ><i class="fa-solid fa-circle fa_circle ms-1"></i> Goals
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li data-aos="zoom-in">
                                    <Link
                                        to="/ManufacturingUnit?ScreenName=Manufacturing Unit"
                                        className=
                                        {actives == "ManufacturingUnit" ? "actives" : "text-dark"}
                                    >Manufacturing Unit</Link>

                                </li>
                                <li data-aos="zoom-in">
                                    <Link
                                        to="/InvestorDetails?ScreenName=Investor Details"
                                        className=
                                        {actives == "InvestorDetails" ? "actives" : "text-dark"}
                                    > Investor Details </Link>

                                </li>
                                <li data-aos="zoom-in">
                                    <Link to="/Products?ScreenName=Products"
                                        className=
                                        {actives == "Products" ? "actives" : "text-dark"}
                                    >Products</Link>

                                </li>
                                <li data-aos="zoom-in">
                                    <Link to="/OurTeam?ScreenName=Our Team"
                                        className=
                                        {actives == "OurTeam" ? "actives" : "text-dark"}
                                    >Our Team</Link>

                                </li>
                                <li data-aos="zoom-in">
                                    <Link to="/Contact?ScreenName=Contact"
                                        className=
                                        {actives == "Contact" ? "actives" : "text-dark"}
                                    >Contact</Link>

                                </li>
                                {/* <li><a href="#" className="bg-danger text-white roundeed" onClick={event => {
                                    event.preventDefault();
                                    window.open('/Products?ScreenName=Products', '_blank');
                                }}>Products</a></li> */}


                            </ul>

                            <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

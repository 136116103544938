
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const SUPER_CAP = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/SUPER_CAP-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3  pt-lg-5 mt-lg-5">SUPER-CAP</h4>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Composition :</h5>
                    <ul>
                        <li> Supercap is a 100% organic product.</li>
                        <li> Supercap improves cytokinin balance in plants.</li>
                        <li> Supercap is used for grape crop.</li>
                        <li> It is especially used to increase the length of beads.</li>
                        <li> Increases the length and width of grape seed cells.</li>
                        <li> The points of the grape beads are maintained till the end.</li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage  :</h5>
                    <ul>
                        <li>
                        Spray 1 liter per acre
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

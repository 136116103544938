import React, { useEffect, useState } from "react";
import { HeaderDashboard } from "../HeaderDashboard";
import { Sidebar } from "../Sidebar";
import { useSearchParams } from "react-router-dom";
import { ReactDDL } from "../CommonDDL/ReactDDL";
import AddPopUpReceiveOrder from "./AddPopUpReceiveOrder";

export default function ReceiveOrder() {
    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')

    const [addPopUpReceiveOrder, setaddPopUpReceiveOrder] = useState(false)
    const handleReceiveOrder = () => {
        setaddPopUpReceiveOrder(false)
    }

    return (
        <>
            <div className="container-scroller">
                <HeaderDashboard />
                <div className="container-fluid page-body-wrapper m-0">

                    <Sidebar active='dashboard' />

                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="card-body grid-margin">
                                <h6 className="green" style={{ fontSize: '19px' }}>{Name} </h6>

                                <div className="row p-2 m-0 bg-white rounded" >
                                    <div className="bgcolor pb-2">
                                        <div className="row">
                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Order No</label>
                                                <ReactDDL />
                                            </div>

                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Consumer Name </label>
                                                <ReactDDL />
                                            </div>

                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">From Date</label>
                                                <input type="date" class="form-control" />
                                            </div>

                                            <div class="my-1 col-12 col-md-3 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">To Date</label>
                                                <input type="date" class="form-control" />
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-12 table_scroll p-0">
                                        <table className="table  text-center bg-white mt-2 " >
                                            <thead className="parrot_green">
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Order Date</th>
                                                    {(Name == "Received Order" || Name == "Pending Order" || Name == "Rejected Order" || Name == "Cancelled Order") ?
                                                        <th>Order Number</th> :
                                                        <></>
                                                    }
                                                    {Name == "Approved Order" ?
                                                        <th>Order Id</th> :
                                                        <></>
                                                    }
                                                    <th>Consumer Name</th>
                                                    {(Name == "Received Order" || Name == "Pending Order") ?
                                                        <th>Address</th> :
                                                        <></>
                                                    }
                                                    <th>Mobile Number</th>
                                                    <th>Email</th>
                                                    <th>Company Name</th>
                                                    <th>Product Category</th>
                                                    <th>Product Name</th>
                                                    <th>Product Size-Unit</th>
                                                    <th>Quantity</th>
                                                    {(Name == "Received Order" || Name == "Pending Order") ?
                                                        <th>Action</th> :
                                                        <></>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td> 1 </td>
                                                    <td></td>
                                                    {(Name == "Received Order" || Name == "Pending Order" || Name == "Rejected Order" || Name == "Cancelled Order") ?
                                                        <td></td> :
                                                        <></>
                                                    }
                                                    {Name == "Approved Order" ?
                                                        <td></td> :
                                                        <></>
                                                    }
                                                    {(Name == "Received Order" || Name == "Pending Order") ?
                                                        <td></td> :
                                                        <></>
                                                    }
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td> <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    {(Name == "Received Order" || Name == "Pending Order") ?
                                                        <td>
                                                            <i class="fa-solid fa-circle-check cursor_pointer text-success mx-1"
                                                                onClick={() => { setaddPopUpReceiveOrder(true) }}></i>
                                                            <i class="fa-solid fa-circle-xmark cursor_pointer text-danger mx-1"></i>
                                                        </td> :
                                                        <></>}

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div >
            </div >


            {
                addPopUpReceiveOrder ?
                    <AddPopUpReceiveOrder
                        cancelbtn={handleReceiveOrder}
                        heading={Name}
                    /> : <></>
            }

        </>
    )
}

import { useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../Footer/Footer"
import { Header } from "../IndexPage/Header/Header"
import { useState } from "react";
import { HeaderEcommerce } from "../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";

export const TermsCondition = () => {

    const navigate = useNavigate()

    return (
        <>
            <Header actives="Login" />
            <div className="row p-4 " onLoad={() => window.scrollTo(0, 0)} style={{ background: '#edecec' }}>
                <div className="col-12 col-lg-10 mx-auto  p-lg-5">

                    <h3 className="fw-bold pb-4 green text-center"><span className="text-danger">Terms</span> & Condition</h3>
                    <h6 className="fw-bold">Disclaimer</h6>
                    <p>The website (<a onClick={()=> navigate ('/')} className="cursor_pointer">"https://palviagro.com/"</a> ) is operated by Palvi Agro Chemicals and Fertilize having its registered office located Plot No.277 RK Nagar Society, Society No.5 Karvir Opposite Nayara Petrol Pump Morewadi Kolhapur 416007. Please read the Conditions of Use document carefully before using the palviagro.com website. By using the palviagro.com, you signify your agreement to be bound by Palvi Agro Chemicals and Fertilizer’s Conditions of Use.</p>

                    <h6 className="fw-bold">Your Account </h6>
                    <p>While Using website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorised access to your account. You agree to accept responsibility for all activities that occur under your account or password.</p>

                    <h6 className="fw-bold">Access to Site</h6>
                    <p>You can access site user friendly and use on multiple platforms.</p>

                    <h6 className="fw-bold">Purchase and delivery of products and services.</h6>
                    <p>Easy to navigate, place orders quickly and fast shipping of products.</p>

                    <h6 className="fw-bold">Multiple Payment Method</h6>
                    <p>You can pay order via multiple method of payments.</p>

                    <h6 className="fw-bold">Reviews and Rating </h6>
                    <p>You can post reviews and rating to the products.</p>

                    <h6 className="fw-bold">Communication</h6>
                    <p>Access dedicated customer support to assist with orders, product Enquiries,issues, providing personalized service tailored to your needs.</p>

                    <h6 className="fw-bold">Flexible Product Return Policy</h6>
                    <p>Enjoy a straightforward product return policy, allowing you to return products easily if they don't meet your expectations or are damaged, ensuring peace of mind with your purchases.</p>

                    <h6 className="fw-bold">Health & Safety</h6>
                    <p>    While Purchasing any products,you have to understand health and safety Guidelines.</p>
                </div>

            </div>


            <Footer />



        </>
    )
}




export const OurPhotos = () => {
    return (
        <>
            <div className="row py-5  p-3 p-lg-5 mb-5">


                <h2 className=" fw-bold green text-center pt-5" style={{fontSize:'26px'}}>
                    <span className="text-danger">OUR</span>  GALLERY </h2>

                <div className="col-12 col-lg-4 pt-4">
                    <div className="GALLERY">
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-4">
                    <div className="GALLERY2">
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-4">
                    <div className="GALLERY3">
                    </div>
                </div>

                {/* <div className="col-12 col-lg-4 pt-4">
                    <div className="GALLERY">
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-4">
                    <div className="GALLERY2">
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-4">
                    <div className="GALLERY3">
                    </div>
                </div> */}

            </div>
        </>
    )
}


export const AboutUs = () => {
    return (
        <>
            <div class="row px-lg-5  align-items-center pt-5">
                {/* <div class="col-lg-6 position-relative order-lg-2">
                    <div class="shape bg-dot primary rellax w-16 h-20" data-rellax-speed="1" style={{ top: "3rem", left: '5.5rem' }}>
                    </div>
                    <div class="overlap-grid overlap-grid-2">
                        <div class="item">
                            <figure class="rounded shadow">
                                <img src="Static/Assets/Img/about2.jpg"
                                    srcset="Static/Assets/Img/about2.jpg 2x" alt="" /></figure>
                        </div>
                        <div class="item">
                            <figure class="rounded shadow"><img src="Static/Assets/Img/about1.jpg"
                                srcset="Static/Assets/Img/about1.jpg 2x" alt="" /></figure>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg-12 " >
                    {/* <img src="./assets/img/icons/megaphone.svg" class="svg-inject icon-svg icon-svg-md mb-4" alt="" /> */}

                    <h2 className="text-center fw-bold green pb-3" data-aos="zoom-out-up" style={{ fontSize: '26px' }}>
                        <span className="text-danger" > ABOUT </span> US</h2>
                    <p class=" p-3 p-lg-0" style={{ textAlign: 'justify' }} data-aos="fade-up">
                        <span className="green fw-bold"> PALVI AGRO CHEMICALS & FERTILIZER </span> is pioneer in developing
                        innovative products for comprehensive crop production. We are providing solutions for various crop
                        diseases that will help farmers grow disease free and healthy crops. We have come up with exceptional
                        and wide variety Bio-chemicals and fertilizer to  make our farmers as rich, healthy, and prosperous as
                        they aspire to be by helping them produce disease free healthy, weighty and fruitful crops and make
                        farming soil fertile and reproductive as it should be.

                        Our products are suitable for all cropping conditions around the world to improve crop productivity
                        and soil health. Our products tailered/designed to produce more crop yield per unit area while
                        improving the eco system and bringing sustainability to agriculture. Currently we are catering
                        to Indian markets  in few State  and desire to few parts of the  countries around the world.</p>


                    <div className="row pt-4  p-3 p-lg-0">
                        <div className="col-12 col-lg-3 py-2" data-aos="zoom-in">
                            <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                <h2 className=" fw-bold">  25 + </h2>
                                <span className="fw-bold green">  Years Experience</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 py-2" data-aos="zoom-in">
                            <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                <h2 className=" fw-bold">  110 +</h2>
                                <span className="fw-bold green"> Agriculture Products</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 py-2" data-aos="zoom-in">
                            <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                <h2 className=" fw-bold">  25K + </h2>
                                <span className="fw-bold green">      Satisfied Customers</span>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 py-2" data-aos="zoom-in">
                            <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                <h2 className=" fw-bold">  7 +</h2>
                                <span className="fw-bold green">States</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </>
    )
}

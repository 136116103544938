import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const PARAPAL_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/PARAPALs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">PARAPAL</h4>
                    <h6 className="py-2 ps-3">Paraquat Dichloride 24% SL.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>
                            Parapal is non selective contact herbicide. It control all types of weeds very quickly.
                        </li>
                        <li>  It works very fast after its application on weeds.</li>
                        <li>  It is not easily washed by rain because of
                            their quick penetration power.</li>
                        <li> It is very safe to soil because when it comes in to contact with
                            soil it become inactive so it very effective for both water and environment. For beter result
                            apply it when weeds become 3 to 4 leaf stage.</li>
                        <li> It is best for weed control when it its use in
                            standing crop with the help of hood.</li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Dose :</h5>
                    <ul>
                        <li>
                            750 to 900 ml per acre.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            500 ml, 1 ltr, 5 ltr.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

export const MultiSelectDDL = (props) => {
    const { GardenSelected, handleMultiGardenData } = props;
    const [options, setOptions] = useState([]);

    const DDLData = [
        { id: "1", GardenName: "FriendShip Garden" },
        { id: "2", GardenName: "Rajmata Jijau Garden" },
        { id: "3", GardenName: "Lakshmibai Barne Garden" },
    ];

    useEffect(() => {
        const mappedOptions = DDLData.map(i => ({
            label: i.GardenName,
            value: i.id
        }));
        setOptions(mappedOptions);
    }, []);

    return (
        <>
            <MultiSelect
                options={options}
                value={GardenSelected}
                onChange={handleMultiGardenData}
                labelledBy="Select Gardens"
            />
        </>
    );
};


import { useSearchParams } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const ThreeD_WEED_Products = () => {
    const [searchparams] =useSearchParams()
    const ScreenName= searchparams.get('ScreenName')

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4  overflow_products border shdows">
                    <img src="Static/Assets/Img/products/3D WEEDs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">{ScreenName}</h4>
                    <h6 className="py-2 ps-3">2,4-D Amine Salt 58% SL.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Dose :</h5>
                    <ul>
                        <li>
                            40 to 50 ml in 15 il water/ spray pump in Wheat, Rice, Maize, Grassland 150 to 200 ml in 15 ltr
                            water/ spray pump in Sugarcane or as been recommended by local authorities
                        </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Mode of Action :</h5>
                    <ul>
                        <li>
                            3D WEED is selective, systematic, post emergent herbicides, growth
                            inhibitors.It controls post emergence annual and perennial broad leaves weeds in cereals,
                            tea, wheat, sorghum, rice, maize, millets, grasslands, established turf, aquatic broad leaves
                            weeds, grass seeds crops, orchards (pome & stone fruits), cranberries, asparagus, sugarcane,
                            rice forestry and non-crop land etc.
                        </li>
                        <li>It should be used in accordance with climatic conditions and approval of local authorities.</li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability  :</h5>
                    <ul>
                        <li>
                            250ml, 500 ml, 1 ltr., 5 ltr.
                        </li>

                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

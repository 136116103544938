import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const Goals = () => {
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="Goals" subactive="About" />
                    <HerosectionsAll />
                    <div class="row align-items-center  py-5 p-3 px-lg-5 mb-5">
                        <div class="col-12 col-lg-4 pt-lg-5  d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/goals.jpg" className="vision_img"
                                alt="" />
                        </div>
                        <div class="col-12 col-lg-8 pt-lg-5 ps-lg-5" >
                            <h3 class="fw-bold mb-3 pt-5">Our  Goals </h3>
                            <div className="row pt-lg-4">

                                <div className="col-12 col-lg-6">
                                    <div className="row pe-lg-2">
                                        <div className="col-2 ">
                                            <img src="Static/Assets/Img/farmer.png" width={35} alt="" />
                                        </div>
                                        <div className="col-10">
                                            <h6 class="fw-bold mb-2"> Empowerment of Farmers </h6>
                                            <p class="mb-5 text_color" style={{ textAlign: 'justify' }}>Enhance the quality of life for farmers by providing them with effective products and fair pricing for their produce.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="row ps-lg-2">
                                        <div className="col-2 ">
                                            <img src="Static/Assets/Img/Ecosystem_Innovation.png" width={38} alt="" />
                                        </div>
                                        <div className="col-10">
                                            <h6 class="fw-bold mb-2"> Ecosystem Innovation </h6>
                                            <p class="mb-5 text_color" style={{ textAlign: 'justify' }}>Build a fully integrated, tech-driven agricultural ecosystem connecting stakeholders from farm to market.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="row pe-lg-2">
                                        <div className="col-2 ">
                                            <img src="Static/Assets/Img/Sustainability_Growth.png" width={35} alt="" />
                                        </div>
                                        <div className="col-10">
                                            <h6 class="fw-bold mb-2"> Sustainability and Growth </h6>
                                            <p class="mb-5 text_color" style={{ textAlign: 'justify' }}>Foster a greener, more sustainable future through environmentally friendly agrochemicals and processes.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="row ps-lg-2">
                                        <div className="col-2 ">
                                            <img src="Static/Assets/Img/Seamless_Access.png" width={35} alt="" />
                                        </div>
                                        <div className="col-10">
                                            <h6 class="fw-bold mb-2"> Seamless Access </h6>
                                            <p class="mb-5 text_color" style={{ textAlign: 'justify' }}>Ensure that distributors, dealers, and retailers can access high-quality products and services effortlessly.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="row pe-lg-2">
                                        <div className="col-2 ">
                                            <img src="Static/Assets/Img/FreshnessAndFairness.png" width={35} alt="" />
                                        </div>
                                        <div className="col-10">
                                            <h6 class="fw-bold mb-2"> Freshness and Fairness </h6>
                                            <p class="mb-5 text_color" style={{ textAlign: 'justify' }}> Deliver the freshest farm produce to consumers while ensuring farmers receive fair compensation.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* <h3 class="fw-bold mb-2"> Our Vision</h3>
                            <p class="mb-5" style={{ textAlign: 'justify' }}>We envision a future where agriculture is not only more efficient and innovative but also deeply rewarding for everyone involved. Our goal is to be the most trusted partner for farmers, business owners, and consumers, transforming how agrochemicals, farm products, and fresh produce are grown, distributed, and consumed.</p> */}

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

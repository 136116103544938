





import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const POTA_GREEN = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/POTA_GREEN-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> POTA GREEN <span className="fs-6">(Organic sweetner/ Colouring compound)</span></h4>
                    <h6 className="py-2 ps-3">
                    Pota green is a probiotic base amino acid. It is easily avilable to the plant.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>It covers the plants nutritional need which is defecient & helps platto survive in
                        adverse climate.</li>
                        <li>It is very usefull in fruit size development.</li>
                        <li>It increses Sweetness in the fruits.</li>
                        <li>It increse weight of the fruits & helpfull for colour and shining in the fruits.</li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                        Recommended for all Vegetables & fruits
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        500 ml/ Acre for spraying.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                        500ml., 1 ltr.
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}





import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const SIZE_PLUS = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/SIZE_PLUS-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> SIZE PLUS <span className="fs-6">(Rooting & Vegitative Growth Stimulant)</span></h4>
                    <h6 className="py-2 ps-3">
                        Size Plus is a combination of seaweed
                        extract & humic acid.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>Develop root initiation.</li>
                        <li>Drough resistance capacity.</li>
                        <li> Promotes absorption of soil nutrients. </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        2 Ltr / Acre for drenching, 1 Ltr / acre for spraying
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                        1 ltr., 5 ltr.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

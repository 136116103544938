
export const HeroSection = () => {
    return (
        <>
            <div className="row">
                <div className="col-12 px-0">
                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-aos="fade-up">
                                <img src="Static/Assets/Img/bg-01.png" class="d-block w-100 herosection_img" alt="..." />
                            </div>
                            <div class="carousel-item " data-aos="fade-up">
                                <img src="Static/Assets/Img/herosection_img3.jpg " class="d-block w-100 herosection_img" alt="..." />
                            </div>
                            <div class="carousel-item " data-aos="fade-up">
                                <img src="Static/Assets/Img/background_img2.jpg" class="d-block w-100 herosection_img" alt="..." />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

import { Footer } from "../Footer/Footer"
import { HeroSection } from "./HeroSection/HeroSection"
import { Header } from "./Header/Header"
import { InnovationandTechnology } from "./InnovationandTechnology/InnovationandTechnology"
import { Navbar } from "./Navbar/Navbar"
import { OurSaleNetworks } from "./OurSaleNetworks/OurSaleNetworks"
import { OurValues } from "./OurValues/OurValues"
import { OurProducts } from "./Products/OurProducts"
import { AboutUs } from "./AboutUs/AboutUs"
import { OurPhotos } from "./OurPhotos/OurPhotos"
import { Vertical } from "./Vertical/Vertical"
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { useEffect } from "react"

export const IndexPage = () => {
    // useEffect(() => {
    //     AOS.init();
    //   }, []);
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="Home"/>
                    <HeroSection />
                    <AboutUs />
                    <OurProducts />
                    <OurValues />
                    <Vertical />
                    {/* <OurPhotos /> */}
                    <OurSaleNetworks />
                    <InnovationandTechnology />
                    <Footer />

                </div>
            </div>
        </>
    )
}

import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const OurTeam = () => {

    return (
        <> <div className="row" onLoad={() => window.scrollTo(0, 0)}>
            <div className="col-12">
                <Navbar />
                <Header actives="OurTeam" />
                <HerosectionsAll />

                <div className="row" >


                    <section class="our-webcoderskull padding-lg my-5 bg-danger">
                        <div class="px-3 p-lg-5">

                            <ul class="row">
                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Hanumant_Bhosle-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">Hanumant Bhosale</h3>
                                        <p>Mentor & Technical Support</p>

                                    </div>
                                </li>
                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Rahul Jadhav-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">Rahul Jadhav </h3>
                                        <p>Managing Director</p>
                                    </div>
                                </li>
                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Nitin_Ingole-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4"> Nitin Ingole </h3>
                                        <p>Chief Finance Officer</p>
                                    </div>
                                </li>
                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/SANDIP_SADALE-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4"> Sandip Sadale  </h3>
                                        <p>Operation Head</p>
                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/P_SALMON-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">P Salmon</h3>
                                        <p>Assistant General Manager</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Anwar Basha-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">V. Anwar Basha </h3>
                                        <p>Zonal Business Manager </p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/PERIYASAMY_G-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">Periyasamy G. </h3>
                                        <p>Regional Business Manager</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Pandurang mate-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">Pandurang Mate </h3>
                                        <p>Area Business Manager</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/SELVARAJ_THANGAPAN-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">Selvaraj Thangapand  </h3>
                                        <p>Area Business Manager</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/N.VidyaSagarReddy-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">N. Vidya Sagar Reddy </h3>
                                        <p>Area Business Manager</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Purshottam Koli-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">Purshottam Koli</h3>
                                        <p>Admin Executive</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/N.V.NageswaraRao-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4">N. V. Nageswara Rao</h3>
                                        <p>Area Business Manager</p>

                                    </div>
                                </li>

                                <li class="col-12 col-md-6 col-lg-3 pt-4">
                                    <div class="cnt-block equal-hight rounded" style={{ height: '280px' }}>
                                        <figure className="pt-4">
                                            <img src="Static/Assets/Img/Team/Gurav Amar-01.png" class="img-responsive" alt="" /></figure>
                                        <h3 className="fw-bold green pt-4"> Amarnath Gurav </h3>
                                        <p>Regional Business Manager</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>

                </div>


                <Footer />
            </div>
        </div>

        </>
    )
}

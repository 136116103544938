
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const LEGEND = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/LEGEND-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3  pt-lg-5 ">LEGEND</h4>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Composition :</h5>
                    <ul>
                        <li> Legend is an organic product.</li>
                        <li> The legend is an amino acid derivative.</li>
                        <li> Legend serves a multi-purpose function.</li>
                        <li> Legend mainly reduces heat, cold and water parties.</li>
                        <li> Auto action increases enzymatic reactions.</li>
                        <li> Among the crops, the number of flowers and leaves were the main indicators.</li>
                        <li> In grapevines, rotting occurs and petals break and relax. </li>
                        <li>There was swelling of the beads in the grapes.</li>
                        <li>Pomegranate cultivars vary in female flower size, fruit size, and gloss was.</li>
                        <li>In vegetables there is budding, female flower budding and fertilization.</li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage  :</h5>
                    <ul>
                        <li>
                        Spray 100 ml per acre.
                        </li>
                    </ul>
                   

                </div>

            </div>


            <Footer />


        </>
    )
}


import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const MAGISTRATE_SP= () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/MAGISTRATE_SP-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> MAGISTRATE SP <span className="fs-6"></span></h4>
                    {/* <h6 className="py-2 ps-3">
                        Petiole is powerful biostimulant for enhanced yield when it applied it acts either by
                        Adsorption or absorption by plant cells.</h6> */}
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>Magistrate sp it maintains nutrient balance in plants it increses vegetative growth and
                        photosynthesis.</li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                        Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        250 gm / Acre for Spraying.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                        250gm, 500gm.
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}



import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const CHLOPAL_SUPER_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4  overflow_products  border shdows">
                    <img src="Static/Assets/Img/products/CHLOPALSUPERs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5  overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">CHLOPAL SUPER</h4>
                    <h6 className="py-2 ps-3">(Chlorpyriphos-20% EC)</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Specifications :</h5>
                    <ul>
                        <li>
                            Broad spectrum insecticide with quick knock down effects. Chlopal Super is best suited for
                            soil drenching on any crop to control soil pests. Effective as seeding dip in rice (1ml/1liter of
                            water)It can be used in building for treatments against termites.It acts as Cholinesterase
                            Inhibitor.
                        </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Category :</h5>
                    <ul>
                        <li>
                            Organophosphate group of insecticide.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2"> Mode of Action :</h5>
                    <ul>
                        <li>
                            Contact, Stomach and Respiratory Action.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2"> Phytotoxicity :</h5>
                    <ul>
                        <li>
                            Not phytotoxic when applied as recommended
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2"> Availability :</h5>
                    <ul>
                        <li>
                            250ml, 500ml, 1liter
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2"> Doses :</h5>
                    <ul>
                        <li>
                            300 to 500 liter per acre.
                        </li>

                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const CYTO_BOOST_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                <img src="Static/Assets/Img/products/CYTOBOOSTs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5  overflow_products">
                    <h4 className="text-danger fw-bold  ps-3 ">CYTO BOOST</h4>
                    <h6 className="py-2 ps-3">Gibberellic Acid- 0.001% L</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>
                            <span className="fw-bold">Cyto Boost</span>   is a highly effective growth regulator containing Gibberellic acid 0.001% L as
                            active ingredient along with yeast extract marine brown Algae extract, FeSO 7H,O, MnSO,
                            7H,O.
                        </li>
                        <li> It improves the physiological efficiency of the crop by the stimulating the harmonal
                            and enzymatic harmonal activity and increases the yield and quality of the products and
                            helps the growth by its effect on cell growth and cell elongation.</li>


                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Category :</h5>
                    <ul>
                        <li>
                            Plant growth regulator
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Vegetables, Groundnut, Horticulture crops, Oil seeds and All crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Doses :</h5>
                    <ul>
                        <li>
                            180ml / hectare.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            100 ml, 250 ml, 500 ml, 1 ltr
                        </li>

                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}



import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const JET_PLUS_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products  border shdows">
                    <img src="Static/Assets/Img/products/JETPLUSs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5  overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">JET PLUS </h4>
                    <h6 className="py-2 ps-3">Emamectin Benzoate-5 % SG</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Specifications :</h5>
                    <ul>
                        <li>
                            Jet Plus is Larvicide having Anti-Feedent actions. It protects plant from feeding damage within
                            few hours of application.
                        </li>
                        <li>Control range of larval instars by contact activity. • Long residual Ingestion activity.</li>
                        <li>It is rapidly absorbed into foliage and has translaminer action.It is a naturally derived product
                            that does not harm the beneficial insects.</li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Category  :</h5>
                    <ul>
                        <li>
                            Avermectin group of insects.   </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Mode of Action :</h5>
                    <ul>
                        <li>
                            Non systemic penetration by Translaminar movement.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Compatibility :</h5>
                    <ul>
                        <li>It is a compatible with the all available insecticides andfungicides which are
                            highly acidic and alkaline in nature.  100 ml, 250ml, 500 ml,1ltr.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Phytotoxicity :</h5>
                    <ul>
                        <li>
                            Not Phytotoxic when applied in recommended dose.
                        </li>

                    </ul>  <h5 className=" parrot_green py-2  ps-3 text-white border2">Avaliability  :</h5>
                    <ul>
                        <li>
                            50 gm, 100 gm, 250 gm, 500 gm, 1kg.
                        </li>

                    </ul>
                </div>

            </div>


            <Footer />


        </>
    )
}

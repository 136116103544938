
import { useSearchParams } from "react-router-dom";
import { HeaderDashboard } from "../HeaderDashboard";
import { ReactDDL } from "../CommonDDL/ReactDDL";
import { Sidebar } from "../Sidebar";

const OrderTracking = () => {

    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')

    return (<>

        <div className="container-scroller">
            <HeaderDashboard />
            <div className="container-fluid page-body-wrapper m-0">

                <Sidebar active='dashboard' />

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="card-body grid-margin">
                            <h6 className="d-flex justify-content-between green" style={{ fontSize: '19px' }}>Track Orders
                                {/* <button class="btn btn-success py-1" type="submit"
                                >
                                    <i class="fa-solid fa-plus pe-1"></i> Add</button> */}
                            </h6>

                            <div className="row p-2 m-0 bg-white rounded" >
                                <div className="bgcolor pb-2">
                                    <div className="row">
                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Order Date</label>
                                            <input type="date" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Order Number</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Consumer Name</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Consumer Address</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Mobile Number</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Email</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Company Name </label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product category</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Name</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Qty</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Mode of Shipment</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Estimated Delivery Date</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Order Status</label>
                                            <input type="text" class="form-control" />
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 table_scroll p-0">
                                    <table className="table  text-center bg-white mt-2 " >
                                        <thead className="parrot_green">
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Order Date</th>
                                                <th>Order Number</th>
                                                <th>Consumer Name</th>
                                                <th>Consumer Address</th>
                                                <th>Mobile Number</th>
                                                <th>Email</th>
                                                <th>Company Name</th>
                                                <th>Product category</th>
                                                <th>Product Name</th>
                                                <th>Qty</th>
                                                <th>Mode of Shipment</th>
                                                <th>Estimated Delivery Date</th>
                                                <th>Order Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td> 1 </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>

            </div>
        </div>


    </>);
}

export default OrderTracking;

import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const PESTIGO_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/PESTIGOs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-3 p-lg-5  overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">PESTIGO</h4>
                    <h6 className="py-2 ps-3">Lambdacyhalothrin-5% EC</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Specifications :</h5>
                    <ul>
                        <li>
                            Pestigo is triple action insecticide-contact, systemic and fumigant action.
                            Broad spectrum and fast degrading insecticide.
                        </li>
                        <li>
                            It has action on insects and is therefore most valuable as a cleanup spray in the event of a
                            heavy infestation of pests.     </li>
                        <li> It has quick knock down action.    </li>
                        <li>It can use for controlling stored grain pests.</li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Mode of action :</h5>
                    <ul>
                        <li>
                            Systemic, Contact & Fumigant action
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Compatibility :</h5>
                    <ul>
                        <li>
                            It is a compatible with the all available insecticides and fungicides which are
                            highly acidic and alkaline in nature.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Phytotoxicity :</h5>
                    <ul>
                        <li>
                            Not Phytotoxic when applied in recommended dose.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            100 ml, 250 ml, 500 ml, 1 ltr.
                        </li>

                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}


import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const CHLOPAL_BOOST_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products  border shdows">
                    <img src="Static/Assets/Img/products/CHLOPALBOOSTs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5  overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">CHLOPAL BOOST</h4>
                    <h6 className="py-2 ps-3">Chlorpyriphos-50% + Cypermethrin-5% </h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Specifications :</h5>
                    <ul>
                        <li>
                            Chlopal Boost is a combination of Chlorpyriphos and Cypermethrin. It is Broad spectrum
                            insecticide.It has quick knock down action against targeted pests. It affects both the axonic
                            and synaptic transmission of the nerve impulses in the insect's nervous system.
                        </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Category :</h5>
                    <ul>
                        <li>
                            Organophosphate synthetic pyrethroid group of insecticide.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Mode of Action  :</h5>
                    <ul>
                        <li>
                            Contact and Systemic poison.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Compatibility  :</h5>
                    <ul>
                        <li>
                            It is compatible with all insecticides and fungicides in the market except
                            those which are highly acidic or alkaline in nature.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Phytotoxicity :</h5>
                    <ul>
                        <li>
                            Not Phytotoxic when applied in recommended
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Avaliability :</h5>
                    <ul>
                        <li>
                            100 ml, 250 ml, 500 ml, 1 ltr.
                        </li>

                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

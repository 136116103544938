
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const CYMINT_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4  overflow_products  border shdows">
                    <img src="Static/Assets/Img/products/CYMINTs-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5  overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">CYMINT </h4>
                    <h6 className="py-2 ps-3">Acetamiprid 1.1% + Cypermethrin 5.5% W/W EC</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Common name of Pest :</h5>
                    <ul>
                        <li>
                            Aphid, Jassids, Thrips and bollworms
                        </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Direction of use :</h5>
                    <ul>
                        <li>
                            Cymint is Plant protection equipment. It is recommended to use knapsack
                            sprayer or motorised knapsack sprayer fitied with high or low volume nozzle.

                        </li>
                        <li> The product is toxic to honeybees and avoid spraying during foraging time on crops.</li>

                        <li> Take the
                            measured quantity of insecticide and add to it a small quantity of water mix thoroughly then
                            add remaining quantity of water, stir well to form a ready to use spray. Do not spray against
                            the wind.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Doses :</h5>
                    <ul>
                        <li>
                            1 to 2 ml. per 1 ltr. of water.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            100 ml, 250ml, 500 ml,1ltr.
                        </li>

                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

import { Link, useNavigate } from "react-router-dom"


export const Footer = () => {
    const navigate = useNavigate()

    return (
        <>
            {/* <div className="row px-lg-5 py-3 mt-5 border faint">

                <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                    <a href="javascript:void(0)"
                        onClick={() => navigate('/')}>
                        <img src="Static/Assets/Img/logo_header-01.png" alt="logo here" class="logo_here" />
                    </a>
                </div>

                <div className="col-12 col-lg-8 ">
                    <div className="row d-flex align-items-end ">
                        <div className="col-8 pt-5 ">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label fs-5">  Subscribe Our Newsletter  </label>
                                <input type="email" class="form-control subscribe" id="exampleFormControlInput1" placeholder=" Subscribe Our Newsletter " />
                            </div>
                        </div>
                        <div className="col-4  ">
                            <div class="mb-3 pt-lg-4">
                                <button type="button" class="btn btn-outline-success subscribe_btns">  Subscribe Now  </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}


            <div className="row px-4 pt-5 footer_bgs text-white" data-aos="zoom-in">
                <div className="col-12 col-lg-4 ps-lg-5  pb-5">
                    <h5 className="pb-3 text-white fw-bold ps-lg-5">Quick Link</h5>
                    <hr />

                    <div className="row ps-lg-3">
                        <div className="col-6 text-white ps-lg-5">
                            <p><a href="javascript:void(0)"
                                onClick={() => navigate('/')}
                            >Home
                            </a></p>

                            <p>
                                <Link to="/About?ScreenName=About">
                                    About
                                </Link>

                            </p>

                            <p>
                                <Link to="/PalviAgroChemicals?ScreenName=Palvi Agro Chemicals and Fertilizers" >
                                    Our Verticals
                                </Link>
                            </p>
                            <p>
                                <Link to="/Products?ScreenName=Products" >
                                    Products
                                </Link>
                            </p>

                            {/* <p>
                                <Link to="/Vision?ScreenName=Vision">
                                    Vision
                                </Link></p>

                            <p>
                                <Link to="/Mission?ScreenName=Mission">
                                    Mission
                                </Link>
                            </p>

                            <p>
                                <Link to="/Goals?ScreenName=Goals">
                                    Goals
                                </Link>
                            </p> */}

                        </div>

                        <div className="col-6">
                            <p>
                                <Link to="/ManufacturingUnit?ScreenName=Manufacturing Unit" >
                                    Manufacturing Unit
                                </Link>
                            </p>

                            <p>
                                <Link to="/InvestorDetails?ScreenName=Investor Details" >
                                Investor Details
                                </Link>
                            </p>

                            <p>
                                <Link to="/OurTeam?ScreenName=Our Team" >
                                    Our Team
                                </Link>
                            </p>
                            {/* <p>
                                <Link to="/PalviFarmFresh?ScreenName=Palvi Farm Fresh" >
                                Palvi Farm Fresh
                                </Link>
                            </p> */}


                            <p>
                                <Link to="/Contact?ScreenName=Contact">
                                    Contact
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-2 ">
                    <h5 className="pb-3 text-white">Get in touch</h5>
                    <hr />
                    <p>
                        <a href="javascript:void(0)"
                            onClick={event => {
                                event.preventDefault();
                                window.open('https://www.facebook.com/profile.php?id=61565973425751', '_blank');
                            }}
                        >
                            <i class="fa-brands fa-facebook-f icons22 me-2"></i> Facebook
                        </a>
                    </p>

                    <p>
                        <a href="javascript:void(0)"
                            onClick={event => {
                                event.preventDefault();
                                window.open('https://twitter.com/PalviAgro', '_blank');
                            }}
                        >
                            <i class="fa-brands fa-x-twitter icons22 me-2"></i>
                            X
                        </a>
                    </p>

                    <p>
                        <a href="javascript:void(0)"
                            onClick={event => {
                                event.preventDefault();
                                window.open('https://www.instagram.com/palviagrochemicals', '_blank');
                            }}
                        ><i class="fa-brands fa-instagram icons22 me-2"></i> Instagram
                        </a>
                    </p>

                    <p>
                        <a href="javascript:void(0)"
                            onClick={event => {
                                event.preventDefault();
                                window.open('https://www.linkedin.com/in/palvi-agro-chemicals-and-fertilizer-664b56329/', '_blank');
                            }}
                        >    <i class="fa-brands fa-linkedin-in icons22 me-2"></i> Linkedin
                        </a>
                    </p>

                </div>

                <div className="col-12 col-lg-3 py-5 pt-lg-0">
                    <h5 className="pb-3 text-white">Maharashtra Corporate Address</h5>
                    <hr />
                    <p className="pe-lg-5 cursor_pointer">
                        Plot no 277, RK Nagar Society, <br /> Society no 5, Karvir,  <br /> Opposite Nayara Petrol Pump,  <br /> Morewadi Kolhapur 416007
                    </p>
                </div>

                <div className="col-12 col-lg-3 ps-lg-5  pb-5">
                    <h5 className="pb-3 text-white  ps-lg-5">Consumer Policy</h5>
                    <hr />

                    <div className="text-white ps-lg-5">
                        <p>
                            <Link to="/PrivacyNotice">
                                Privacy Notice
                            </Link>

                        </p>
                        <p>
                            <Link to="/TermsCondition">
                                Terms & Condition
                            </Link></p>

                        <p>
                            Helpline Number <br />
                            <a href="tel:7711804004" > <span className="text-primary">+91 771 180 4004</span> </a>

                        </p>
                    </div>
                </div>

                <hr />

                <div className="col-12">
                    <p className="text-white text-center">© Copyright Palvi Agro. All Rights Reserved.</p>
                </div>
            </div>
        </>
    )
}

import { useNavigate } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce"

export const Addresses = () => {
    return (
        <>
            <HeaderEcommerce actives='Orders' />
            <div className="row pb-5 px-5 cursor_pointer" style={{ background: '#edecec' }}>
                <div className="col-12 py-4 pt-5">
                    <h4 className="fw-bold">Your Addresses</h4>
                </div>
                <div className="col-12 col-lg-4  p-3 justyfy-content-center align-items-center">
                    <div className=" borderdashed bg-white p-5 ">
                        <h4 className="text-center"><i class="fa-solid fa-plus"></i></h4>
                        <h5 className="text-center">Add Addresses</h5>
                    </div>
                </div>

                

            </div>
            <Footer />
        </>
    )
}
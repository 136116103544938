
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const AUSTINSUPER = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/AUSTIN_SUPER-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3  pt-lg-5 mt-lg-5">AUSTIN SUPER <span className="fs-6"> (Organic Fungicide)</span>  </h4>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Composition :</h5>
                    <ul>
                        <li> Proteins, Nucleic Acid, Saccharide.</li>
                        <li> Austin Super is used for all crops for the control of Downy Mildew, Powdery Mildew, Anthracnose, Xanthomonas, Blight, Angular leaf spot, Root rot.</li>
                   
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Packing :</h5>
                    <ul>
                        <li>
                            100 ml,250 ml.
                        </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Dose :</h5>
                    <ul>
                        <li>
                            100 ml per 200 lit  Of water.
                        </li>

                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}


import React, { useEffect, useState } from "react";
import { HeaderDashboard } from "../../HeaderDashboard";
import { Sidebar } from "../../Sidebar";
import { ReactDDL } from "../../CommonDDL/ReactDDL";
import { useSearchParams } from "react-router-dom";
import DeletePopUp from "../../CommonPopUp/DeletePopUp";
import AddPopUpCompanyMaster from "./AddPopUpCompanyMaster";

export const CompanyMaster = () => {

    const [addPopUpCompanyMaster, setaddPopUpCompanyMaster] = useState(false)
    const handleCompanyMaster = () => {
        setaddPopUpCompanyMaster(false)
    }

    const [addPopUpDelete, setaddPopUpDelete] = useState(false)
    const handleDelete = () => {
        setaddPopUpDelete(false)
    }

    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')

    return (
        <>
            <div className="container-scroller">
                <HeaderDashboard />
                <div className="container-fluid page-body-wrapper">

                    <Sidebar active='Master' subMenu="CompanyMaster" />

                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="card-body grid-margin">
                                <div className="row " >
                                    <div className="col-12">
                                        <p className="d-flex justify-content-between fw-bold green" style={{ fontSize: '18px' }}>{Name}

                                            <button type="button" class="btn btn-primary px-2 fs-6" id="parrot_green"
                                                onClick={() => {
                                                    setaddPopUpCompanyMaster(true)
                                                }}>
                                                <i class="fa-solid fa-plus fs-8 pe-1"></i> Add</button>
                                        </p>
                                    </div>
                                </div>

                                <div className="row p-2 BgRow shdows">
                                    <div className="bgcolor pb-3">
                                        <div className="row">
                                            {/* <div className="col-12 col-lg-4">
                                                <label className="my-2 ">Product Category</label>
                                                <ReactDDL />
                                            </div> */}
                                            <div className="col-12 col-lg-4">
                                                <label className="my-2 ">Company Name</label>
                                                <ReactDDL />
                                            </div>
                                        </div>
                                    </div>

                                    <table className="table text-center bg-white mt-2" >
                                        <thead className="parrot_green">
                                            <tr>
                                                <th scope="col">Sr.No.</th>
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Contact Number</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td> 1 </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <i class="fa-regular fa-pen-to-square green px-2 cursor_pointer"
                                                        onClick={() => setaddPopUpCompanyMaster(true)}></i>
                                                    <i class="fa-regular fa-trash-can text-danger px-2 cursor_pointer"
                                                        onClick={() => setaddPopUpDelete(true)}></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {
                addPopUpCompanyMaster ?
                    <AddPopUpCompanyMaster
                        cancelbtn={handleCompanyMaster}
                        heading={Name}
                    /> : <></>

            }

            {
                addPopUpDelete ?
                    <DeletePopUp
                        cancelbtn={handleDelete}
                        heading={Name}
                    /> : <></>
            }
        </>
    )
}
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Footer } from "../../Components/Footer/Footer";
import { Navbar } from "../../Components/IndexPage/Navbar/Navbar";
import { Header } from "../../Components/IndexPage/Header/Header";

export const LoginPage = () => {

    const navigate = useNavigate();
    const [UserName, setUserName] = useState()
    const [Invalid, setInvalid] = useState('')
    const [Password, setPassword] = useState()

    const Handlenavigate = () => {
        if (UserName == 'Admin') {
            navigate('/Dashboard')
        }
        if (UserName == '1' && Password =='1') {
            navigate('/Checkout')
        }
        else {
            setInvalid('Invalid Credential . Please Try Again')
        }
    }
    return (
        <>
            <Navbar />
            <Header actives="Login" />
            <div className="row LoginPage_BgImg d-flex align-items-center p-4" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12 col-lg-4  ms-auto  border2 shdows p-3 py-lg-2 px-lg-5 bg-white" style={{ borderRadius: '10px', height:'470px' }}>

                    <div className="text-center">
                        {/* <img src="Static/Assets/Img/logo.png" onClick={() => navigate('/')} alt="logo here" className="logo_bg" /> */}
                        <h4 className="fw-bold green pt-2 pb-3">Login</h4>
                    </div>
                    <div>
                        <label for="floatingInput"
                        > Enter Email / Mobile Number </label>
                        <div class="input-group mb-3 mt-2">
                            <span class="input-group-text parrot_green" id="basic-addon1"><i class="fa-solid fa-user text-white"></i></span>
                            <input type="text" class="form-control" aria-describedby="basic-addon1"
                                id="UserName"
                                value={UserName}
                                onChange={(e) => setUserName(e.target.value)} />
                        </div>
                    </div>
                    <div>
                        <label for="floatingInput"
                        > Password </label>
                        <div class="input-group mb-3 mt-2">
                            <span class="input-group-text parrot_green" id="basic-addon1"><i class="fa-solid fa-lock text-white"></i></span>
                            <input type="text" class="form-control" aria-describedby="basic-addon1"
                            id="Password"
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)} />
                            <span class="input-group-text text2" id="basic-addon2"><i class="fa-solid fa-eye-slash green "></i></span>
                        </div>
                    </div>

                    {/* <div class="form-floating mb-3 mt-3">
                        <input type="email" class="form-control" placeholder="name@example.com"
                            id="UserName"
                            value={UserName}
                            onChange={(e) => setUserName(e.target.value)} />
                        <label for="floatingInput"
                        > Enter Email / Mobile Number </label>
                    </div>

                    <div class="form-floating">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword"> Password </label>
                    </div> */}


                    <p className="pb-0 text-end green fw-bold cursor_pointer fs-8"
                        onClick={() => navigate('/ForgotPassword')} >Forgot Password?</p>

                    <p className="fs-8 mb-0">
                        By placing your order, you agree to Palvi Agro's  Chemicals and Fertilizer’s
                        <span className="green text-decoration-underline cursor_pointer px-2" onClick={() => navigate('/PrivacyNotice')}> Privacy Notice </span> &
                        <span className="green text-decoration-underline cursor_pointer px-1" onClick={() => navigate('/TermsCondition')}> Terms and Condition. </span>
                    </p>

                    <div className="pt-3">
                        <button type="button" class=" parrot_green text-white w-100 fw-bold border rounded fs-6 py-2"
                            onClick={Handlenavigate}
                        >LOGIN</button>
                    </div>
                    <p className="text-danger">{Invalid}</p>

                    <p className=" text-center">New here? <a href="javascript:void(0)" onClick={() => navigate('/Register')}> Create Account</a></p>

                </div>


            </div>


            {/* <Footer /> */}



        </>
    )
}

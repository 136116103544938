import React, { useEffect, useState } from "react";
import { MultiSeletDDL } from "../CommonDDL/MultiSeletDDL";
import { useNavigate } from "react-router-dom";

export const ProductOfferAndDiscounts = () => {
    const navigate = useNavigate()


    return (
        <>
            <h6 className="green pt-3" style={{ fontSize: '19px' }}>  Product Offer and Discounts </h6>
            <div className="row bg-white pb-1 rounded shadow2">

                <div className="col-12 col-lg-6 ps-lg-3 py-3 ">
                    <div className=" px-3 pb-1 pt-3 pb-1 bg_offer cursor_pointer" onClick={() => navigate(`/SpecialOffers?Name=Special Offers`)}>
                        <h5 className="text-center pb-3 pt-4">Special Offers</h5>

                    </div>
                </div>

                <div className="col-12 col-lg-6 pe-lg-3 py-3">
                    <div className=" px-3 pt-3 pb-1 products_offer cursor_pointer" onClick={() => navigate(`/ProductOffers?Name=Product Offers`)}>
                        <h5 className="text-center pb-3 pt-4">Product Offers</h5>
                    </div>
                </div>



            </div>
            
        </>
    )
}
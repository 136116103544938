
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const Chlopal_Diamond_Products = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/ChlopalDiamonds-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5  overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">Chlopal Diamond</h4>
                    <h6 className="py-2 ps-3">Chlorpyriphos 50% EC </h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Dose :</h5>
                    <ul>
                        <li>
                            300-500 ml per acre.
                        </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Benefits :</h5>
                    <ul>
                        <li>
                            Chlopal Diamond is non Systemic with contact, stomach & respiratory action
                            Control of sucking, Chewing & boring insects and various foliar Crop Pests in wide range of
                            crops like paddy, cotton, wheat, fruits, vegetables, Tea, Vines, Sugarcane etc. It is also
                            recommended for Termite Control in Crop field and has potential demand in building
                            construction for termite proofing.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            250 ml, 500 ml, 1 ltr.
                        </li>

                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

import { MoreBtn } from "../../CommonBtn/MoreBtn"

export const InnovationandTechnology = () => {
    return (
        <>
            <div class="row mt-lg-5 p-lg-0 p-3 px-lg-5">
                <div class="col-12 col-lg-6 FutureOutlookandUpcomingProducts" data-aos="fade-up">
                    {/* <img src="Static/Assets/Img/FutureOutlookandUpcomingProducts.jpg " className="FutureOutlookUpcomingProducts" alt="" /> */}

                </div>
                <div className=" col-lg-6 ps-lg-4 pt-lg-5" data-aos="fade-down">
                    <h4 className="pt-4 fw-bold pb-2 mt-lg-3">Future Outlook and Upcoming Products</h4>
                    <p className="text_color " style={{ textAlign: 'justify' }}>
                        "We are committed to helping you achieve greater crop yields and quality. Our future lineup will feature
                        advanced precision application technologies, making pesticide use more efficient, cost-effective, and
                        reducing waste. Our research team is tirelessly working on next-generation products that offer increased
                        resistance management. Expect innovative solutions that combat pesticide resistance and maintain their
                        effectiveness over time."
                    </p>
                    {/* <MoreBtn /> */}
                </div>
            </div>

            <div class="row  p-3 p-lg-0 px-lg-5">

                <div className=" col-lg-6  pt-lg-5" data-aos="fade-up">
                    <h4 className=" fw-bold pt-0 pt-lg-5 mt-lg-4">Quality Assurance Statements</h4>
                    <p className="text_color pb-1  pe-lg-5 mt-lg-1" style={{ textAlign: 'justify' }}>
                        “Our dedication to quality assurance starts from the moment we source our products. We maintain a keen
                        eye on the entire production process, guaranteeing the purity and effectiveness of every product we
                        offer.”
                    </p>
                    {/* <MoreBtn /> */}
                </div>

                <div class="col-12 col-lg-6 QualityAssuranceStatements " data-aos="fade-down">
                    {/* <img src="Static/Assets/Img/QualityAssuranceStatements.jpg" className="QualityAssuranceStatements" alt="" /> */}

                </div>
            </div>

            <div class="row  p-3 p-lg-0 px-lg-5 mb-5">
                <div class="col-12 col-lg-6 InnovationandTechnology" data-aos="fade-up">
                    {/* <img src="Static/Assets/Img/InnovationandTechnology.jpg" className="InnovationandTechnology" alt="" /> */}

                </div>
                <div className=" col-lg-6 ps-lg-4 pt-lg-5" data-aos="fade-down" >
                    <h4 className="pt-5 fw-bold pb-2 mt-lg-3">Innovation and Technology</h4>
                    <p className="text_color" style={{ textAlign: 'justify' }}>
                        "Innovation is at the core of our identity. Our startup thrives on cuting-edge research and development,
                        utilizing the latest technologies to develop pesticide solutions that are effective, efficient, and
                        sustainable."
                    </p>
                    {/* <MoreBtn /> */}
                </div>
            </div>



        </>
    )
}



export const Review = () => {


    return (
        <>

            <div className="row col-lg-10 mx-auto mb-5 mt-4">
                <h6 className="fw-bold pt-4 pb-4 text-center">Customer Reviews (8)</h6>
                <hr />
                <div className="col-12 col-lg-10 mx-auto ">
                    <p className="fs-6 fw-bold" style={{ color: '#000000' }}>
                        <span className="rounded parrot_green text-white py-1 px-2  me-3 fs-8"> 5 <i class="fa-solid fa-star fs-9 ps-1"></i></span>
                        Super quality</p>
                    <p className="fs-8 d-flex justify-content-between">
                        <span>Namita Pawar | 8 Sep 2024</span>
                        <span className="green"><i class="fa-solid fa-thumbs-up"></i> 0 | <span className="text-danger ps-3"><i class="fa-solid fa-thumbs-down"></i> 0 </span></span>
                    </p>
                </div> <hr />

                <div className="col-12 col-lg-10 mx-auto ">
                <p className="fs-6 fw-bold" style={{ color: '#000000' }}>
                        <span className="rounded parrot_green text-white py-1 px-2  me-3 fs-8"> 5 <i class="fa-solid fa-star fs-9 ps-1"></i></span>
                        Super quality</p>
                    <p className="fs-8 d-flex justify-content-between">
                        <span>Namita Pawar | 8 Sep 2024</span>
                        <span className="green"><i class="fa-solid fa-thumbs-up"></i> 0 | <span className="text-danger ps-3"><i class="fa-solid fa-thumbs-down"></i> 0 </span></span>
                    </p>
                </div> <hr />


                <p className="text-center fw-bold mt-3">
                    <span className=" p-2 parrot_green text-white rounded px-3 cursor_pointer">View all reviews   <i class="fa-solid fa-angles-right"></i></span>
                </p>
            </div>
        </>
    )
}






import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const GREEN_GOLD_40 = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/GREEN_GOLD_40-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> GREEN GOLD-40 <span className="fs-6">(Biostimulant)</span></h4>
                    <h6 className="py-2 ps-3">
                    Green Gold is a Biostimulant which help for higher photosynthesis more branching
                    dark greenary developes resistance against fungal and bacterial disease.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>It has major role in fruit size also induces colour & shinig in the fruit.</li>
                        <li>It improves uptake & trans location of micro & micronutrients. 3. It enhances crop
                        vigour.</li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        500 ml / Acre
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                        500 ml., 1 ltr.
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

import React, { useEffect, useState } from "react";
import { HeaderDashboard } from "../../HeaderDashboard";
import { Sidebar } from "../../Sidebar";
import { ReactDDL } from "../../CommonDDL/ReactDDL";
import { useSearchParams } from "react-router-dom";
import DeletePopUp from "../../CommonPopUp/DeletePopUp";
import AddPopUpOutletStoreMaster from "./AddPopUpOutletStoreMaster";
export const OutletStoreMaster = () => {

    const [addPopUpOutletStoreMaster, setaddPopUpOutletStoreMaster] = useState(false)
    const handleOutletStoreMaster = () => {
        setaddPopUpOutletStoreMaster(false)
    }

    const [addPopUpDelete, setaddPopUpDelete] = useState(false)
    const handleDelete = () => {
        setaddPopUpDelete(false)
    }

    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')

    return (
        <>
            <div className="container-scroller">
                <HeaderDashboard />
                <div className="container-fluid page-body-wrapper">

                    <Sidebar active='Master'  subMenu="OutletStoreMaster"/>

                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="card-body grid-margin">
                                <div className="row " >
                                    <div className="col-12">
                                        <p className="d-flex justify-content-between fw-bold green" style={{ fontSize: '18px' }}>{Name}
                                            <button type="button" class="btn btn-primary px-2 fs-6" id="parrot_green"
                                                onClick={() => {
                                                    setaddPopUpOutletStoreMaster(true)
                                                }}>
                                                <i class="fa-solid fa-plus fs-8 pe-1"></i> Add</button>
                                        </p>
                                    </div>
                                </div>

                                <div className="row p-2 BgRow shdows">
                                    <div className="bgcolor pb-3">
                                        <div className="row">

                                            <div className="col-12 col-lg-4">

                                                <div>
                                                    <label className="my-2 ">Outlet Store Name</label>

                                                </div> <div class="input-group mb-3">
                                                    <input type="text" class="form-control" placeholder="Search..." />
                                                    <span class="input-group-text" id="basic-addon2">
                                                        <i class="fa-solid fa-magnifying-glass"></i>
                                                    </span>
                                                </div>
                                            </div>


                               

                                            {/* <div className="col-12 col-lg-4 d-flex align-items-end">
                                                <button type="button" class="btn btn-primary px-2 ">
                                                    <i class="fa-solid fa-xmark fs-8 pe-1"></i> Clear</button>
                                            </div> */}
                                        </div>
                                    </div>

                                    <table className="table text-center bg-white mt-2" >
                                        <thead className="parrot_green">
                                            <tr>
                                                <th scope="col">Sr.No.</th>
                                                <th scope="col">Outlet Store Name</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Mobile Number</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td> 1 </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <i class="fa-regular fa-pen-to-square green cursor_pointer px-2"
                                                        onClick={() => setaddPopUpOutletStoreMaster(true)}></i>
                                                    <i class="fa-regular fa-trash-can text-danger px-2"
                                                        onClick={() => setaddPopUpDelete(true)}></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {
                addPopUpOutletStoreMaster ?
                    <AddPopUpOutletStoreMaster
                        cancelbtn={handleOutletStoreMaster}
                        heading={Name}
                    /> : <></>

            }

            {
                addPopUpDelete ?
                    <DeletePopUp
                        cancelbtn={handleDelete}
                        heading={Name}
                    /> : <></>
            }
        </>
    )
}
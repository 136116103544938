import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const ROOT_42_F = () => {

    return (
        <>

            <Header  actives="Products"/>

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/ROOT_42_F-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">ROOT 42 F</h4>
                    <h6 className="py-2 ps-3">Root 42 F is increases the soil fertility through its influence on the water holding capacity of the soil.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li> It increases soil cation exchange capacity. </li>
                        <li> Increase roote penetration and internal drainage.</li>
                        <li> Conserves top soil.</li>
                        <li> Breaks up productive clay solis and turns into profitable solis.</li>
                        <li> Stabilizers soil structure through the the formation of micro aggregates.</li>
                        <li> Increases the sugar (brix) level of the plants and strengthens the plant of resist.</li>
                        <li> Enhance plant root update of p, k, ca, mg, mn, fe, cu & zn. </li>
                        <li> Influence enzyme system. </li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                        Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        100gm - 250gm per acre.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const ROOT_42_L = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/ROOT_42_L-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3  pt-lg-4">ROOT 42-L</h4>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Composition :</h5>
                    <ul>
                        <li>Humic Acid 15 %, Fulvic Acid 0.5 %.</li>
                        <li> Root-42 improving uptake & translocation of macro & micro nutrients.</li>
                        <li> It helps plant to resist against drought & Acceleration germination, Enhancing seeding vigor and root growth. Induces early maturity. </li>
                        <li>Helps to develop white root, keeps soil loose for better aeration.
                            Humic acid is an organic plant deposits useful for the development of white root, increase water capacity in soil, transfer nutrients from soil to crop root and stimulates the production of chlorophyll.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Dose :</h5>
                    <ul>
                        <li> 2 to 3 ml. per liter water for Spray.</li>
                        <li>  1 to 2 ltr for drenching.</li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            500 ml, 1 ltr, 5 ltr.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

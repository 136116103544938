import { useNavigate } from "react-router-dom"
import { Header } from "../Header/Header"
import { Navbar } from "../Navbar/Navbar"
import { OurProducts } from "./OurProducts"
import { Footer } from "../../Footer/Footer"
import { HeaderEcommerce } from "./HeaderEcommerce/HeaderEcommerce"
import { HerosectionsAll } from "../../HerosectionsAll/HerosectionsAll"

export const Products = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="Products" />
                    <HerosectionsAll />
                    <OurProducts />
                    <Footer />
                </div>
            </div>
        </>
    )
}

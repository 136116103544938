import { useNavigate } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce"

export const Account = () => {
    const navigate = useNavigate()
    return (
        <>
            <HeaderEcommerce actives="Account" />
            <div className="row pb-5 px-lg-5" style={{ background: '#fff' }} onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12 py-4 pt-5">
                    <h4 className="fw-bold">Your Account</h4>
                </div>

                <div className="col-12 col-lg-4 pt-4 pt-lg-0">
                    <div className="row p-2 borderaccount rounded m-1 bg-white py-3 cursor_pointer">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/wishlist.png" alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-9">
                            <h6 className="fw-bold">Your Wishlists </h6>
                            <p style={{ fontSize: '15px' }}>Shopping List Here</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-4 pt-lg-0">
                    <div className="row p-2 borderaccount rounded m-1 bg-white py-3 cursor_pointer">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/parcel.png" alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-9">
                            <h6 className="fw-bold">Your Orders</h6>
                            <p style={{ fontSize: '15px' }}>Track, return, or buy things again</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-4 pt-lg-0">
                    <div className="row p-2 borderaccount rounded m-1 bg-white cursor_pointer py-3" onClick={() => navigate('/Addresses')}>
                        <div className="col-3 d-flex justify-content-center align-items-center ">
                            <img src="Static/Assets/Img/address.png" className="" alt="" style={{ width: "100%" }} />
                        </div>

                        <div className="col-9">
                            <h6 className="fw-bold">Your Addresses</h6>
                            <p style={{ fontSize: '15px' }}>Edit addresses for orders </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-3">
                    <div className="row p-2 borderaccount  m-1 bg-white py-3 cursor_pointer">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/payment.png" alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-9">
                            <h6 className="fw-bold">Payment Options</h6>
                            <p style={{ fontSize: '15px' }}>Edit or add payment methods</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-3 ">
                    <div className="row p-2 borderaccount cursor_pointer m-1 bg-white py-3" onClick={()=> navigate ('/ChangePassword')}>
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/password.png" alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-9">
                            <h6 className="fw-bold">Change Password </h6>
                            <p style={{ fontSize: '15px' }}>Change your password</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 pt-3 ">
                    <div className="row p-2 borderaccount cursor_pointer m-1 bg-white py-3">
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/contact.png" alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-9">
                            <h6 className="fw-bold">Contact Us </h6>
                            <p style={{ fontSize: '15px' }}>Contact our customer </p>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}
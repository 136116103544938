import { useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../Footer/Footer"
import { Header } from "../IndexPage/Header/Header"
import { useState } from "react";
import { HeaderEcommerce } from "../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";

export const PrivacyNotice = () => {

    const navigate = useNavigate()

    return (
        <>
            <Header actives="Login" />
            <div className="row p-4 " onLoad={() => window.scrollTo(0, 0)} style={{ background: '#edecec' }}>
                <div className="col-12 text-center ">

                    <h3 className="fw-bold py-4 green"><span className="text-danger">Privacy</span> Notice</h3>
                    <p className="pt-3 pb-5 fs-5">While using this site you agree to use of your personal information for order process and delivery of product.</p>

                </div>

            </div>


            <Footer />



        </>
    )
}

import { Footer } from "../../Footer/Footer"
import { HerosectionsAll } from "../../HerosectionsAll/HerosectionsAll"
import { Header } from "../Header/Header"
import { Navbar } from "../Navbar/Navbar"
import { OurPhotos } from "../OurPhotos/OurPhotos"

export const ManufacturingUnit = () => {
    return (
        <>
            <div className="row " onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="ManufacturingUnit" />
                    <HerosectionsAll />

                    <div className="row p-lg-5 p-3"
                        style={{ background: '#dbebde' }}
                    >
                        <div className="col-12 col-lg-4 mx-auto py-5 text-center">
                            <img src="Static/Assets/Img/pk-01.png" class="Hanumant_Bhosle" alt="" />

                            {/* <h5 className="pt-3 fw-bold">Maharashtra Manufacturing Address</h5> */}
                            <p className="text-center">
                            <h6 className="fs-5 text-dark fw-bold pt-3">   Pravis Krushi Rasayan </h6>
                                Pravis Krushi Rasayan Gate No.1416, <br /> A/P, Tal, Danoli, Shirol, Maharashtra <br /> Pin Code – 416101</p>

                        </div>

                        <div className="col-12 py-5 col-lg-4 mx-auto text-center">
                            <img src="Static/Assets/Img/Team/bhosale-01.png" class="Hanumant_Bhosle" alt="" />
                            <h5 className="pt-3 fw-bold">Dr. Hanumant Bhosale</h5>
                            <p className="text-dark text-center" style={{ textAlign: 'justify' }}>
                                MSc. Agri PH.D. <br />
                                Managing Director <br />
                                Pravis Krushi Rasayan <br />
                                Greenfield Hi-tech agrotechnologies <br />
                            </p>

                        </div>
                    </div>

                    <OurPhotos />
                    <Footer />
                </div>
            </div>
        </>
    )
}



import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const CROP_KING = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/CROP_KING-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> CROP KING <span className="fs-6">(Biostimulant)</span></h4>
                    <h6 className="py-2 ps-3">
                        Cropking is a Biostimulant which increses vegetative growth, plant greenary. It
                        enhances mitochondrial activity & photosynthesis process which controls the
                        evapotranspiration in plants.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li> It develops fruit size.</li>
                        <li>It increses plant reproductive activity.</li>
                        <li>It developes embryo of female flower.</li>



                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                            100 ml / Acre.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            50ml, 100ml.
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

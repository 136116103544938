import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const Contact = () => {
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="Contact" />
                    <HerosectionsAll />

                    <div className="row p-lg-5 p-3" style={{ background: '#dbebde' }}>
                        <div className="col-12 col-lg-6 py-5 ps-lg-5">

                            <img src="Static/Assets/Img/logo.png" alt="logo here" class="logo_bg bg-white py-2 ms-0" />

                            <h2 className="display-6 py-1 fw-bold green"> We would Love to Help</h2>
                            <h5 style={{ textAlign: 'justify' }}>Email Address</h5>
                            <p className="text_color" style={{ textAlign: 'justify' }}>
                                <a href="mailto:salespalviagrochemicals@gmail.com" className="text_color">
                                    <i class="fa-solid fa-envelope green fs-6 pe-2"></i>
                                    sales.palviagrochemicals@gmail.com</a>
                                <br />

                                <a href="mailto:hrpalviagrochemicals@gmail.com" className="text_color">
                                    <i class="fa-solid fa-envelope green fs-6 pe-2"></i>
                                    hr.palviagrochemicals@gmail.com</a>
                            </p>

                            <h5 style={{ textAlign: 'justify' }}>Customer Care Number</h5>
                            <div className="text_color " style={{ textAlign: 'justify' }}>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <p className="text_color">
                                            <i class="fa-solid fa-phone-volume green fs-6 pe-2"></i>
                                            <a href="tel:9325003938" className="text_color"> 932 500 3938</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-5">
                                        <p>
                                            <i class="fa-solid fa-phone-volume green fs-6 pe-2"></i>
                                            <a href="tel:7711804004" className="text_color">771 180 4004</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <h5 style={{ background: '#dbebde' }}> Follow our Social Media</h5>
                            <span>
                                <i class="fa-brands fa-facebook-f parrot_green text-white icons3 cursor_pointer"
                                    onClick={event => {
                                        event.preventDefault();
                                        window.open('https://www.facebook.com/profile.php?id=61565973425751', '_blank');
                                    }}></i>

                                <i class="fa-brands fa-instagram parrot_green text-white icons2 cursor_pointer"
                                    onClick={event => {
                                        event.preventDefault();
                                        window.open('https://www.instagram.com/palviagrochemicals', '_blank');
                                    }}
                                >
                                </i>
                                <i class="fa-brands fa-x-twitter parrot_green text-white icons2 cursor_pointer"
                                    onClick={event => {
                                        event.preventDefault();
                                        window.open('https://twitter.com/PalviAgro', '_blank');
                                    }}>
                                </i>

                                <a href="https://wa.me/7711804004" target="_blank">
                                    <i class="fa-brands fa-whatsapp parrot_green text-white icons2"></i>
                                </a>

                                <i class="fa-brands fa-linkedin-in parrot_green text-white icons2 cursor_pointer"
                                    onClick={event => {
                                        event.preventDefault();
                                        window.open('https://www.linkedin.com/in/palvi-agro-chemicals-and-fertilizer-664b56329/', '_blank');
                                    }}></i>
                            </span>


                        </div>

                        <div className="col-12 col-lg-6">

                            <form action="https://formspree.io/f/xwpejnlw" method="post">
                                <div className="row pt-lg-5">
                                    {/* <div className="col-12">
                                    <form action="https://formspree.io/f/xwpejnlw" method="post">
                                        <label for="email">Your Email</label>
                                        <input name="Email" id="email" type="email" />
                                        <button type="submit">Submit</button>
                                    </form>
                                </div> */}

                                    <div className="col-12 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control borders" id="floatingInput" name="First Name" placeholder="First Name" required />
                                            <label for="floatingInput" className="fs-6">First Name</label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control borders" id="floatingInput" name="Last Name" placeholder="Last Name" required />
                                            <label for="floatingInput" className="fs-6">Last Name</label>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control borders" id="EmailAddress" name="Email Address" placeholder="Email Address" required />
                                    <label for="EmailAddress" className="fs-6">Email Address</label>
                                </div>

                                <div class="form-floating mb-3">
                                    <input type="tel" minlength="10" maxlength="10" class="form-control borders" name="Mobile Number" id="Mobile" placeholder="Mobile Number" required />
                                    <label for="Mobile" className="fs-6">Mobile Number</label>
                                </div>

                                <div class="form-floating mb-3">
                                    <textarea class="form-control borders" placeholder="Message Here" name="Message" id="Message" style={{ height: '120px' }} required></textarea>
                                    <label for="Message" className="fs-6"> Message Here...</label>
                                </div>
                                <div class="form-floating mb-3 pt-3">
                                    <button type="submit" class="btn Free_Quote">Get Free A Quote</button>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12 text-center px-5 pt-5">
                            <h5 className="">Get in Touch</h5>
                            <h2 className="fw-bold green pt-3 pb-3"> Let's Start and Talk! </h2>
                        </div>
                    </div>

                    <div className="row px-3 px-lg-5 pb-5">
                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green"> Maharashtra Registered Address </h6>
                                <p className="text_color"> Floor no 1 Milkat no 1987, Popat Narsu Doke, Pakhrud Road , IEET road, Tal- Bhoom Dist. Osmanabad State- Maharashtra </p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green"> Maharashtra Corporate Address </h6>
                                <p className="text_color"> Plot no 277, RK Nagar Society, Society no 5, Karvir, Opposite Nayara Petrol Pump,      Morewadi Kolhapur 416007 </p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Maharashtra Manufacturing  Address </h6>
                                <p className="text_color">Pravis Krushi Rasayan Gate No.1416, A/P, Tal, Danoli, Shirol, Maharashtra 416101
                                    Pin Code – 416101</p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Telangana Branch Office Address </h6>
                                <p className="text_color"> Plot no 6, Block no 21,Auto Nagar Road, Hyderabad Dist. RangaReddy <br /> State- Telangana
                                    500070 </p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Tamil Nadu Branch Office Address </h6>
                                <p className="text_color">TSF No 32,Door No 18, KNK Main Road,Sampalkadu, Thirunagar Colony Erode- 638003  </p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Andhra Pradesh Branch Office Address</h6>
                                <p className="text_color"> Door no 19-8-70, Godown no 4 ,Suryadevara Basaviah Estate ,Etukur Road, Guntur City     Dist.- Guntur 522003 </p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-house-chimney text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Karnataka Branch Office Address</h6>
                                <p className="text_color">10.10/ANext to the Post office building, Rajput block, 2nd cross, Shivaji Road, NR Mohalla Mysore <br /> 570007 </p>
                            </div>
                        </div>

                        {/* <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-envelope text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Email Address</h6>
                                <p className="text_color">
                                    <a href="mailto:salespalviagrochemicals@gmail.com" className="text_color">sales.palviagrochemicals@gmail.com</a>
                                </p>
                                <p>
                                    <a href="mailto:hrpalviagrochemicals@gmail.com" className="text_color">hr.palviagrochemicals@gmail.com</a>
                                </p>
                            </div>
                        </div> */}

                        {/* 
                        <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address pt-4">
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>
                                <h6 className="fw-bold pt-5 pb-2 green">Customer Care Number</h6>
                                <p className="text_color">
                                    <a href="tel:9325003938" className="text_color"> 932 500 3938</a>
                                </p>
                                <p className="text_color">
                                    <a href="tel:7711804004" className="text_color">771 180 4004</a>
                                </p>
                            </div>
                        </div> */}


                        {/* <div className="col-12 col-lg-4 text-center pt-4">
                            <div className="p-3 address py-5">
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>
                                <span className="address_circle p-3 "><i class="fa-solid fa-phone-volume text-white fs-5"></i></span>

                            </div>
                        </div> */}



                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}



import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const GREENN_ZYME = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/GREENN_ZYME-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> GREENN ZYME (Seaweed Base) </h4>
                    <h6 className="py-2 ps-3">
                        Green Zyme is a Bio-Techonological product based on seaweed extract. It is non toxic &
                        safe to use with the other fertilizers and pesticides.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li> it stimulates plant growth root development.</li>
                        <li> It improves plant resistance against diseases. </li>
                        <li>It enhances crop quality and yield by increasing the size and number of fruits.</li>
                        <li> It provides anti stress ability to withstand draw conditions. </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops. It is non- toxic & safe to use other fertilisers &
                            pesticides.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                            500 ml / acre for spraying 1 Ltr / Acre for Drenching
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            250ml, 500ml, 1ltr.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

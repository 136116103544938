import { useNavigate } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce"
import { OrderAll } from "./OrderAll"

export const Orders = () => {
    const navigate = useNavigate()
    const handlenavigate = (title) => {
        if (title == 'ExpectedDelivery') {
            navigate(`/OrderTimeline?title=${title}`)
        }
    }
    return (
        <>
            <HeaderEcommerce actives='Orders' />
            <div className="row pb-5 px-3 px-lg-5" style={{ background: '#edecec' }}>
                <div className="col-12 py-3 pt-4">
                    <h4 className="fw-bold">Your Orders</h4>
                </div>
                <div className="col-12 bg-white p-4 p-lg-5 mb-5">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active fs-6 fw-bold" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Orders</button>
                            <button class="nav-link fs-6 fw-bold" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Cancelled Orders</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                            <OrderAll
                                ImgPath="Static/Assets/Img/products/HERBO_STRICKs-01.png"
                                title='ExpectedDelivery'
                                ProductName="HERBO STRICK"
                                Quantity="Net Vol : 1 Ltr."
                                Price="Rs.400"
                                iconcolor='green'
                                deliveryDate="Delivered by 20 Sep 2024"
                                Confirmation='Your Expected delivery date'
                                Rating=" Rate & Review Product"
                                handlenavigate={handlenavigate}
                            />

                            <OrderAll
                                ImgPath="Static/Assets/Img/products/HERBO_STRICKs-01.png"
                                title=''
                                ProductName="HERBO STRICK"
                                Quantity="Net Vol : 1 Ltr."
                                BuyAgain=' Buy Again'
                                Price="Rs.400"
                                iconcolor='green'
                                deliveryDate="Delivered on 10 Jul 2024"
                                Confirmation='Your item has been delivered'
                                Rating=" Rate & Review Product"
                                handlenavigate={handlenavigate}
                            />


                            <OrderAll
                                ImgPath="Static/Assets/Img/products/HERBO_STRICKs-01.png"
                                title=''
                                ProductName="HERBO STRICK"
                                Quantity="Net Vol : 1 Ltr."
                                BuyAgain=' Buy Again'
                                iconcolor='red'
                                Price="Rs.400"
                                deliveryDate="Replacement Completed"
                                Confirmation='You returned this order because you did not like.'
                                Rating=" Rate & Review Product"
                                handlenavigate={handlenavigate}
                            />




                            {/* <div className="row border rounded mt-3" onClick={() => navigate("/OrderTimeline")}>

                                <div className="col-12 col-md-2  ">
                                    <img src="Static/Assets/Img/products/HERBO_STRICKs-01.png" alt="" className="products_order" />
                                </div>
                                <div className="col-12 col-md-3 pt-2">
                                    <h6 className="">HERBO STRICK</h6>
                                    <p className="fs-8 mb-0"> <b>Net Vol :</b> 1 Ltr.</p>
                                    <p className="text-danger fs-8 pt-2 fw-bold cursor_pointer" onClick={() => navigate("/HERBO_STRICK_Products")}>Buy Again</p>
                                </div>
                                <div className="col-12 col-md-3 pt-3 ">
                                    <h6>Rs.400</h6>
                                </div>
                                <div className="col-12 col-md-4 pt-2 ">

                                    <p className="pb-1 fs-6 mb-0"><i class="fa-solid fa-circle green fs-7"></i> Delivered on Jul 20 </p>
                                    <p className="pb-1 fs-8 mb-0"> Your item has been delivered </p>
                                    <p className="pb-1 fs-6 mb-0 green"> <i class="fa-solid fa-star fs-8"></i> Rate & Review Product </p>
                                </div>

                            </div>



                            <div className="row border rounded mt-3">

                                <div className="col-12 col-md-2  ">
                                    <img src="Static/Assets/Img/products/HERBO_STRICKs-01.png" alt="" className="products_order" />
                                </div>
                                <div className="col-12 col-md-3   pt-2">
                                    <h6 className="">HERBO STRICK</h6>
                                    <p className="fs-8 mb-0"> <b>Net Vol :</b> 1 Ltr.</p>
                                    <p className="text-danger fs-8 pt-2 fw-bold cursor_pointer" onClick={() => navigate("/HERBO_STRICK_Products")}>Buy Again</p>

                                </div>
                                <div className="col-12 col-md-3 pt-3 ">
                                    <h6>Rs.400</h6>
                                </div>
                                <div className="col-12 col-md-4 pt-2 ">

                                    <p className="pb-1 fs-6 mb-0"><i class="fa-solid fa-circle text-warning fs-7"></i> Replacement Completed </p>
                                    <p className="pb-1 fs-8 mb-0"> You returned this order because you did not like. </p>
                                    <p className="pb-1 fs-6 mb-0 green"> <i class="fa-solid fa-star fs-8"></i> Rate & Review Product </p>
                                </div>

                            </div> */}

                        </div>

                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <div className="row">
                                <div className="col-12 pt-3 pb-5">
                                    <h6>We aren't finding any cancelled orders (for orders placed in the last 6 months).<a className="fw-bold">View all orders</a> </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}
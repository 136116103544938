import { useSearchParams } from "react-router-dom"
import { ReactDDL } from "../CommonDDL/ReactDDL"
import { HeaderDashboard } from "../HeaderDashboard"
import { Sidebar } from "../Sidebar"
import { useState } from "react"
import AddPopUpShippingAction from "./AddPopUpShippingAction"
import AddPopUpReplaceAction from "./AddPopUpReplaceAction"

export const ShippedOutProduct = () => {
    const [searchParams] = useSearchParams()
    const Name = searchParams.get("Name")

    const [ShippingAction, setShippingAction] = useState(false)
    const handleShippingAction = () => {
        setShippingAction(false)
    }
    const [ReplaceAction, setReplaceAction] = useState(false)
    const handleReplaceAction = () => {
        setReplaceAction(false)
    }

    return (
        <>
            <div className="container-scroller">
                <HeaderDashboard />
                <div className="container-fluid page-body-wrapper m-0">

                    <Sidebar active='dashboard' />

                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="card-body grid-margin">
                                <h6 className="d-flex justify-content-between pb-2 green" style={{ fontSize: '19px' }}>{Name}
                                    {/* <button class="btn btn-success py-1" type="submit" */}
                                    {/* // onClick={() => { setProductOffers(true) }} */}
                                    {/* > */}
                                    {/* <i class="fa-solid fa-plus pe-1"></i> Add</button> */}
                                </h6>

                                <div className="row p-2 m-0 bg-white rounded" >
                                    <div className="bgcolor pb-2">
                                        <div className="row">
                                            <div class="my-1 col-12 col-md-4 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Company Name</label>
                                                <ReactDDL />
                                            </div>


                                            <div class="my-1 col-12 col-md-4 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Category</label>
                                                <ReactDDL />
                                            </div>


                                            <div class="my-1 col-12 col-md-4 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Name</label>
                                                <ReactDDL />
                                            </div>

                                            <div class="my-1 col-12 col-md-4 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Size Unit</label>
                                                <ReactDDL />
                                            </div>

                                            <div class="my-1 col-12 col-md-4 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">From Date</label>
                                                <input type="date" class="form-control" />
                                            </div>

                                            <div class="my-1 col-12 col-md-4 ">
                                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">To Date</label>
                                                <input type="date" class="form-control" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 table_scroll p-0">
                                        <table className="table  text-center bg-white mt-2 " >
                                            <thead className="parrot_green">
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Order Date</th>

                                                    {Name == "Returned Product" ?
                                                        <th>Order Number</th> :
                                                        <></>}
                                                    {Name != "Returned Product" ?
                                                        <th>Order ID</th> :
                                                        <></>}
                                                    <th>Consumer Name</th>
                                                    <th>Mobile Number</th>
                                                    <th>Email</th>

                                                    {Name != "Delivered Product" ?
                                                        <th>Company Name</th> :
                                                        <></>
                                                    }
                                                    <th>Product Category</th>
                                                    <th>Product Name</th>
                                                    <th>Product Size Unit</th>
                                                    <th>Quantity</th>
                                                    {Name == "Shipped Out Product" ?
                                                        <th>Action </th> :
                                                        <></>
                                                    }
                                                    {Name == "Returned Product" ?
                                                        <th>Action </th> :
                                                        <></>
                                                    }

                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr >
                                                    <td> 1 </td>
                                                    <td></td>

                                                    {Name == "Returned Product" ?
                                                        <td></td> :
                                                        <></>}

                                                    {Name != "Returned Product" ?
                                                        <td></td> :
                                                        <></>}

                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    {Name != "Delivered Product" ?
                                                        <td></td> :
                                                        <></>
                                                    }

                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    {Name == "Shipped Out Product" ?
                                                        <td><i class="fa-solid fa-truck-fast fs-8 cursor_pointer"
                                                            onClick={() => { setShippingAction(true) }}></i></td> :
                                                        <></>
                                                    }

                                                    {Name == "Returned Product" ?
                                                        <td><i class="fa-solid fa-up-down-left-right cursor_pointer"
                                                            onClick={() => { setReplaceAction(true) }}></i></td> :
                                                        <></>
                                                    }

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {ShippingAction ?
                <AddPopUpShippingAction
                    cancelbtn={handleShippingAction}
                    heading={Name}
                /> : <></>
            }

            {ReplaceAction ?
                <AddPopUpReplaceAction
                    cancelbtn={handleReplaceAction}
                    heading={Name}
                /> : <></>
            }

        </>
    )
}
import { useState } from "react";
import { ReactDDL } from "../CommonDDL/ReactDDL";

const AcknowledgeAddPopUp = ({ cancelbtn, confirmBtnCallBack, deleteRecord, message, heading }) => {

    return (<>
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog " role="document" id="modal-lg">
                <div className="modal-content p-3">
                    <div className="modal-header pt-0">

                        <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle"
                        >{heading} </h5>
                        <button onClick={() => cancelbtn()} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <div className="modal-body mx-0">

                        <div className="row p-2 m-0 " >

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8"> Date</label>
                                <input type="date" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Order Number</label>
                                <input type="text" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Consumer Name</label>
                                <input type="text" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Company Name</label>
                                <ReactDDL />
                            </div>
                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Category</label>
                                <ReactDDL />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Name</label>
                                <input type="text" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Size Unit</label>
                                <input type="text" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Quantity</label>
                                <input type="text" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Amount</label>
                                <input type="text" class="form-control" />
                            </div>

                            <div class="my-1 col-12 col-md-3 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Receive Amount</label>
                                <input type="text" class="form-control" />
                            </div>
                            {/* 
                            <div className="col-12 col-lg-6">
                                <div class="input-group my-3">
                                    <input type="text" class="form-control" placeholder="Search..." />
                                    <span class="input-group-text text-white" id="basic-addon">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                </div>
                            </div> */}
                            <hr className="mt-3" />
                            <div className="row text-center">
                                <div className="col-12">
                                    <button
                                        type='button'
                                        onClick={cancelbtn}
                                        className=" btn addbtn btn-success  m-2 px-2" data-ktwizard-type="action-next">
                                        Acknowledge
                                    </button>
                                    <button
                                        type="button"
                                        onClick={cancelbtn}
                                        className=" btn  btn-danger  m-2 px-4" data-ktwizard-type="action-next">
                                        Cancel

                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </>);
}

export default AcknowledgeAddPopUp;
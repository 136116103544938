
import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const About = () => {
    return (
        <>
            <div className="row " onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12 ">
                    <Navbar />
                    <Header actives="About" subactive="About" />
                    <HerosectionsAll />
                    <div className="row py-lg-5 mb-5  p-3 p-lg-5 mt-lg-5">

                        <div className="col-12 col-lg-6 mb-5">
                            <h2 className="fw-bold green pt-3 pt-lg-0" style={{ fontSize: '26px' }}>
                                <span className="text-danger " > ABOUT </span> US</h2>
                            <h2 className=" fw-bold pt-2">We Providing More Quality Products</h2>
                            <p class=" pt-2 pe-lg-5" style={{ textAlign: 'justify' }} >
                                <span className="green fw-bold "> PALVI AGRO CHEMICALS & FERTILIZER </span> is
                                pioneer in developing innovative products for comprehensive crop production.
                                We are providing solutions for various crop diseases that will help farmers
                                grow disease free and healthy crops. We have come up with exceptional and wide
                                variety Bio-chemicals and fertilizer to  make our farmers as rich, healthy, and
                                prosperous as they aspire to be by helping them produce disease free healthy,
                                weighty and fruitful crops and make farming soil fertile and reproductive as
                                it should be.

                                Our products are suitable for all cropping conditions around the world to improve
                                crop productivity and soil health. Our products tailered/designed to produce more
                                crop yield per unit area while improving the eco system and bringing sustainability
                                to agriculture. Currently we are catering to Indian markets  in few State
                                and desire to few parts of the  countries around the world.</p>
                        </div>

                        <div className="col-12 col-lg-6 about">
                        </div>

                        <div className="row pt-4">
                            <div className="col-12 col-lg-3 py-2">
                                <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                    <h2 className=" fw-bold">  25 +</h2>
                                    <span className="fw-bold green">   Years Experience</span>
                                </div>
                            </div>

                            <div className="col-12 col-lg-3 py-2">
                                <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                    <h2 className=" fw-bold">  110 +</h2>
                                    <span className="fw-bold green">      Agriculture Products</span>
                                </div>
                            </div>

                            <div className="col-12 col-lg-3 py-2">
                                <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                    <h2 className=" fw-bold">  25K +</h2>
                                    <span className="fw-bold green">      Satisfied Customers</span>
                                </div>
                            </div>

                            <div className="col-12 col-lg-3 py-2">
                                <div className="faint_bg p-4 rounded border border-1 border-success text-center">
                                    <h2 className=" fw-bold">  7 +</h2>
                                    <span className="fw-bold green">States</span>
                                </div>
                            </div>
                        </div>


                        {/* <div className="row" id="our_value">
                            <h2 className=" pb-3 pt-5 fw-bold green text-center" style={{ fontSize: '26px' }}>
                                <span className="text-danger ">OUR</span> VALUES</h2>
                            <div className="col-12 col-lg-6 pt-2 pe-lg-5">
                                <p>  <span className="green fw-bold">Customer -</span>  Our products and services exceeds expectations.
                                </p>
                                <p> <span className="green fw-bold">Simplicity -</span> We keep business simple and easy to understand and focus on the key issues not making
                                    things complicated and keeping things as simple as it can be.
                                </p>
                                <p>  <span className="green fw-bold">Excellence -</span> Do it better today than yesterday.
                                </p>
                               
                                <p>  <span className="green fw-bold">Compassion -</span> We care for the employees and their family.
                                </p>
                                <p> <span className="green fw-bold">Work & life balance -</span> we
                                    make employees by giving happy family time and happy work life.
                                </p>
                                <p> <span className="green fw-bold">Transparency -</span> We share information highlighting problems as well as successes. We do not hide
                                    information.
                                </p>

                            </div>
                            <div className="col-12 col-lg-6 pt-2 pe-lg-5">

                                <p> <span className="green fw-bold">Virtue -</span> Own our successes and failuresFreedom to innovate - we are full
                                    of ideas.
                                </p>
                                <p> <span className="green fw-bold">Integrity -</span> We are honest in all that we do.
                                </p>
                                <p> <span className="green fw-bold">Supportive -</span> We are always there for each other.
                                </p>
                                <p>  <span className="green fw-bold">Innovation -</span> See the standard and improve on it.
                                </p>
                                <p> <span className="green fw-bold">Team Work and Collaboration -</span> We work together sharing ideas in an open constructive way and together we achieve more.
                                </p>
                                <p> <span className="green fw-bold">Accountability -</span> We do what we say.   </p>
                                <p> <span className="green fw-bold">Respectful -</span> We always look for the best in people. We care for each other. We give respect to one and
                                    all.
                                </p>
                                <p>


                                </p>
                            </div>
                        </div> */}
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}

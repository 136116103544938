import React, { useEffect } from "react";
import { ReactDDL } from "../CommonDDL/ReactDDL";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ProductsShipping = () => {
    const [searchParams]=useSearchParams()
    const Name = searchParams.get("Name")

    
    const navigate =useNavigate()
    return (
        <>
            <h6 className="green pt-3" style={{ fontSize: '19px' }}>  Product Shipping </h6>
            <div className="row bg-white pb-1 rounded shadow2">

                <div className="col-12 col-lg-3 p-3 ">
                    <div className=" px-3 pt-3 pb-1 gray_bgs" onClick={()=> navigate(`/ShippedOutProduct?Name=Shipped Out Product`)}>
                        <h6>Shipped out Product</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/shipped.png" alt="profile" width={32} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bgs" onClick={()=> navigate(`/ShippedOutProduct?Name=Delivered Product`)}>
                        <h6>Delivered Product</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ReceivedOrder2.png" alt="profile" width={28} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bgs" onClick={()=> navigate(`/ShippedOutProduct?Name=Returned Product`)}>
                        <h6>Returned Product</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/product_return.png" alt="profile" width={30} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bgs" onClick={()=> navigate(`/ShippedOutProduct?Name=Replaced Product`)}>
                        <h6>Replaced Product</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ReplacedProduct.png" alt="profile" width={30} />
                            </span>
                        </h4>
                    </div>
                </div>

               


            </div>

        </>
    )
}
import { useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../../Footer/Footer"
import { Header } from "../../IndexPage/Header/Header"
import { useState } from "react";
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";

export const ChangePassword = () => {

    const navigate = useNavigate()

    return (
        <>
            <HeaderEcommerce actives="ChangePassword" />
            <div className="row p-4 " onLoad={() => window.scrollTo(0, 0)} style={{ background: '#edecec' }}>
                <div className="col-12 col-lg-4  mx-auto  border2 shdows rounded p-3 py-lg-4 px-lg-5 bg-white" >
                    <div className="text-center">
                        <img src="Static/Assets/Img/logo.png" onClick={() => navigate('/')} alt="logo here" className="logo_bg" />
                    </div>

                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" id="floatingInput" placeholder="Current Password" />
                        <label for="floatingInput">Current Password </label>
                    </div>

                    <div class="form-floating mb-3">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="New Password" />
                        <label for="floatingPassword">New Password </label>
                    </div>

                    <div class="form-floating">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="New Password" />
                        <label for="floatingPassword">Confirm Password </label>
                    </div>

               
                  
                    <div className="pt-3">
                        <button type="button" class=" parrot_green text-white w-100 fw-bold border2 fs-5 py-2"
                            onClick={() => navigate('/')}
                        >Submit</button>
                    </div>

               
                </div>


            </div>


            <Footer />



        </>
    )
}

import { ReactDDL } from "../CommonDDL/ReactDDL";


const AddPopUpReceiveOrder = ({ cancelbtn, confirmBtnCallBack, deleteRecord, message, heading }) => {

    return (<>
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog dialog_width " role="document" >
                <div className="modal-content p-3" style={{background:'#ffffff'}}>
                    <div className="modal-header pt-0">

                        <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{heading}</h5>
                        <button onClick={() => cancelbtn()} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0" >

                        <div className="row p-2 m-0 " >

                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8"> Date</label>
                                <input type="date" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Order Number</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Customer Name</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Address</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Mobile Number</label>
                                <input type="text" class="form-control" />
                            </div>
                         
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Email</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Company Name</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Category</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Name</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Product Size Unit</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fs-8">Quantity</label>
                                <input type="text" class="form-control" />
                            </div>

                        </div>

                    </div>

                    <hr className="mt-2" />

                    <div className="row text-center">
                        <div className="col-12">
                            <button
                                type='button'
                                onClick={cancelbtn}
                                className="btn addbtn btn-success m-2 px-2" data-ktwizard-type="action-next">
                                <i class="fa-solid fa-floppy-disk pe-1"></i>Approve
                            </button>
                            <button
                                type="button"
                                onClick={cancelbtn}
                                className=" btn addbtn btn-danger m-2 px-3" data-ktwizard-type="action-next">
                                <i class="fa-solid fa-xmark pe-1"></i>Reject

                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </>);
}

export default AddPopUpReceiveOrder;

import { useNavigate } from "react-router-dom";
import $ from 'jquery'
import { ReactDDL } from "./CommonDDL/ReactDDL";


export const HeaderDashboard = () => {
    const navigate = useNavigate()

    var body = $('body');

    const SidebarHideShow = () => {
        if ((body.hasClass(''))) {
            body.toggleClass('sidebar-icon-only');
        }
        else {
            body.toggleClass('sidebar-icon-only');
        }
    }

    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">

            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center " >
                <span className="navbar-brand brand-logo">
                    <img src="Static/Assets/Img/logo.png" className="mr-2 mb-1" alt="logo" style={{ width: "125px", height: "auto" }} />
                </span>
                <span className="navbar-brand brand-logo-mini">
                    <img src="Static/Assets/Img/logo.png" alt="logo" className=" " style={{ width: "105px", height: "auto" }} />
                </span>
            </div>

            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end " >
                <ul className="navbar-nav mr-5" style={{ listStyle: "none" }}>
                    <li className="nav-item nav-search">
                        <div className="input-group">
                            <div className="input-group-prepend hover-cursor" id="navbar-search-icon" onClick={SidebarHideShow}>
                                <span className="input-group-text" id="search">
                                    <i class="fa-solid fa-bars py-1"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>

                <h4 className="px-lg-5 ps-3 heading text-dark me-lg-5 ">Palvi Agro Chemicals & Fertilizer</h4>
          
                    <span className="mx-3 float-right "><ReactDDL /></span> 
                    <span className="mx-3 float-right"><ReactDDL /></span> 

           
              
                       
           
                <ul className="navbar-nav navbar-nav-right pe-lg-3">
                  

                    <li className="nav-item nav-profile dropdown">
                    
                        <a className="nav-link dropdown-toggle pt-3" href="#" data-toggle="dropdown" id="profileDropdown">
                            <img src="Static/Assets/Img/Dashboard/admin.png" alt="profile" width={35}
                                onClick={() => navigate('/LoginPage')} />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                            <a className="dropdown-item"
                            // onClick={handleLogout}
                            >
                                <i className="ti-power-off text-primary pe-3"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                    {/* <li className="nav-item nav-settings d-none ">
                        <a className="nav-link" href="#">
                            <i className="icon-ellipsis"></i>
                        </a>
                    </li> */}
                </ul>

                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                    <span className="icon-menu"></span>
                </button>

            </div>
        </nav>
    )
}
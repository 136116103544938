
import { useNavigate, useSearchParams } from "react-router-dom";
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";
import { Footer } from "../../Footer/Footer";

export const ForgotPassword = () => {

    const navigate = useNavigate()

    return (
        <>
            <div className="row p-4 d-flex align-items-center justify-content-center" style={{height:'100vh', background: '#edecec'}} onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-4  mx-auto  border2 shdows rounded p-3 p-lg-5 bg-white" >

                    <div className="text-center">
                        <img src="Static/Assets/Img/logo.png" onClick={() => navigate('/')} alt="logo here" className="logo_bg" />
                    </div>

                    <h4 className="text-center py-3">Password Assistance</h4>
                    <p className="text-center">Enter the Email Address or Mobile Number associated with your Palvo Agro's account.</p>

                    <div class="form-floating">
                        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword"> Email or mobile phone number </label>
                    </div>

                 
                    <div className="pt-4">
                        <button type="button" class=" parrot_green text-white w-100 fw-bold border2 fs-5 py-2"
                            onClick={() => navigate('/Approval')}
                        >Continue</button>
                    </div>

                </div>

                <p className="fs-6 text-center ">
                        <span className="green text-decoration-underline cursor_pointer px-2" onClick={() => navigate('/PrivacyNotice')}> Privacy Notice </span> &
                        <span className="green text-decoration-underline cursor_pointer px-2" onClick={() => navigate('/TermsCondition')}> Terms and Condition </span></p>



            </div>


        </>
    )
}

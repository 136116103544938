
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const GANNA_POWER = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/GANNA_POWER-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 ">GANNA POWER</h4>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Introduction of Ganna Power :</h5>
                    <ul>
                        <li> GANNA Power is a Hi-Tech Research Product based on
                            Nano Technology molecules.</li>
                            <li>It Shows Fast Results on Sugarcane crop.</li>
                        <li>It is Formulated
                            With Advanced molecules related to growth & Root Devolvement.</li>
                        <li> GANNA Power is
                            Designed by considering plant physiology of sugarcane formore &uniform tailoring.</li>
                        <li> GANNA Power Kit has total seven Applications, which contains 2-Drenching & 2-Foliar
                            sprayingIn Drenching it measurly works as Highly rooting stimulant that can Uptake
                            allessential nutrients & Supply to all parts of plant.</li>
                        <li> Drenching material has longer
                            effects on Root.. In spraying we provide 4-Spraying applications, that can enhance the
                            plant to maximum tillering, width of leaves, Inter node distance & also width of stem. </li>
                        <li>It
                            measurly increases the Mitochondrial activity that can increases thephotosynthesis in
                            leaves. It also increases Disease & Pest resistance in sugarcane. </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                            1 pack of ganna power per acre.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}


export const OurSaleNetworks = () => {
    return (
        <>
            <div class="row  p-lg-5 img_green mt-5" data-aos="zoom-out-down">
                <div class="col-12 col-lg-6 px-lg-5">
                    <img src="Static/Assets/Img/map-01.png " className="vision_img" alt="" />
                </div>
                <div class="col-12 col-lg-6 p-lg-5  text-white">
                    <h3 class="fw-bold mb-4 heading_bg p-2 text-center rounded">OUR SALES NETWORK SO FAR</h3>

                    <div className="row">
                        <div className="col-8 col-lg-6 text-end  px-lg-4">
                            <h5 className="text-white pt-2"> MAHARASHTRA <span className=""></span> </h5>
                            <h5 className="text-white pt-2"> ANDHRA PRADESH </h5>
                            <h5 className="text-white pt-2"> KARNATAKA </h5>
                            <h5 className="text-white pt-2"> TELANGANA </h5>
                            <h5 className="text-white pt-2"> TAMIL NADU </h5>
                            <h5 className="text-white pt-2"> KERALA </h5>
                            <h5 className="text-white pt-2"> ODISHA </h5>
                        </div>
                        <div className="col-4 col-lg-4  px-lg-4">
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#f58020' }}></i></h5>
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#53519f' }}></i></h5>
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#e20b86' }}></i></h5>
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#e02f2e' }}></i></h5>
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#bc2668' }}></i></h5>
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#fbed20' }}></i></h5>
                            <h5 className="text-white pt-2"> <i class="fa-solid fa-location-dot" style={{ color: '#fab815' }}></i></h5>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

import { Link, useNavigate } from "react-router-dom"

export const HeaderEcommerce = ({ actives }) => {
    const navigate = useNavigate()

    return (
        <>
            <div className="row bg-white shadow sticky">
                <div className="col-12 ">
                    <nav class="navbar navbar-expand-lg navbar-light   ">
                        <div class="container-fluid ">
                            <a href="javascript:void(0)"
                                onClick={() => navigate('/Products?ScreenName=Products')}>
                                <img src="Static/Assets/Img/gallery/palvo_agrico-01.png" alt="logo here" class="logo_bg2" />
                            </a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li class="nav-item">
                                        <Link to="/Products?ScreenName=Products"
                                            className=
                                            {actives == "Products" ? "actives" : "text-dark"}
                                        >Products
                                        </Link>
                                        {/* <a class="nav-link active cursor_pointer" aria-current="page" onClick={() => navigate('/')}>Home</a> */}
                                    </li>
                                    <li class="nav-item">
                                        <Link to="/Account"
                                            className=
                                            {actives == "Account" ? "actives px-lg-4" : "px-lg-4 text-dark"}
                                        >Account
                                        </Link>
                                        {/* <a class="nav-link active cursor_pointer" aria-current="page" onClick={() => navigate('/')}>Home</a> */}
                                    </li>
                                    <li class="nav-item">
                                        {/* <a class="nav-link cursor_pointer" onClick={() => navigate('/About')}>About</a> */}
                                        <Link to="/Orders"
                                            className=
                                            {actives == "Orders" ? "actives px-lg-4" : "px-lg-4 text-dark"}
                                        >Orders
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to="/LoginPage"
                                            className=
                                            {actives == "Login" ? "actives px-lg-4" : "px-lg-4 text-dark"}
                                        >Login
                                        </Link>
                                    </li>

                                    {/* <li class="nav-item">
                                        <Link to="/Register"
                                            className=
                                            {actives == "Register" ? "actives px-lg-4" : "px-lg-4 text-dark"}
                                        >Register
                                        </Link>
                                    </li> */}

                                    <li class="nav-item">
                                        <Link to="/AddtoCard"
                                            className={actives == "AddtoCard" ? "actives px-lg-4 " : "px-lg-4 text-dark"}>
                                            <span> <i class="fa-solid fa-cart-shopping position-relative fs-5"></i></span>

                                            <span class="position-absolute translate-middle badge rounded-pill bg-danger" >
                                                2</span>

                                            <span className="ps-2">Cart</span>

                                        </Link>
                                    </li>

                                </ul>
                                {/* <span className="ps-4"> <button type="button" class="btn btn-danger btndanger" onClick={()=> navigate ('/Products?ScreenName=Products')}>Products</button></span> */}

                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

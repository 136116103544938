import { useNavigate, useSearchParams } from "react-router-dom"
import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const HerosectionsAll = () => {
    const navigate = useNavigate()
    const [searchparams] = useSearchParams()
    const ScreenName = searchparams.get('ScreenName')
    const Name = searchparams.get('Name')
    return (
        <>
            <div className="row">
                <div className="col-12 HerosectionsAll d-flex">
                    <div className="col-auto m-auto ">
                        <h2 className="white text-center text-white display-5 fw-bold" style={{letterSpacing:'1px'}}> {ScreenName} </h2>
                        <nav aria-label="breadcrumb " className="first  d-md-flex text-center">
                            <ol className="breadcrumb indigo lighten-6 first-1 shadow-lg pt-4 text-center ">
                                <li>
                                    <a className=" ms-1 text-white  fs-5" href="javascript:void(0) "
                                        onClick={() => navigate('/')} ><span>Home</span></a>
                                    <i className="fas fa-angle-double-right text-white px-3"></i> </li>
                                {
                                    Name &&
                                    <li><a className=" text-white fs-5"
                                        href="javascript:void(0)"><span className="text-white  ">{Name}</span></a> <i
                                            className="fas fa-angle-double-right text-white px-3"></i></li>
                                }
                                <li className="breadcrumb-item font-weight-bold text-white fs-5 fw-bold"><a className="text-white "
                                    href="javascript:void(0)"><span>{ScreenName}</span></a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect } from "react";
import { HeaderDashboard } from "./HeaderDashboard";
import { Sidebar } from "./Sidebar";
import { EnquiryDetails } from "./EnquiryDetails/EnquiryDetails";
import { ProductInventory } from "./ProductInventory";
import { ProductsShipping } from "./ProductShipping/ProductsShipping";
import { PaymentDetails } from "./PaymentDetails/PaymentDetails";
import { ProductReviewAndRating } from "./ProductReviewAndRating";
import { ProductOfferAndDiscounts } from "./ProductOfferAndDiscounts/ProductOfferAndDiscounts";
import { OrderManagement } from "./OrderManagement/OrderManagement";

export default function Dashboard() {

    return (
        <>
            <div className="container-scroller">
                <HeaderDashboard />
                <div className="container-fluid page-body-wrapper m-0">

                    <Sidebar active='dashboard' />

                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="card-body grid-margin">
                                {/* <h6 style={{ fontSize: '19px' }}>Admin Dashboard Panel</h6> */}


                                <EnquiryDetails />
                                <ProductInventory />
                                <ProductOfferAndDiscounts />
                                <OrderManagement />
                                <ProductsShipping />
                                <PaymentDetails />
                                <ProductReviewAndRating />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
import React, { useEffect, useState } from "react";
import ApprovedEnquiriesAddPopUp from "./ApprovedEnquiries";
import { useNavigate } from "react-router-dom";

export const EnquiryDetails = () => {

    const navigate = useNavigate()


    const [AddPopUpReceiveEnquiries, setAddPopUpReceiveEnquiries] = useState(false)
    const handleReceiveEnquiriesClick = () => {
        setAddPopUpReceiveEnquiries(false)
    }

    const [AddPopUpApprovedEnquiries, setAddPopUpApprovedEnquiries] = useState(false)
    const handleApprovedEnquiriesClick = () => {
        setAddPopUpApprovedEnquiries(false)
    }

    return (
        <>
            <h6 className="green d-flex justify-content-between" style={{ fontSize: '19px' }}> Enquiry Details
                <button class="btn btn-success py-1 " type="submit"
                    onClick={() => navigate("/OrderTracking")}
                >
                    <img src="Static/Assets/Img/order-tracking.png" alt="" /> Track Orders </button>
            </h6>

            <div className="row rounded bg-white shadow2">
                <div className="col-12 col-lg-6 border-end">
                    <div className=" pt-2 pb-3 ">
                        <h6 className="fw-bold">By Contacts</h6>
                        <div className="row m-0">

                            <div className="col-12 col-lg-6 pe-lg-4">
                                <div className="row gray_bg" onClick={() => navigate('/ReceivedEnquiries?Name=Received Enquiries')}
                                //  { setAddPopUpReceiveEnquiries(true) }
                                >
                                    <div className="col-9 pt-2">
                                        <p className="">Received Enquiries</p>
                                        <h4 className="green fw-bold">50</h4>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                        <img src="Static/Assets/Img/Dashboard/received.png" alt="profile" width={40} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 mt-2 mt-lg-0 ps-lg-4">
                                <div className="row gray_bg" onClick={() => navigate('/ApprovedEnquiries?Name=Approved Enquiries')}>
                                    <div className="col-9 pt-2">
                                        <p className="">Approved Enquiries</p>
                                        <h4 className="green fw-bold">20</h4>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                        <img src="Static/Assets/Img/Dashboard/Approved.png" alt="profile" width={40} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 ">
                    <div className=" pt-2 pb-3 rounded bg-white">
                        <h6 className="fw-bold">By Registration</h6>
                        <div className="row m-0">

                            <div className="col-12 col-lg-6 pe-lg-4">
                                <div className="row gray_bg" onClick={() => navigate('/ReceivedEnquiries?Name=Received Enquiries')}>
                                    <div className="col-9 pt-2">
                                        <p className="">Received Enquiries</p>
                                        <h4 className="green fw-bold">50</h4>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                        <img src="Static/Assets/Img/Dashboard/received.png" alt="profile" width={40} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6  ps-lg-4 mt-2 mt-lg-0">
                                <div className="row gray_bg"  onClick={() => navigate('/ApprovedEnquiries?Name=Approved Enquiries')}>
                                    <div className="col-9 pt-2">
                                        <p className="">Approved Enquiries</p>
                                        <h4 className="green fw-bold">20</h4>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                        <img src="Static/Assets/Img/Dashboard/Approved.png" alt="profile" width={40} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            {/* {AddPopUpReceiveEnquiries ?
                <ReceiveEnquiriesAddPopUp
                    cancelbtn={handleReceiveEnquiriesClick}
                    heading="Receive Enquiries"
                /> : <></>
            } */}
            {AddPopUpApprovedEnquiries ?
                <ApprovedEnquiriesAddPopUp
                    cancelbtn={handleApprovedEnquiriesClick}
                    heading={"Approved Enquiries"}
                /> : <></>
            }
        </>

    )
}






import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const SIZE_SUPER = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/SIZE_SUPER-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> SIZE SUPER <span className="fs-6">(Biostimulant)</span></h4>
                    <h6 className="py-2 ps-3">
                    Size super is a combination of amino acid it promotes vegetative growth. It increses
                    photosynthesis process.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>It induces more flowring & Fruiting.</li>
                        <li>It also prevents shedding of buds, flowers.</li>
                        <li>It improves quality of flowers & fruits.</li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        500 ml / acre for spraying.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                        250ml, 500ml, 1ltr.
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

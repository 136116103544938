import { useState } from "react";
import { ReactDDL } from "../../CommonDDL/ReactDDL";
import { MultiSeletDDL } from "../../CommonDDL/MultiSeletDDL";

const AddPopUpSpecialOffers = ({ cancelbtn, confirmBtnCallBack, deleteRecord, message, heading }) => {
    const [WardIDData, setWardIDData] = useState([]);
    const [selected, setSelected] = useState([]);

    return (<>
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog dialog_width " role="document" >
                <div className="modal-content p-3">
                    <div className="modal-header pt-0">

                        <h5 className="card-title " style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{heading}</h5>
                        <button onClick={() => cancelbtn()} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0">

                        <div className="row p-2 m-0 " >

                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Scheme Type</label>
                                <ReactDDL />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Starting Date</label>
                                <input type="date" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Closing Date</label>
                                <input type="date" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Offer Amount(Rs)</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Company Name</label>
                                <ReactDDL />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Category</label>
                                <ReactDDL />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Name</label>
                                <ReactDDL />
                                {/* <MultiSeletDDL

                                    WardIDData={WardIDData}
                                    setWardIDData={setWardIDData}
                                    selected={selected}
                                    setSelected={setSelected}
                                /> */}
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Pack Size</label>
                                <ReactDDL />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Unit Rate(Rs.)</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Qty</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Total Amount(Rs.) </label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="my-1 col-12 col-md-4 ">
                                <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Offer Details</label>
                                <input type="text" class="form-control" />
                            </div>

                        </div>

                    </div>

                    <hr className="mt-2" />

                    <div className="row text-center">
                        <div className="col-12">
                            <button
                                type='button'
                                onClick={cancelbtn}
                                className="btn addbtn btn-success m-2 px-4" data-ktwizard-type="action-next">
                                <i class="fa-solid fa-floppy-disk pe-1"></i> Save
                            </button>
                            <button
                                type="button"
                                onClick={cancelbtn}
                                className=" btn addbtn btn-danger m-2 px-3" data-ktwizard-type="action-next">
                                <i class="fa-solid fa-xmark pe-1"></i> Cancel

                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </>);
}

export default AddPopUpSpecialOffers;
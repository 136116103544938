const AddressModal = ({ cancelbtn, confirmBtnCallBack, deleteRecord, message, heading }) => {

    return (<>
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090", zIndex:'99999' }}>
            <div className="modal-dialog dialog_width " role="document" >
                <div className="modal-content">
                    <div className="modal-header py-0 " style={{ background: '#f0f2f2' }}>

                        <h5 className="card-title pt-4 ps-3 pb-2" id="exampleModalLongTitle" style={{ fontSize: '17px' }}>{heading}</h5>
                        <button onClick={() => cancelbtn()} type="button" className="close fs-4" style={{ background: '#ebebeb' }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0 modal_body" >

                        <div className="row px-3">
                            <div className="col-12">
                                {/* <h6 className="fw-bold">Add a new address</h6>
                                <p className="px-5 bg-green text-dark py-3 my-2">Save time. Autofill your current location. <span className="bg-green rounded px-3 py-1 ms-3 bg-white cursor_pointer">Autofill</span></p> */}

                                <div class="mb-3 ">
                                    <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Country/Region</label>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>India</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Full Name (First and Last name)</label>
                                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Mobile Number</label>
                                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Mobile Number" />
                                    <p className="fs-8 green">May be used to assist delivery</p>
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Pincode</label>
                                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="6 Digits [0-9] Pin Code" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Flat, House no., Building, Company, Apartment</label>
                                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Area, Street, Sector, Village</label>
                                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                                </div>

                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label fw-bold fs-8">Landmark</label>
                                    <input type="email" class="form-control" id="exampleFormControlInput1" />
                                </div>
                                <div className="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label fw-bold fs-8">Town/City</label>
                                        <input type="email" class="form-control" id="exampleFormControlInput1" />
                                    </div>

                                    <div class="col-12 col-lg-6 mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label fw-bold fs-8"> State</label>
                                        <input type="email" class="form-control" id="exampleFormControlInput1" />
                                    </div>
                                </div>
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                    <label class="form-check-label" for="exampleCheck1">Make this my default address</label>
                                </div>
                            </div>



                            <button type="submit" class="border-0 py-2 text-white parrot_green">Use This Address</button>

                        </div>

                    

                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default AddressModal;
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const OrderManagement = () => {
    // const [ReceiveOrder, setReceiveOrder] = useState(false)
    // const handleReceiveOrder = () => {
    //     setReceiveOrder(false)
    // }
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')

    return (
        <>
            <h6 className="green pt-3" style={{ fontSize: '19px' }}> Order Management </h6>
            <div className="row bg-white pb-1 rounded shadow2">

                <div className="col-12 col-lg-2 p-3 Same_Width ">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceiveOrder?Name=Received Order`)}>
                        <h6>Received Order</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ReceivedOrder.png" alt="profile" width={32} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-2 p-3 Same_Width">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceiveOrder?Name=Approved Order`)}>
                        <h6>Approved Order</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ApprovedOrder.png" alt="profile" width={30} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-2 p-3 Same_Width">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceiveOrder?Name=Pending Order`)}>
                        <h6>Pending Order</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/PendingOrder.png" alt="profile" width={30} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-2 p-3 Same_Width">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceiveOrder?Name=Rejected Order`)}>
                        <h6>Rejected Order</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/RejectedOrder.png" alt="profile" width={28} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-2 p-3 Same_Width">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceiveOrder?Name=Cancelled Order`)}> 
                        <h6>Cancelled Order</h6>
                        <h4 className="green">30
                            <span className="float-end pt-0">
                                <img src="Static/Assets/Img/Dashboard/CancelledOrder.png" alt="profile" width={30} />
                            </span>
                        </h4>
                    </div>
                </div>


            </div>
            {/* {
                ReceiveOrder ?
                    <AddPopUpReceiveOrder
                        cancelbtn={handleReceiveOrder}
                        heading="Received Order"
                    /> : <></>
            } */}
        </>
    )
}
import React, { useEffect } from "react";
import { ReactDDL } from "../CommonDDL/ReactDDL";
import { useNavigate } from "react-router-dom";

export const PaymentDetails = () => {
    const navigate = useNavigate()

    return (
        <>
            <h6 className="green pt-3" style={{ fontSize: '19px' }}>  Payment Details </h6>
            <div className="row bg-white pb-1 rounded shadow2">

                <div className="col-12 col-lg-3 p-3 ">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceivedPayment?Name=Received Payment`)}>
                        <h6>Received Payment</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ReceivedPayment.png" alt="profile" width={33} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceivedPayment?Name=Acknowledge Payment`)}>
                        <h6>Acknowledge Payment</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/AcknowledgePayment.png" alt="profile" width={32} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceivedPayment?Name=Pending Payment`)}>
                        <h6>Pending Payment</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/PendingPayment.png" alt="profile" width={32} />
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bg" onClick={() => navigate(`/ReceivedPayment?Name=Refund Payment`)}>
                        <h6>Refund Payment</h6>
                        <h4 className="green">30
                            <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/RefundPayment.png" alt="profile" width={32} />
                            </span>
                        </h4>
                    </div>
                </div>




            </div>

        </>
    )
}
import React from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { IndexPage } from "./Components/IndexPage/IndexPage";
import { About } from "./Components/About/About";
import { Vision } from "./Components/Vision/Vision";
import { Mission } from "./Components/Mission/Mission";
import { HerosectionsAll } from "./Components/HerosectionsAll/HerosectionsAll";
import { Contact } from "./Components/Contact/Contact";
import { HERBO_STRICK_Products } from "./Components/IndexPage/Products/HERBO_STRICK_Products";
import { CYTO_BOOST_Products } from "./Components/IndexPage/Products/CYTO_BOOST_Products";
import { ThreeD_WEED_Products } from "./Components/IndexPage/Products/ThreeD_WEED_Products";
import { CHLOPAL_BOOST_Products } from "./Components/IndexPage/Products/CHLOPAL_BOOST_Products";
import { Chlopal_Diamond_Products } from "./Components/IndexPage/Products/Chlopal_Diamond_Products";
import { CHLOPAL_SUPER_Products } from "./Components/IndexPage/Products/CHLOPAL_SUPER_Products";
import { CYMINT_Products } from "./Components/IndexPage/Products/CYMINT_Products";
import { JET_PLUS_Products } from "./Components/IndexPage/Products/JET_PLUS_Products";
import { PARAPAL_Products } from "./Components/IndexPage/Products/PARAPAL_Products";
import { PESTIGO_Products } from "./Components/IndexPage/Products/PESTIGO_Products";
import { Products } from "./Components/IndexPage/Products/Products";
import { InvestorDetails } from "./Components/InvestorDetails/InvestorDetails";
import { ManufacturingUnit } from "./Components/IndexPage/ManufactureUnit/ManufactureUnit";
import { ROOT_42_F } from "./Components/IndexPage/Products/ROOT_42_F";
import { GREEN_LUSH_SP } from "./Components/IndexPage/Products/GREEN_LUSH_SP";
import { Green_Spark_SP } from "./Components/IndexPage/Products/Green_Spark_SP";
import { GANNA_POWER } from "./Components/IndexPage/Products/GANNA_POWER";
import { AUSTIN_SUPER, AUSTINSUPER } from "./Components/IndexPage/Products/AUSTINSUPER";
import { NUTRI_POWER } from "./Components/IndexPage/Products/NUTRI_POWER";
import { Maxima } from "./Components/IndexPage/Products/Maxima";
import { GREENN_ZYME } from "./Components/IndexPage/Products/GREENN_ZYME";
import { GREEN_LUSH_L } from "./Components/IndexPage/Products/GREEN_LUSH_L";
import { SIZE_PLUS } from "./Components/IndexPage/Products/SIZE_PLUS";
import { GREEN_GOLD_40 } from "./Components/IndexPage/Products/GREEN_GOLD_40";
import { SIZE_SUPER } from "./Components/IndexPage/Products/SIZE_SUPER";
import { POTA_GREEN } from "./Components/IndexPage/Products/POTA_GREEN";
import { PETIOLE } from "./Components/IndexPage/Products/PETIOLE";
import { OurTeam } from "./Components/OurTeam/OurTeam";
import { MAGISTRATE_SP } from "./Components/IndexPage/Products/MAGISTRATE_SP";
import { CROP_KING } from "./Components/IndexPage/Products/CROP_KING";
import { ROOT_42_L } from "./Components/IndexPage/Products/ROOT_42_L";
import { LEGEND } from "./Components/IndexPage/Products/LEGEND";
import { SUPER_CAP } from "./Components/IndexPage/Products/SUPER_CAP";
import { P_D_C_L } from "./Components/IndexPage/Products/P_D_C_L";
import { AddtoCard } from "./Components/CommonComponents/AddtoCard";
import { Register } from "./Components/CommonComponents/Register";
import { ChangePassword, Login } from "./Components/CommonComponents/Account/ChangePassword";
import { Account } from "./Components/CommonComponents/Account/Account";
import { Orders } from "./Components/CommonComponents/Account/Orders";
import { Addresses } from "./Components/CommonComponents/Addresses/Addresses";
import { Checkout } from "./Components/CommonComponents/Checkout/Checkout";
import { Review } from "./Components/CommonComponents/Review/Review";
import { OrderTimeline } from "./Components/CommonComponents/Account/OrderTimeline";
import { OrderAll } from "./Components/CommonComponents/Account/OrderAll";
import { PrivacyNotice } from "./Components/PrivacyNotice/PrivacyNotice";
import { TermsCondition } from "./Components/PrivacyNotice/TermsCondition";
import { ForgotPassword } from "./Components/CommonComponents/ForgotPassword/ForgotPassword";
import { Approval } from "./Components/CommonComponents/ForgotPassword/Approval";
import { LoginPage } from "./Public/Login/LoginPage";
import { HeaderDashboard } from "./Private/DashboardPages/HeaderDashboard";
import { Sidebar } from "./Private/DashboardPages/Sidebar";
import Dashboard from "./Private/DashboardPages/Dashboard";
import { ProductCategory } from "./Private/DashboardPages/Master/ProductCategory/ProductCategory";
import { ProductMaster } from "./Private/DashboardPages/Master/ProductMaster/ProductMaster";
import { CompanyMaster } from "./Private/DashboardPages/Master/CompanyMaster/CompanyMaster";
import { CompanywiseProductMaster } from "./Private/DashboardPages/Master/CompanywiseProductMaster/CompanywiseProductMaster";
import { ProductPackageWiseUnitRateMaster } from "./Private/DashboardPages/Master/ProductPackageWiseUnitRateMaster/ProductPackageWiseUnitRateMaster";
import { OutletStoreMaster } from "./Private/DashboardPages/Master/OutletStoreMaster/OutletStoreMaster";
import { ConsumerMaster } from "./Private/DashboardPages/Master/ConsumerMaster/ConsumerMaster";
import { ProductInventory } from "./Private/DashboardPages/ProductInventory";
import { ProductsShipping } from "./Private/DashboardPages/ProductShipping/ProductsShipping";
import { PaymentDetails } from "./Private/DashboardPages/PaymentDetails/PaymentDetails";
import { ProductReviewAndRating } from "./Private/DashboardPages/ProductReviewAndRating";
import { EnquiryDetails } from "./Private/DashboardPages/EnquiryDetails/EnquiryDetails";
import { ProductOfferAndDiscounts } from "./Private/DashboardPages/ProductOfferAndDiscounts/ProductOfferAndDiscounts";
import { OrderManagement } from "./Private/DashboardPages/OrderManagement/OrderManagement";
import ReceiveOrder from "./Private/DashboardPages/OrderManagement/ReceiveOrder";
import SpecialOffers from "./Private/DashboardPages/ProductOfferAndDiscounts/SpecialOffers/SpecialOffers";
import ProductOffers from "./Private/DashboardPages/ProductOfferAndDiscounts/ProductOffers/ProductOffers";
import { ShippedOutProduct } from "./Private/DashboardPages/ProductShipping/ShippedOutProduct";
import { ReceivedPayment } from "./Private/DashboardPages/PaymentDetails/ReceivedPayment";
import OrderTracking from "./Private/DashboardPages/OrderTracking/OrderTracking";
import ReceivedEnquiries from "./Private/DashboardPages/EnquiryDetails/ReceivedEnquiries";
import ApprovedEnquiries from "./Private/DashboardPages/EnquiryDetails/ApprovedEnquiries";
import { ProductwisePackSizeMaster } from "./Private/DashboardPages/Master/ProductwisePackSizeMaster/ProductwisePackSizeMaster";
import { SchemeTypeMaster } from "./Private/DashboardPages/Master/SchemeTypeMaster/SchemeTypeMaster";
import { Goals } from "./Components/Goals/Goals";
import { Vertical } from "./Components/IndexPage/Vertical/Vertical";
import { PalviAgroChemicals } from "./Components/OurVerticals/PalviAgroChemicals";
import { PalviAgrico } from "./Components/OurVerticals/PalviAgrico";
import { PalviFarmFresh } from "./Components/OurVerticals/PalviFarmFresh";


export default function AllRoutes() {

    return (
        <>
            <div className="container-fluid ">
                <Router>
                    <Routes>
                        {/* <Route
                        exact path="/"
                        element={<LogIn />}
                    /> */}

                        <Route
                            exact path="/"
                            element={<IndexPage />}
                        />
                        <Route
                            exact path="/LoginPage"
                            element={<LoginPage />}
                        />
                        <Route
                            exact path="/About"
                            element={<About />}
                        />
                        <Route
                            exact path="/Goals"
                            element={<Goals />}
                        />
                        <Route
                            exact path="/Vision"
                            element={<Vision />}
                        />
                        <Route
                            exact path="/Mission"
                            element={<Mission />}
                        />
                        <Route
                            exact path="/Products"
                            element={<Products />}
                        />
                        <Route
                            exact path="/OurTeam"
                            element={<OurTeam />}
                        />
                        {/* <Route
                            exact path="/Vertical"
                            element={<Vertical />}
                        /> */}
                        <Route
                            exact path="/PalviAgroChemicals"
                            element={<PalviAgroChemicals />}
                        />
                        <Route
                            exact path="/PalviAgrico"
                            element={<PalviAgrico />}
                        />
                        <Route
                            exact path="/PalviFarmFresh"
                            element={<PalviFarmFresh />}
                        />
                        <Route
                            exact path="/HerosectionsAll"
                            element={<HerosectionsAll />}
                        />
                        <Route
                            exact path="/Contact"
                            element={<Contact />}
                        />
                        <Route
                            exact path="/HERBO_STRICK_Products"
                            element={<HERBO_STRICK_Products />}
                        />
                        <Route
                            exact path="/CYTO_BOOST_Products"
                            element={<CYTO_BOOST_Products />}
                        />
                        <Route
                            exact path="/ThreeD_WEED_Products"
                            element={<ThreeD_WEED_Products />}
                        />
                        <Route
                            exact path="/CHLOPAL_BOOST_Products"
                            element={<CHLOPAL_BOOST_Products />}
                        />
                        <Route
                            exact path="/Chlopal_Diamond_Products"
                            element={<Chlopal_Diamond_Products />}
                        />
                        <Route
                            exact path="/CHLOPAL_SUPER_Products"
                            element={<CHLOPAL_SUPER_Products />}
                        />
                        <Route
                            exact path="/CYMINT_Products"
                            element={<CYMINT_Products />}
                        />
                        <Route
                            exact path="/LEGEND"
                            element={<LEGEND />}
                        />
                        <Route
                            exact path="/P_D_C_L"
                            element={<P_D_C_L />}
                        />
                        <Route
                            exact path="/SUPER_CAP"
                            element={<SUPER_CAP />}
                        />
                        <Route
                            exact path="/JET_PLUS_Products"
                            element={<JET_PLUS_Products />}
                        />
                        <Route
                            exact path="/PARAPAL_Products"
                            element={<PARAPAL_Products />}
                        />
                        <Route
                            exact path="/PESTIGO_Products"
                            element={<PESTIGO_Products />}
                        />
                        <Route
                            exact path="/ROOT_42_F"
                            element={<ROOT_42_F />}
                        />
                        <Route
                            exact path="/ROOT_42_L"
                            element={<ROOT_42_L />}
                        />
                        <Route
                            exact path="/GREEN_LUSH_SP"
                            element={<GREEN_LUSH_SP />}
                        />
                        <Route
                            exact path="/Green_Spark_SP"
                            element={<Green_Spark_SP />}
                        />
                        <Route
                            exact path="/GANNA_POWER"
                            element={<GANNA_POWER />}
                        />
                        <Route
                            exact path="/AUSTINSUPER"
                            element={<AUSTINSUPER />}
                        />
                        <Route
                            exact path="/Maxima"
                            element={<Maxima />}
                        />
                        <Route
                            exact path="/GREENN_ZYME"
                            element={<GREENN_ZYME />}
                        />
                        <Route
                            exact path="/NUTRI_POWER"
                            element={<NUTRI_POWER />}
                        />
                        <Route
                            exact path="/GREEN_LUSH_L"
                            element={<GREEN_LUSH_L />}
                        />
                        <Route
                            exact path="/SIZE_PLUS"
                            element={<SIZE_PLUS />}
                        />
                        <Route
                            exact path="/SIZE_PLUS"
                            element={<SIZE_PLUS />}
                        />
                        <Route
                            exact path="/GREEN_GOLD_40"
                            element={<GREEN_GOLD_40 />}
                        />
                        <Route
                            exact path="/SIZE_SUPER"
                            element={<SIZE_SUPER />}
                        />
                        <Route
                            exact path="/POTA_GREEN"
                            element={<POTA_GREEN />}
                        />
                        <Route
                            exact path="/PETIOLE"
                            element={<PETIOLE />}
                        />
                        <Route
                            exact path="/MAGISTRATE_SP"
                            element={<MAGISTRATE_SP />}
                        />
                        <Route
                            exact path="/CROP_KING"
                            element={<CROP_KING />}
                        />
                        <Route
                            exact path="/ManufacturingUnit"
                            element={<ManufacturingUnit />}
                        />
                        <Route
                            exact path="/InvestorDetails"
                            element={<InvestorDetails />}
                        />
                        <Route
                            exact path="/AddtoCard"
                            element={<AddtoCard />}
                        />
                        <Route
                            exact path="/ChangePassword"
                            element={<ChangePassword />}
                        />
                        <Route
                            exact path="/ForgotPassword"
                            element={<ForgotPassword />}
                        />

                        <Route
                            exact path="/Register"
                            element={<Register />}
                        />
                        <Route
                            exact path="/Account"
                            element={<Account />}
                        />
                        <Route
                            exact path="/Orders"
                            element={<Orders />}
                        />
                        <Route
                            exact path="/Addresses"
                            element={<Addresses />}
                        />

                        <Route
                            exact path="/Checkout"
                            element={<Checkout />}
                        />
                        <Route
                            exact path="/Review"
                            element={<Review />}
                        />
                        <Route
                            exact path="/OrderTimeline"
                            element={<OrderTimeline />}
                        />
                        <Route
                            exact path="/OrderAll"
                            element={<OrderAll />}
                        />

                        <Route
                            exact path="/PrivacyNotice"
                            element={<PrivacyNotice />}
                        />

                        <Route
                            exact path="/TermsCondition"
                            element={<TermsCondition />}
                        />

                        <Route
                            exact path="/Approval"
                            element={<Approval />}
                        />


                        {/* dashboard */}

                        <Route
                            exact path="/Dashboard"
                            element={<Dashboard />}
                        />
                        <Route
                            exact path="/HeaderDashboard"
                            element={<HeaderDashboard />}
                        />
                        <Route
                            exact path="/Sidebar"
                            element={<Sidebar />}
                        />
                        <Route
                            exact path="/ProductCategory"
                            element={<ProductCategory />}
                        />
                        <Route
                            exact path="/ProductMaster"
                            element={<ProductMaster />}
                        />
                        <Route
                            exact path="/CompanyMaster"
                            element={<CompanyMaster />}
                        />
                        <Route
                            exact path="/CompanywiseProductMaster"
                            element={<CompanywiseProductMaster />}
                        />
                        <Route
                            exact path="/ProductwisePackSizeMaster"
                            element={<ProductwisePackSizeMaster />}
                        />
                        <Route
                            exact path="/SchemeTypeMaster"
                            element={<SchemeTypeMaster />}
                        />
                        <Route
                            exact path="/ProductPackageWiseUnitRateMaster"
                            element={<ProductPackageWiseUnitRateMaster />}
                        />
                        <Route
                            exact path="/OutletStoreMaster"
                            element={<OutletStoreMaster />}
                        />
                        <Route
                            exact path="/ConsumerMaster"
                            element={<ConsumerMaster />}
                        />
                        <Route
                            exact path="/EnquiryDetails"
                            element={<EnquiryDetails />}
                        />
                        <Route
                            exact path="/ProductInventory"
                            element={<ProductInventory />}
                        />
                        <Route
                            exact path="/ProductOfferAndDiscounts"
                            element={<ProductOfferAndDiscounts />}
                        />
                        <Route
                            exact path="/OrderManagement"
                            element={<OrderManagement />}
                        />
                        <Route
                            exact path="/ProductsShipping"
                            element={<ProductsShipping />}
                        />
                        <Route
                            exact path="/PaymentDetails"
                            element={<PaymentDetails />}
                        />
                        <Route
                            exact path="/ProductReviewAndRating"
                            element={<ProductReviewAndRating />}
                        />
                        <Route
                            exact path="/ReceiveOrder"
                            element={<ReceiveOrder />}
                        />
                        <Route
                            exact path="/SpecialOffers"
                            element={<SpecialOffers />}
                        />
                        <Route
                            exact path="/ProductOffers"
                            element={<ProductOffers />}
                        />
                        <Route
                            exact path="/ShippedOutProduct"
                            element={<ShippedOutProduct />}
                        />
                        <Route
                            exact path="/ReceivedPayment"
                            element={<ReceivedPayment />}
                        />
                        <Route
                            exact path="/OrderTracking"
                            element={<OrderTracking />}
                        />
                        <Route
                            exact path="/ReceivedEnquiries"
                            element={<ReceivedEnquiries />}
                        />
                        <Route
                            exact path="/ApprovedEnquiries"
                            element={<ApprovedEnquiries />}
                        />

                    </Routes>



                </Router>
            </div>
        </>
    )
}
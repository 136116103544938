import { useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../../Footer/Footer";
import AddressModal from "./Modal/AddressModal";
import { useState } from "react";
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";

export const Checkout = () => {

    const navigate = useNavigate()

    const [addAddressModal, setAddressModal] = useState(false)
    const handleAddressModal = () => {
        setAddressModal(false)
    }
    return (
        <>

            <HeaderEcommerce actives="Account" />

            <div className="row pb-5 px-lg-5" onLoad={() => window.scrollTo(0, 0)}>
                <h5 className="pt-4 pb-3 fw-bold">Checkout</h5>
                <div className="col-12 col-lg-8 ps-lg-0 ">
                    <div className="accordion shdows" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header fw-bold" id="headingOne">
                                <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    1. Select a delivery address
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body" style={{ background: '#f7ffed' }}>

                                    <div>
                                        {/* <h6 className="px-2  mb-1 fw-bold">Your  addresses </h6>
                                        <hr className=" mt-0 mb-2" /> */}

                                        <div className="row">
                                            <p className="mb-0">
                                                <b> Namita Pawar</b>
                                                <span className="float-end green cursor_pointer" onClick={() => { setAddressModal(true) }}>Edit</span>
                                            </p>
                                            <div className="col-12">
                                                <div class="form-check py-2">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault12" id="flexRadioDefault12" checked />
                                                    <label class="form-check-label fs-8 d-flex" for="flexRadioDefault12">
                                                        <div className="w-100">  103, A2, Pune, PUNE, MAHARASHTRA, 411041, India  103, A2, Pune, PUNE, MAHARASHTRA, 411041, India 103, A2, Pune, PUNE, MAHARASHTRA, 411041, India
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <p className="mb-0">
                                                <b> Namita Pawar</b>
                                                <span className="float-end green cursor_pointer" onClick={() => { setAddressModal(true) }}>Edit</span>
                                            </p>
                                            <div className="col-12">
                                                <div class="form-check py-2">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault12" id="flexRadioDefault2" />
                                                    <label class="form-check-label fs-8 d-flex" for="flexRadioDefault2">
                                                        <div className="w-100">  House no 3, Zarap Shirodkarwadi, SAWANTWADI, MAHARASHTRA, 416510, India
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>




                                        <hr className=" mt-0 mb-2" />

                                        <p className="p-2 fs-8 mb-0 cursor_pointer address_hover"
                                            onClick={() => { setAddressModal(true) }}>
                                            <span><i class="fa-solid fa-plus gray_color pe-2"></i></span> Add a new address</p>
                                    </div>

                                    <div>
                                        <h6 className="p-2 mb-0 fw-bold pt-3"> Your pickup locations </h6>
                                        <hr className=" mt-0 mb-2" />

                                        <p className="p-2 mb-0 cursor_pointer address_hover fs-8"> <span><i class="fa-solid fa-location-dot gray_color pe-2"></i></span> Find a pickup location near you </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    2. Payment method
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body" style={{ background: '#f7ffed' }}>
                                    {/* <h6 className="p-2  mb-0 fw-bold">Payment method </h6>
                                    <hr className=" mt-0 mb-2" /> */}
                                    <div className="row">

                                        <div class="form-check py-2">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                            <img src="Static/Assets/Img/credit-card.png" width={'30px'}  className="pe-1" alt="" />
                                            Credit or debit card
                                            </label>
                                        </div>
                                        <div class="form-check py-2">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                Net Banking
                                            </label>
                                        </div>
                                        <div class="form-check py-2">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label class="form-check-label" for="flexRadioDefault3">
                                            <img src="Static/Assets/Img/UPI.gif" width={'35px'} className="pe-1" alt="" /> UPI
                                            </label>
                                        </div>
                                        <div class="form-check py-2">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                            <label class="form-check-label" for="flexRadioDefault4">
                                            <img src="Static/Assets/Img/cash-on-delivery.png" width={'30px'}  className="pe-1" alt="" />
                                                Cash on Delivery (COD)
                                            </label>
                                        </div>
                                        <hr />
                                        <div className="my-0">
                                            <img src="Static/Assets/Img/visa_2.png" width={'45px'} alt="" />
                                            <img src="Static/Assets/Img/bhim_pay.png" width={'45px'} alt="" />
                                            <img src="Static/Assets/Img/maestro-logo_1.png" width={'45px'} alt="" />
                                            <img src="Static/Assets/Img/Master_card_1.png" width={'45px'} alt="" />
                                            <img src="Static/Assets/Img/RuPay.png" width={'50px'} alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    3. Offers
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Not Available</strong>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                    4. Review items and delivery
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>Arriving 13 Sept 2024</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-lg-4 shdows mt-3 mt-lg-0">
                    <div className="p-3 m-1">
                        <h5 className="green fw-bold">Price Details</h5>
                        <hr />

                        <div className="row">
                            <div className="col-12">
                                <p className="d-flex justify-content-between">Price (2 items) <span>₹1000</span></p>
                                <p className="d-flex justify-content-between">Discount <span className="text-danger">₹200</span></p>
                                <p className="d-flex justify-content-between">Delivery Charges <span>₹250</span></p>
                            </div>

                        </div>
                        <hr />
                        <p className="d-flex justify-content-between green fw-bold">Total Amount <span>₹1050.00</span></p>
                    </div>

                    <div className="row mx-auto">
                        <div className="col-1 mx-auto pt-2">
                            <img src="Static/Assets/Img/safe.png" alt="" className="" />
                        </div>
                        <div className="col-10 ps-4 mx-auto">
                            <span className="">
                                Safe and Secure Payments.  Easy returns. 100% Authentic products. </span>
                        </div>
                    </div>


                </div>
            </div>

            {addAddressModal ?
                <AddressModal
                    cancelbtn={handleAddressModal}
                    heading="Enter a new delivery address"
                /> : <></>

            }
            <Footer />



        </>
    )
}

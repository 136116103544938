import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"


export const InvestorDetails = () => {
    return (
        <>
            <div className="row " onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="InvestorDetails" />
                    <HerosectionsAll />

                    <div className="row p-lg-5 p-3"
                        style={{ background: '#dbebde' }}
                    >
                        <div className="col-12 col-lg-6 py-5 text-center">
                            <div className="pb-3">
                                <img src="Static/Assets/Img/manganga logo-01.png" class="Nitin bg-white rounded p-3" alt="" />

                                <h5 className="pt-4 fw-bold">Manganga Parivar </h5>
                                <p className="text-center text_dark">
                                    MBM Mall, First floor,   <br />Behind Bus Stand, Sangola-Pandharpur    <br /> road, Sangola, District-Solapur-413307</p>
                            </div>
                            <img src="Static/Assets/Img/Team/Nitin_Ingole-01.png" class="Nitin" alt="" />
                            <h5 className="pt-3 fw-bold">Mr. Nitin Ingole</h5>
                            <p className="text_dark text-center" style={{ textAlign: 'justify' }}>
                                MSc. Agri. <br />
                                Founder and Director  <br />
                                Manganga Parivar co operative credit society limited <br />
                            </p>



                        </div>

                        <div className="col-12 py-5 col-lg-6 text_dark">
                            <p><b>MANGANGA</b>  Group is a diversified business conglomerate committed to deliver ‘value for money’ propositions. The journey of <b>MANGANGA</b> Group started in 2010, when Shri. Nitin Ingole and his family members started their journey with small Dairy Collection Centre at Sangola ,Solapur District of Maharashtra State. With a desire to create a lasting and successful impact on thousands of consumers & farmers, <b>MANGANGA</b> Group today has presence in Finance, Food Processing, Dairy & allied products, Real Estate, Agro Chemicals etc.
                                The group comprises of following. </p>
                            <p> 1.	<b>MANGANGA</b> Parivar Co-Op Credit Society Solapur. </p>
                            <p> 2.	<b>MANGANGA</b> Dairy Industries. </p>
                            <p> 3.	<b>MANGANGA</b> Starch Private Limited. </p>
                            <p> 4.	<b>MANGANGA</b> Parivar Farmer Producer Company Ltd. </p>
                            <p> 5.	<b>MANGANGA</b> Agro Chemicals and Fertlizers. </p>
                            <b>MANGANGA</b> Group believes in sustained economic growth with due importance given to the environment and giving back to society, showcasing a holistic business expansion plan. As a part of our Social activities, the group is actively involved in various social activities like Tree plantation, aid to primary schools, seminars for educating farmers etc.
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}


import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const P_D_C_L = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/P.D.C.L-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3  pt-lg-5 mt-lg-5">P.D.C.-L</h4>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Composition :</h5>
                    <ul>
                        <li> Eugenic Acid, Carboxylate, Polysaccarides, Adjuvants..</li>
                        <li>P.D.C. is unique formulation of Eugenic acid, carboxylate, Polysaccharides. </li>
                        <li>It is very effective on Downey mildew, powdery mildew & anthracnose on grapes, vegetable, cereals, pulses & fruits crops.</li>
                        <li> It has a fast action to controls the active spores of fungus & bacteria.</li>

                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dose  :</h5>
                    <ul>
                        <li>
                            1.5 ml to 2.5 ml per lit of water.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Packing  :</h5>
                    <ul>
                        <li>
                            250 ml. 500 ml, 1 lit.
                        </li>
                    </ul>
                </div>
            </div>


            <Footer />


        </>
    )
}

import React, { useEffect } from "react";
import { ReactDDL } from "./CommonDDL/ReactDDL";

export const ProductReviewAndRating = () => {

    return (
        <>
            <h6 className="green pt-3" style={{ fontSize: '19px' }}> Product Review and Rating </h6>
            <div className="row bg-white pb-1 rounded shadow2">

                <div className="col-12 col-lg-3 p-3 ">
                    <div className=" px-3 pt-3 pb-1 gray_bgs">
                        <h6>Product Name</h6>
                        <h4 className="green">30
                            {/* <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ReceivedOrder.png" alt="profile" width={32} />
                            </span> */}
                        </h4>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-3">
                    <div className=" px-3 pt-3 pb-1 gray_bgs">
                        <h6>Product Name</h6>
                        <h4 className="green">30
                            {/* <span className="float-end">
                                <img src="Static/Assets/Img/Dashboard/ApprovedOrder.png" alt="profile" width={30} />
                            </span> */}
                        </h4>
                    </div>
                </div>

              

            </div>

        </>
    )
}
import { useNavigate } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce"
import { useState } from "react"

export const OrderTimeline = () => {
    const navigate = useNavigate()

    const [show, setShow] =useState(false);
    return (
        <>
            <HeaderEcommerce actives='Orders' />
            <div className="row py-5 px-2 px-lg-5" style={{ background: '#edecec' }} onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 bg-white">

                    <div className="row border rounded pt-3 pt-lg-4" >

                        <div className="col-3 col-md-1 text-center">
                            <img src="Static/Assets/Img/received.png" alt="" style={{ width: '50px' }} />

                        </div>
                        <div className="col-9 col-md-11 ">
                            <h6 className="mb-0">Order Placed</h6>
                            <span className="fs-8">Delivery by sat, 20th Sep</span>

                        </div>
                        <hr />
                        <div className="col-5 col-md-2 pt-3 ">
                            <img src="Static/Assets/Img/products/HERBO_STRICKs-01.png" alt="" className="products_order" />
                        </div>
                        <div className="col-7 col-md-3 pt-4">
                            <h6 className="">HERBO STRICK</h6>
                            <p className="fs-8 mb-0"> <b>Net Vol :</b> 1 Ltr.</p>
                            <h6 className="fw-bold pt-2 green">Rs.400</h6>
                        </div>

                        <div className="col-12 col-md-7 pt-2 ">
                            <section>
                                <nav>
                                    <ul class="ul-time">
                                        <li>
                                            <small className="fw-bold">Ordered </small>
                                            <a href="#" class="active" title="2000" data-name="2000">
                                                <div></div>
                                            </a>
                                            <small>8th Sep</small>
                                        </li>
                                        <li>
                                            <small className="fw-bold">Shipped</small>
                                            <a href="#" title="2003" data-name="2003">
                                                <div></div>
                                            </a>
                                            <small>11th Sep</small>
                                        </li>
                                        <li>
                                            <small className="fw-bold">Out for delivery</small>
                                            <a href="#" title="2005" data-name="2005">
                                                <div></div>
                                            </a>
                                            <small>16th Sep</small>
                                        </li>
                                        <li>
                                            <small className="fw-bold">Delivered</small>
                                            <a href="#" title="2008" data-name="2008">
                                                <div></div>
                                            </a>
                                            <small>20th Sep</small>
                                        </li>
                                    </ul>
                                    <div class="time">
                                        <small></small>
                                    </div>
                                </nav>
                            </section>
                            <p className="text-center">
                                <button type="button" class="btn_shopping mb-4" onClick={() => setShow(!show)}> <i className="pe-2 fa-regular fa-eye "></i> View Tracking Status </button>
                            </p>

                           {show && <div className="row px-5" >
                                <div className="col-12" >
                                    <div class="rightbox">
                                        <div class="rb-container">
                                            <ul class="rb">
                                                <li class="rb-item" ng-repeat="itembx">
                                                    <div class="timestamp">
                                                        Ordered
                                                        <p className="fs-8 mb-1 text-dark"> 8th Sep </p>
                                                    </div>
                                                    <div class="item-title">
                                                        Your Order has been placed.</div>
                                                    <div class="item-title">
                                                        Seller has processed your order.</div>
                                                    <div class="item-title">Item has been dispatched from the seller warehouse.</div>

                                                </li>
                                                <li class="rb-item" ng-repeat="itembx">
                                                    <div class="timestamp">
                                                        Shipped
                                                        <p className="fs-8 mb-1 text-dark"> 11th Sep </p>
                                                    </div>
                                                    <div class="item-title">Your item has been received in the hub nearest to you Ecom Express.</div>

                                                </li>

                                                <li class="rb-item" ng-repeat="itembx">
                                                    <div class="timestamp">
                                                        Out for delivery
                                                        <p className="fs-8 mb-1 text-dark"> 16th Sep </p>
                                                    </div>
                                                    {/* <div class="item-title">Lucas McAlister just send you a message.</div> */}
                                                </li>

                                                <li class="rb-item" ng-repeat="itembx">
                                                    <div class="timestamp">
                                                        Delivered
                                                        <p className="fs-8 mb-1 text-dark"> 20th Sep </p>
                                                    </div>
                                                    {/* <div class="item-title">Lucas McAlister just send you a message.</div> */}
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                        </div>


                    </div>


                </div>

                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <div className="row">
                        <div className="col-12 pt-3 ">
                            <h6>We aren't finding any cancelled orders (for orders placed in the last 6 months).<a className="fw-bold">View all orders</a> </h6>
                        </div>
                    </div></div>


            </div>
            <Footer />
        </>
    )
}



import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const GREEN_LUSH_L = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/GREEN_LUSH-L-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> GREEN LUSH-L </h4>
                    <h6 className="py-2 ps-3">
                        Green Lush L is extracted from Green kelp improves the uptake and translocation of
                        micro and micronutrient it improves vegetative and reproductive growth of the plants.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>In the process of the vegetative growth it maintain balance in xylem and phloem.</li>
                        <li>At the time of the reproductive growth it induces more flowering and fruiting in the
                            plant. </li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                            500 ML / Acre For Sparying. 1 Ltr / Acre for drenching.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            250ml, 500ml, 1ltr.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}

import { useState } from "react";
import { ReactDDL } from "../../CommonDDL/ReactDDL";
import AddPopUp from "../../EnquiryDetails/AddPopUp";
import AddPopUpSpecialOffers from "./AddPopUpSpecialOffers";
import { HeaderDashboard } from "../../HeaderDashboard";
import { Sidebar } from "../../Sidebar";
import { useSearchParams } from "react-router-dom";

const SpecialOffers = ({ cancelbtn, confirmBtnCallBack, deleteRecord, message, heading, }) => {

    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')

    const [addPopUpSpecialOffers, setaddPopUpSpecialOffers] = useState(false)
    const handleaddPopUpSpecialOffersClick = () => {
        setaddPopUpSpecialOffers(false)
    }
    return (<>

        <div className="container-scroller">
            <HeaderDashboard />
            <div className="container-fluid page-body-wrapper m-0">

                <Sidebar active='dashboard' />

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="card-body grid-margin">
                            <h6 className="d-flex justify-content-between green" style={{ fontSize: '19px' }}>{Name}
                                <button class="btn btn-success py-1" type="submit"
                                    onClick={() => { setaddPopUpSpecialOffers(true) }}
                                >
                                    <i class="fa-solid fa-plus pe-1"></i> Add</button>
                            </h6>

                            <div className="row p-2 m-0 bg-white rounded" >
                                <div className="bgcolor pb-2">
                                    <div className="row">
                                        <div class="my-1 col-12 col-md-4 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Date</label>
                                            <input type="date" class="form-control" />
                                        </div>

                                        <div class="my-1 col-12 col-md-4 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Month</label>
                                            <ReactDDL />
                                        </div>

                                        <div class="my-1 col-12 col-md-4 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Scheme Type</label>
                                            <ReactDDL />
                                        </div>

                                        <div class="my-1 col-12 col-md-4 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Company Name</label>
                                            <ReactDDL />
                                        </div>


                                        <div class="my-1 col-12 col-md-4 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Category</label>
                                            <ReactDDL />
                                        </div>


                                        <div class="my-1 col-12 col-md-4 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Product Name</label>
                                            <ReactDDL />
                                        </div>

                                    </div>
                                </div>



                                <div className="col-12 table_scroll p-0">
                                    <table className="table  text-center bg-white mt-2 " >
                                        <thead className="parrot_green">
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Scheme Type</th>
                                                <th>Offer Amounts</th>
                                                <th>Company Name</th>
                                                <th>Product Category</th>
                                                <th>Product Name</th>
                                                <th>Packing Size</th>
                                                <th>Unit Rate</th>
                                                <th>Quantity</th>
                                                <th>Total Amount</th>
                                                <th>Starting Date
                                                    <hr />
                                                    Closing Date</th>
                                                <th>Offer
                                                    <hr />Discount Details</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td> 1 </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>

            </div>
        </div>

        {addPopUpSpecialOffers ?
            <AddPopUpSpecialOffers
                cancelbtn={handleaddPopUpSpecialOffersClick}
                heading={Name}
            /> : <></>
        }
    </>);
}

export default SpecialOffers;

import { useNavigate, useSearchParams } from "react-router-dom";
import { HeaderEcommerce } from "../../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";
import { Footer } from "../../Footer/Footer";

export const Approval = () => {

    const navigate = useNavigate()

    return (
        <>
            <div className="row p-4 d-flex align-items-center justify-content-center" style={{ height: '100vh', background: '#edecec' }} onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-5  mx-auto  border2 shdows rounded p-3 p-lg-5 bg-white" >

                    <div className="text-center">
                        <img src="Static/Assets/Img/logo.png" onClick={() => navigate('/')} alt="logo here" className="logo_bg" />
                    </div>

                    <h5 className="py-2">Enter verification code</h5>
                    <p className="">For your security, we have sent the code to your phone ***-***-**12.</p>

                    <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="" />

                    <p className="pt-3 pb-0">
                        <a href="" className=""> Resend code</a>
                    </p>

                    <div className="">
                        <button type="button" class=" parrot_green text-white w-100 fw-bold border2 fs-5 py-2"
                            onClick={() => navigate('/Login')}
                        >Submit Code</button>
                    </div>

                    <h6 className="pt-3 fw-bold"> Need help? </h6>
                    <p className=""> If you cannot receive the code or if you changed your email or phone number,
                    <a href="javascript:void(0)" onClick={()=> navigate ('/Contact?ScreenName=Contact')}> try a different way.</a>
                    </p>
                </div>

                <p className="fs-6 text-center pt-3">
                    <span className="green text-decoration-underline cursor_pointer px-2" onClick={() => navigate('/PrivacyNotice')}> Privacy Notice </span> &
                    <span className="green text-decoration-underline cursor_pointer px-2" onClick={() => navigate('/TermsCondition')}> Terms and Condition </span></p>



            </div>


        </>
    )
}

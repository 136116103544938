import { ReactDDL } from "../../CommonDDL/ReactDDL";

const AddPopUpSchemeTypeMaster = ({ cancelbtn, confirmBtnCallBack, deleteRecord, message, heading }) => {

    return (<>
        <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
            <div className="modal-dialog modal-lg" role="document" >
                <div className="modal-content p-3">
                    <div className="modal-header pt-0">

                        <h5 className="card-title fw-bold" id="exampleModalLongTitle">{heading}</h5>
                        <button onClick={() => cancelbtn()} type="button" className="close" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0">

                        <div className="row">

                           
                            <div className="col-12 col-lg-6">
                                <label className="my-2 form-label">Scheme Type</label>
                                <input type="text" class="form-control" id="inputGroupFile02" />
                            </div>
                         
                        
                            <div className="row pt-4 text-center ">
                                <div className="col-12">
                                    <button
                                        type='button'
                                        onClick={cancelbtn}
                                        className="w-80 btn addbtn btn-success btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u m-2 px-4" data-ktwizard-type="action-next">
                                        <i class="fa-solid fa-floppy-disk pe-1"></i> Save
                                    </button>
                                    <button
                                        type="button"
                                        onClick={cancelbtn}
                                        className="w-80  btn addbtn btn-danger btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u m-2 px-4" data-ktwizard-type="action-next">
                                        <i class="fa-solid fa-xmark pe-1"></i> Cancel

                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default AddPopUpSchemeTypeMaster;

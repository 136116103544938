
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import $ from 'jquery'

export const Sidebar = ({ active, subMenu }) => {
    const navigate = useNavigate()
    const [MasterOpen, setMasterOpen] = useState(false)
    // const [AdminMasterOpen, setAdminMasterOpen] = useState(false)

    const [AdminTransaction, setAdminTransaction] = useState(false)
    // const [AdminTransactionScreenOpen, setAdminTransactionScreenOpen] = useState(false)


    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')


    var body = $('body');

    const SidebarHideShow = () => {
        if ((body.hasClass('sidebar-icon-only'))) {
            body.toggleClass('sidebar-icon-only');
        }
        // else {
        //     body.toggleClass('sidebar-icon-only');
        // }
    }

    const handelOnCardClick = (pathname, search) => {
        navigate({
            pathname,
            search
        })

        body.toggleClass('sidebar-icon-only');
    }

    return (
        <div className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">

                <li className={active === 'dashboard' ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/dashboard"
                        onClick={() => { setMasterOpen(false); setMasterOpen(false) }}>
                        <i class="fa-solid fa-gauge menu-icon"></i>
                        <span className="menu-title">Dashboard</span>
                    </Link>
                </li>

                <li className={MasterOpen || active === "Master" ? "nav-item active" : "nav-item"} onClick={SidebarHideShow}>
                    <a
                        className="nav-link"
                        data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic"
                        onClick={() => { setMasterOpen(!MasterOpen) }}
                    >
                        <i class="fa-solid fa-table-columns menu-icon"></i>
                        <span className="menu-title">Master </span>
                        <i class="fa-solid fa-chevron-down ps-5 menu-icon fs-7 menu-title"></i>

                    </a>
                    <div className={(MasterOpen || active === "Master") ? "collapse show" : "collapse hide"} id="ui-basic" style={{ height: MasterOpen ? '200px' : '0px' }}>
                        <ul className="nav flex-column sub-menu">

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductCategory", `?Name=Product Category`)}>
                                <li className={subMenu === "ProductCategory" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductCategory" ? "nav-link activeli" : "nav-link"}>Product Category</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductMaster", `?Name=Product Master`)}>
                                <li className={subMenu === "ProductMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductMaster" ? "nav-link activeli" : "nav-link"}>Product Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/CompanyMaster", `?Name=Company Master`)}>
                                <li className={subMenu === "CompanyMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "CompanyMaster" ? "nav-link activeli" : "nav-link"}>Company Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductwisePackSizeMaster", `?Name=Product wise Pack Size Master`)}>
                                <li className={subMenu === "ProductwisePackSizeMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductwisePackSizeMaster" ? "nav-link activeli" : "nav-link"}> Product wise Pack Size <br /> Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/CompanywiseProductMaster", `?Name=Company wise Product Master`)}>
                                <li className={subMenu === "CompanywiseProductMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "CompanywiseProductMaster" ? "nav-link activeli" : "nav-link"}>Company wise Product <br /> Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductPackageWiseUnitRateMaster", `?Name=Product Package Wise Unit Rate Master (Company wise)`)}>
                                <li className={subMenu === "ProductPackageWiseUnitRateMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductPackageWiseUnitRateMaster" ? "nav-link activeli" : "nav-link"}>Product Package Wise <br />Unit Rate Master<br /> (Company wise)</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/OutletStoreMaster", `?Name=Outlet Store Master`)}>
                                <li className={subMenu === "OutletStoreMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "OutletStoreMaster" ? "nav-link activeli" : "nav-link"}>Outlet Store Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/SchemeTypeMaster", `?Name=Scheme Type Master`)}>
                                <li className={subMenu === "SchemeTypeMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "SchemeTypeMaster" ? "nav-link activeli" : "nav-link"}>Scheme Type Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ConsumerMaster", `?Name=Consumer Master`)}>
                                <li className={subMenu === "ConsumerMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ConsumerMaster" ? "nav-link activeli" : "nav-link"}>Consumer Master</span>
                                </li>
                            </span>

                        </ul>
                    </div>
                </li>

                {/* <li className={active === 'Report' ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/Report"
                        onClick={() => { setMasterOpen(false); setAdminMasterOpen(false) }}>
                        <i class="fa-solid fa-gauge menu-icon"></i>
                        <span className="menu-title">Admin Transaction <br /> Screen </span>
                    </Link>
                </li> */}

                {/* <li className={AdminTransaction || active === "Master" ? "nav-item active" : "nav-item"}>
                    <a
                        className="nav-link"
                        data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic"
                        onClick={() =>{ setAdminTransaction(!AdminTransaction) }} 
                    >
                        <i class="fa-solid fa-table-columns menu-icon"></i>
                        <span className="menu-title">Admin Transaction <br /> Screen</span>
                    </a>
                    <div className={AdminTransaction ? "collapse show" : "collapse hide"} id="ui-basic" style={{ height: AdminTransaction ? '200px' : '0px' }}>
                        <ul className="nav flex-column sub-menu">

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductCategory", `?Name=Product Category`)}>
                                <li className={subMenu === "ProductCategory" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductCategory" ? "nav-link activeli" : "nav-link"}>Received Order-Order <br />Approval/Rejection</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductMaster", `?Name=Product Master`)}>
                                <li className={subMenu === "ProductMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductMaster" ? "nav-link activeli" : "nav-link"}>Product Shipping Updation</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/CompanyMaster", `?Name=Company Master`)}>
                                <li className={subMenu === "CompanyMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "CompanyMaster" ? "nav-link activeli" : "nav-link"}>Payment Acknowledge</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/CompanywiseProductMaster", `?Name=Company wise Product Master`)}>
                                <li className={subMenu === "CompanywiseProductMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "CompanywiseProductMaster" ? "nav-link activeli" : "nav-link"}>Product Replacement </span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductStockControl", `?Name=Product Stock Control`)}>
                                <li className={subMenu === "ProductStockControl" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductStockControl" ? "nav-link activeli" : "nav-link"}>Refund Payment</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ProductOfferandDiscounts", `?Name=`)}>
                                <li className={subMenu === "ProductOfferandDiscounts" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ProductOfferandDiscounts" ? "nav-link activeli" : "nav-link"}>Outlet Store Master</span>
                                </li>
                            </span>

                            <span className="cursor-pointer" onClick={() => handelOnCardClick("/ConsumerMaster", `?Name=Consumer Master`)}>
                                <li className={subMenu === "ConsumerMaster" ? "nav-item activeli" : "nav-item"}>
                                    <span className={subMenu === "ConsumerMaster" ? "nav-link activeli" : "nav-link"}>Consumer Master</span>
                                </li>
                            </span>
                        </ul>
                    </div>
                </li> */}


            </ul>
        </div>
    )
}


import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const PETIOLE = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/PETIOLE-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> PETIOLE <span className="fs-6">(Biostimulant)</span></h4>
                    <h6 className="py-2 ps-3">
                        Petiole is powerful biostimulant for enhanced yield when it applied it acts either by
                        Adsorption or absorption by plant cells.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>It results in various physiological and biochemical process resulting in increased of
                            functional molecules like enzymes vitamins etc which ultimately and lead to increased
                            productivity better quality and growth of plant and produce.</li>
                        <li>It produces more flowering and female flower development.</li>

                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                        500 ml. / acre for spraying
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                        250ml., 500ml, 1 ltr.
                        </li>
                    </ul>


                </div>

            </div>


            <Footer />


        </>
    )
}

import React, { useEffect } from "react";
import { ReactDDL } from "./CommonDDL/ReactDDL";

export const ProductInventory = () => {

    return (
        <>
            <h6 className="green pt-3" style={{ fontSize: '19px' }}> Product Inventory </h6>
            <div className="row bg-white pb-1 rounded shadow2">

                <div className="col-12 col-lg-4">
                    <label className="my-2 ">Store Name</label>
                    <ReactDDL />
                </div>

                <div className="col-12 col-lg-4">
                    <label className="my-2 ">Company Name</label>
                    <ReactDDL />
                </div>

                <div className="col-12 col-lg-4">
                    <label className="my-2 ">Product Category</label>
                    <ReactDDL />
                </div>

                <div className="col-12 table_scroll">
                    <table className="table  text-center bg-white mt-3 " >
                        <thead className="parrot_green">
                            <tr>
                                <th scope="col">Sr.No.</th>
                                <th scope="col">Company Name</th>
                                <th scope="col">Product Category</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Pack Size</th>
                                <th scope="col">Available Qty</th>
                                {/* <th scope="col">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td> 1 </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {/* <td>
                                    <i class="fa-regular fa-pen-to-square green cursor_pointer px-2"
                                        onClick={() => setaddPopUpProductMaster(true)}></i>
                                    <i class="fa-regular fa-trash-can text-danger px-2"
                                        onClick={() => setaddPopUpDelete(true)}></i>
                                </td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </>
    )
}
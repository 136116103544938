import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const Mission = () => {
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="Mission" subactive="About" />
                    <HerosectionsAll />
                    <div class="row pt-5 pb-lg-5 px-lg-5">
                        <div class="col-12 col-lg-6 pt-lg-5 order-lg-2 mb-5 d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Img/mission.jpg " className="vision_img" alt="" />
                        </div>
                        <div class="col-12 col-lg-6 pt-lg-5 p-4 pe-lg-5 p-lg-0">
                            <h3 class="fw-bold mb-4 pt-lg-4">Our Mission</h3>
                            <p class="" style={{ textAlign: 'justify' }}>To revolutionize agriculture by building a seamless, integrated ecosystem where every participant—farmers, distributors, dealers, retailers, and consumers—thrives. We aim to provide world-class solutions that enhance productivity, promote sustainability, and guarantee prosperity across the entire agricultural value chain.</p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

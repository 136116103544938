
import { Footer } from "../../Footer/Footer"
import { Header } from "../Header/Header"

export const NUTRI_POWER = () => {

    return (
        <>

            <Header actives="Products" />

            <div className="row px-lg-5" onLoad={() => window.scrollTo(0, 0)}>

                <div className="col-12 col-lg-6 p-2 pe-lg-4 overflow_products border shdows">
                    <img src="Static/Assets/Img/products/NUTRI_POWER-01.png" alt="" className="products_Alls" />
                </div>

                <div className="col-12 col-lg-6 p-5 overflow_products shdows">
                    <h4 className="text-danger fw-bold  ps-3 "> NUTRI POWER (ORGANIC CARBON)</h4>
                    <h6 className="py-2 ps-3">NUTRI POWER is a combination of organic carbon seaweed mass & humic acid mass. It
                        increses Photosynthesis rate in the plants.</h6>
                    <h5 className=" parrot_green py-2 ps-3 text-white border2">Benefits :</h5>
                    <ul>
                        <li>It covers the plant by supplying essential. nutrient and maintenance the xylem and
                            pholem
                            circulation in the plant.</li>
                        <li> It induces more flowering and fruiting. </li>
                        <li>It prevents shedding  of buds and flowers.</li>
                        <li> It maintain c: n Ratio in the soil.</li>
                        <li> It improves uptake and
                            translocation of macro and micronutrients.</li>
                        <li> It enhance crop Vigour and root growth.</li>
                    </ul>
                    <h5 className=" parrot_green py-2 ps-2 text-white border2">Crops :</h5>
                    <ul>
                        <li>
                            Recommended for all Crops.
                        </li>

                    </ul>
                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Dosage :</h5>
                    <ul>
                        <li>
                            500 ml/acre For Spraying 1 Lit/acre For Drenching.
                        </li>
                    </ul>

                    <h5 className=" parrot_green py-2  ps-3 text-white border2">Availability :</h5>
                    <ul>
                        <li>
                            500ml, 1 Ltr.
                        </li>
                    </ul>

                </div>

            </div>


            <Footer />


        </>
    )
}


import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const PalviFarmFresh = () => {
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="PalviFarmFresh" subactive="Verticle" />
                    <HerosectionsAll />
                    <div class="row align-items-center py-5 p-3 px-lg-5 mb-5 col-lg-11 mx-auto">
                        <div class="col-12 col-lg-5 pt-lg-5 mt-lg-4 d-flex  justify-content-center align-items-center">
                            <img src="Static/Assets/Img/farm.jpg" className="fertilizer_img"
                                alt="" />
                        </div>
                        <div class="col-12 col-lg-7 pt-lg-5 mt-lg-4 " >
                            <h3 class="fw-bold mb-2 green"> Palvi Farm Fresh </h3>
                            <p class="mb-3" style={{ textAlign: 'justify' }}>At Palvi Farm Fresh, we are transforming the fresh produce supply chain by working directly with farmers and multiple farmer producer companies or associations. We procure the freshest fruits and vegetables directly from these sources and deliver them to consumers, marketplaces, and institutional vendors like BigBasket. Our mission is to ensure that consumers receive fresh, high-quality produce while farmers, including those working with farmer producer companies, are compensated fairly and promptly.</p>
                            <h6 className="pb-1 text-danger fw-bold">What Makes Us Different?</h6>

                            <p>1. Freshness guaranteed: From farms and producer companies to consumers in record time.</p>
                            <p>2. Fair pricing that benefits both farmers and consumers. </p>
                            <p>3. Seamless supply chain management for institutional vendors and marketplaces.</p>
                            {/* <MoreBtn /> */}
                        </div>
                    </div>


                    <Footer />
                </div>
            </div>
        </>
    )
}

import { useNavigate } from "react-router-dom"

export const Navbar = () => {

    const navigate = useNavigate()

    return (
        <>


            <div className="row parrot_green text-white py-2 px-3">

                <div className="col-12 col-lg-6 pe-lg-5 " data-aos="zoom-in">
                    <span class="">
                        <a href="mailto:salespalviagrochemicals@gmail.com" className="text-white">
                            <i class="fa-solid fa-envelope  fs-6 pe-2"></i>
                            sales.palviagrochemicals@gmail.com</a>
                    </span>
                </div>

                <div className="col-12 col-lg-6  float-end" data-aos="zoom-in">
                    <p class="float-end pb-0 mb-0">
                        <i class="fa-solid fa-phone fs-7"></i><a href="tel:9325003938" className="text-white px-2"> +91 932 500 3938</a>
                        <i class="fa-solid fa-phone fs-7 ps-3"></i><a href="tel:7711804004" className="text-white px-2">+91 771 180 4004</a>
                    </p>
                </div>

            </div>


            {/* <div className="row parrot_green text-white py-2">

                <div className="col-12 col-lg-4 pe-lg-5  text-center">
                    <span class="">
                        <a href="mailto:salespalviagrochemicals@gmail.com" className="text-white">
                            <i class="fa-solid fa-envelope  fs-6 pe-2"></i>
                            sales.palviagrochemicals@gmail.com</a>
                    </span>
                </div>

                <div className="col-12 col-lg-5  text-center">
                    <span class="">
                        <a href="tel:9325003938" className="text-white px-2"> +91 932 500 3938</a>,
                        <a href="tel:7711804004" className="text-white px-2">+91 771 180 4004</a>
                    </span>
                </div>

                <div className="col-12 col-lg-3 text-center mb-0 ">
                    <p className="text-lg-end p-0 m-0">
                        <span>
                            <div class="dropdown">
                                <button class="dropbtn pe-lg-4"><img src="Static/Assets/Img/login.png" alt="" /> Login</button>
                                <div class="dropdown-content">
                                    <a href="#" className="pt-3 ps-3 pb-3" onClick={() => navigate('/LoginPage')}> <i class="fa-solid fa-pen-to-square pe-2"></i> Consumer</a>
                                    <a href="#" className="pt-2 pb-3 ps-3 border-top border-bottom" onClick={() => navigate('/LoginPage')}> <i class="fa-solid fa-user-tie pe-3"></i> Admin</a>
                                </div>
                            </div>
                        </span>
                    </p>

                </div>

            </div> */}
        </>
    )
}

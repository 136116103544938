
import { useSearchParams } from "react-router-dom";
import { HeaderDashboard } from "../HeaderDashboard";
import { ReactDDL } from "../CommonDDL/ReactDDL";
import { Sidebar } from "../Sidebar";
import AddPopUp from "./AddPopUp";
import { useState } from "react";

const ReceivedEnquiries = () => {

    const [searchParams] = useSearchParams()
    const Name = searchParams.get('Name')
    const [addPopUp, setaddPopUp] = useState(false)
    const handleaddPopUpClick = () => {
        setaddPopUp(false)
    }
    return (<>

        <div className="container-scroller">
            <HeaderDashboard />
            <div className="container-fluid page-body-wrapper m-0">

                <Sidebar active='dashboard' />

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="card-body grid-margin">
                            <h6 className="d-flex justify-content-between green" style={{ fontSize: '19px' }}>{Name}
                                <button class="btn btn-success py-1" type="submit"
                                    onClick={() => { setaddPopUp(true) }}
                                >
                                    <i class="fa-solid fa-plus pe-1"></i> Add</button>
                            </h6>

                            <div className="row p-2 m-0 bg-white rounded" >
                                <div className="bgcolor">
                                    <div className="row">
                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Enquiry Number</label>
                                            <ReactDDL />
                                        </div>
                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">Customer Name</label>
                                            <ReactDDL />
                                        </div>
                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">From Date</label>
                                            <input type="date" class="form-control" />
                                        </div>
                                        <div class="my-1 col-12 col-md-3 ">
                                            <label for="exampleFormControlInput1" class="form-label fw-bold fs-8">To Date</label>
                                            <input type="date" class="form-control" />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div class="input-group my-3">
                                                <input type="text" class="form-control" placeholder="Search..." />
                                                <span class="input-group-text text-white" id="basic-addon">
                                                    <i class="fa-solid fa-magnifying-glass"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 pt-2">
                                            <button
                                                type="button"
                                                // onClick={cancelbtn}
                                                className=" btn addbtn btn-danger m-2 px-3" data-ktwizard-type="action-next">
                                                <i class="fa-solid fa-xmark pe-1"></i> Cancel

                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 table_scroll p-0">
                                    <table className="table  text-center bg-white mt-2 " >
                                        <thead className="parrot_green">
                                            <tr>
                                                <th scope="col">Sr.No.</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Enquiry Number</th>
                                                <th scope="col">Customer Name</th>
                                                <th scope="col">Mobile Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Message</th>
                                                <th scope="col">Received through</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td> 1 </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><span className="fs-8 span_btn">Mail/Call Registration</span></td>
                                                <td><span className="fs-8 span_btn">Approval</span></td>
                                                {/* <td>
                                    <i class="fa-regular fa-pen-to-square green cursor_pointer px-2"
                                        onClick={() => setaddPopUpProductMaster(true)}></i>
                                    <i class="fa-regular fa-trash-can text-danger px-2"
                                        onClick={() => setaddPopUpDelete(true)}></i>
                                </td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        {addPopUp ?
            <AddPopUp
                cancelbtn={handleaddPopUpClick}
                heading={Name}
            /> : <></>
        }
    </>);
}

export default ReceivedEnquiries;
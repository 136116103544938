import React, { useEffect, useState } from "react";
import Select from 'react-select'

export const ReactDDL = () => {

    const [StateID, setStateID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const options = [
        { id: 1, value: '', label: 'Select' },
        { id: 2, value: '', label: 'Select' },
    ]



    return (
        <>
            <div className="row">
                <Select
                    id='StateID'
                    isClearable={false}
                    // isRtl={isRtl}
                    isSearchable
                    maxMenuHeight={130}
                    value={{ value: StateID.ID, label: StateID.Label }}
                    onChange={(e) => {
                        e ?
                            setStateID({ ...StateID, ID: e.value, Label: e.label })
                            :
                            setStateID({ ...StateID, ID: 0, Label: "Select..." })

                    }}
                    options={options}

                />

            </div>

        </>
    )
}
import { MoreBtn } from "../CommonBtn/MoreBtn"
import { Footer } from "../Footer/Footer"
import { HerosectionsAll } from "../HerosectionsAll/HerosectionsAll"
import { Header } from "../IndexPage/Header/Header"
import { Navbar } from "../IndexPage/Navbar/Navbar"

export const PalviAgroChemicals = () => {
    return (
        <>
            <div className="row" onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12">
                    <Navbar />
                    <Header actives="PalviAgroChemicals" subactive="Verticle" />
                    <HerosectionsAll />
                    <div class="row align-items-center py-5 p-3 px-lg-5 mb-5 col-lg-11 mx-auto">
                        <div class="col-12 col-lg-5 pt-lg-5 mt-lg-4 d-flex  justify-content-center align-items-center">
                            <img src="Static/Assets/Img/farm.jpg" className="fertilizer_img"
                                alt="" />
                        </div>
                        <div class="col-12 col-lg-7 pt-lg-5 mt-lg-4 " >
                            <h3 class="fw-bold mb-2 green">	Palvi Agro Chemicals and Fertilizers </h3>
                            <p class="mb-3" style={{ textAlign: 'justify' }}>We are the cornerstone of innovation in agrochemical manufacturing, offering cutting-edge solutions such as plant growth regulators and other agrochemicals. Serving 7 states—Maharashtra, Karnataka, Tamil Nadu, Kerala, Telangana, Andhra Pradesh, and Odisha—we provide farmers and agricultural businesses with products that guarantee higher yields, better quality crops, and long-term sustainability. Our 75 dedicated sales professionals work across the region, ensuring swift delivery and expert support.</p>
                            <h6 className="pb-1 text-danger fw-bold">Why Palvi Agro Chemicals?</h6>

                            <p>1. Products designed for maximum crop performance.</p>
                            <p>2. Wide state coverage with efficient logistics. </p>
                            <p> 3. Continuous R&D to create eco-friendly, high-yield agrochemicals.</p>
                            {/* <MoreBtn /> */}
                        </div>
                    </div>


                    <Footer />
                </div>
            </div>
        </>
    )
}

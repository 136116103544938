import { useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../Footer/Footer"
import { Header } from "../IndexPage/Header/Header"
import { useState } from "react";
import { HeaderEcommerce } from "../IndexPage/Products/HeaderEcommerce/HeaderEcommerce";

export const AddtoCard = () => {

    const [searchparams] = useSearchParams()
    const ScreenName = searchparams.get('ScreenName')

    const [count, setCount] = useState(1);

    const navigate = useNavigate()

    function increment() {
        //setCount(prevCount => prevCount+=1);
        setCount(function (prevCount) {
            return (prevCount += 1);
        });
    }

    function decrement() {
        setCount(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 0);
            }
        });
    }
    return (
        <>

            <HeaderEcommerce actives="AddtoCard" />

            <div className="row p-3 p-lg-5" onLoad={() => window.scrollTo(0, 0)} style={{ background: '#edecec' }}>

                <div className="col-12 ">
                    <h4 className="green fw-bold pb-1">CART SUMMARY</h4>
                    <h6 className="text-black pb-3 border4 pt-4"> <i className="fa-solid fa-circle-check text_blue green pe-1"></i> Cart updated.</h6>
                </div>

                <div className="col-12  table_scroll">
                    <table className="table text-center bg-white " >
                        <thead className="parrot_green">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Image</th>
                                <th scope="col">Product	</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total</th>
                                <th scope="col">Remove</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr >
                                <th> <input type="checkbox" id="yes" name="yes" value="yes" /> </th>
                                <th scope="row">
                                    <img class="pic-1" src="Static/Assets/Img/products/HERBO_STRICKs-01.png" style={{ width: '50px' }} />
                                </th>
                                <td>HERBO STRICK</td>
                                <td>₹500.00</td>
                                <td className="">
                                    <span className="borders p-2">
                                        <button className="px-3 bg-transparent py-1 border-0 fs-5" onClick={decrement}>-</button>
                                        <span className="px-3 fs-6">{count}</span>
                                        <button className="px-3 bg-transparent py-1 border-0 fs-5" onClick={increment}>+</button>
                                    </span>
                                </td>
                                <td>₹.500.00</td>
                                <td><i class="fa-regular fa-trash-can text_blue fw-bold green"></i></td>
                            </tr>

                            <tr>
                                <th> <input type="checkbox" id="yes" name="yes" value="yes" /> </th>
                                <th scope="row" className="p-0">
                                    <img class="pic-1" src="Static/Assets/Img/products/HERBO_STRICKs-01.png" style={{ width: '50px' }} />
                                </th>
                                <td>HERBO STRICK</td>
                                <td>₹500.00</td>
                                <td className="">
                                    <span className="borders p-2">
                                        <button className="px-3 bg-transparent py-1 border-0 fs-5" onClick={decrement}>-</button>
                                        <span className="px-3 fs-6">{count}</span>
                                        <button className="px-3 bg-transparent py-1 border-0 fs-5" onClick={increment}>+</button>
                                    </span>
                                </td>
                                <td>₹.500.00</td>
                                <td><i class="fa-regular fa-trash-can text_blue fw-bold green"></i></td>
                            </tr>

                            <tr>
                                <td colspan="5" className="fw-bold fs-5 text_blue py-4 green">Cart Totals</td>
                                <td colspan="1" className="fw-bold fs-5 text_blue py-4 green">1000.00</td>
                                <td className="border-0"></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="col-12 pt-3">
                    <p className="mt-4 ps-2 float-end">
                    <button type="button" class="btn_shopping mx-2"  onClick={() => navigate('/Products?ScreenName=Products')}><i class="fa-solid fa-cart-shopping pe-2"></i> Continue Shopping </button>
                    <button type="button" class="btn_shopping mx-2 mt-3 mt-md-0"  onClick={() => navigate('/LoginPage')}><i class="fa-solid fa-money-bill  pe-2"></i> Proceed to Payment </button>
                 
                    {/* <button type="button" class="btn_shoppnig mx-2 mt-3 mt-md-0"><i class="fa-solid fa-cart-shopping px-2 pe-3"></i> Proceed to Payment</button> */}
                        {/* <span className=" parrot_green pe-4 text-white border2 cursor-pointer" style={{ padding: '12px 5px' }}
                            onClick={() => navigate('/')}
                        >
                            <span><i class="fa-solid fa-cart-shopping px-2 pe-3"></i></span> Continue Shopping </span>

                        <span className=" parrot_green pe-4 text-white border2 cursor-pointer" style={{ padding: '12px 5px' }}
                            onClick={() => navigate('/Checkout')}
                        >
                            <span><i class="fa-solid fa-cart-shopping px-2 pe-3"></i></span> Proceed to Payment </span> */}

                    </p>
                </div>


            </div>


            <Footer />


        </>
    )
}

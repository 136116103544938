import { useNavigate, useSearchParams } from "react-router-dom"
import { Footer } from "../../Footer/Footer"
import { Review } from "../../CommonComponents/Review/Review"
import { Header } from "../Header/Header"

export const HERBO_STRICK_Products = () => {
    const navigate = useNavigate()
    const [searchparams] = useSearchParams()
    const ScreenName = searchparams.get('ScreenName')
    console.log(ScreenName);

    return (
        <>

            <Header actives="Products" />

            <div className="row  px-lg-5" onLoad={() => window.scrollTo(0, 0)}>
                {/* <h5 className="text-center pt-2 pb-3 fw-bold">{ScreenName}</h5> */}

                <div className="col-12 col-lg-6 p-2 border shdows overflow_products" >
                    <img src="Static/Assets/Img/products/HERBO_STRICKs-01.png" alt="" className="products_Alls" />
                    {/* <p style={{ position: 'absolute', top: '8px' }}>
                        <p className="img_discount py-3 px-3 text-center text-white" style={{ fontSize: '14px' }}>
                            <div>
                                10%
                            </div>
                            <div>Off</div>
                        </p>
                    </p> */}
                </div>

                {/* <div className="col-12 col-lg-4 p-2 shdows mt-3 mt-lg-0">
                    <div className="p-1  overflow_products2" style={{ background: '#edecec' }}>
                        <p className=" fw-bold  ps-3 pt-3 pt-lg-1 fs-6 text-danger"><span className="fw-bold text-dark">Company Name :</span> Palvi Agro Chemicals & Fertilizer</p>
                
                        <p className="text-dark ps-3  mb-2"><span className="fw-bold">Product Name :</span><span className="text-danger"> {ScreenName}</span></p>
                        <p className="text-dark ps-3  mb-2"><span className="fw-bold">Rs . </span> 400.00  <strike className="text-danger ps-2"> Rs . 500.00</strike></p>
                        <p className="text-dark  ps-3  mb-2"><span className="fw-bold">Net Vol :</span> 1 Ltr.</p>
                        <p className="text-dark ps-3 mb-2"> <span className="fw-bold">Type :</span> Liquid</p>
                        <div className="pb-1">
                            <p className="text-dark ps-3 "> <span className="fw-bold">Select Unit </span></p>
                            <button type="button" className=" parrot_green btn_width text-white fw-bold border2 mx-1 mx-lg-2">500 ML.</button>
                            <button type="button" className="btn_width hover_effect text-dark fw-bold border3 mx-1 mx-lg-2">1 Ltr.</button>
                            <button type="button" className="btn_width hover_effect text-dark fw-bold border3 mx- mx-lg-2">5 Ltr.</button>
                        </div>


                        <p className="mt-4 ps-2">
                            <span className=" parrot_green pe-3 text-white border2 cursor-pointer" style={{ padding: '12px 5px' }}
                                onClick={() => navigate('/AddtoCard')}>
                                <span><i class="fa-solid fa-cart-shopping px-2 pe-2"></i></span>  Add to Cart</span>

                            <span className=" green px-3 fw-bold border2 cursor-pointer ms-3 hover_effect" style={{ padding: '12px 5px' }}
                           >
                             <span><i class="fa-regular fa-heart pe-1 text-danger "></i></span> Wishlist</span>

                        </p>


                        <p className="text-dark ps-3 pt-2">
                            <span className="fw-bold">Rating : </span>
                            <span className="rounded parrot_green text-white py-1 px-2 ms-3" style={{ fontSize: '14px' }}> 4.2 <i class="fa-solid fa-star fs-7 ps-1"></i></span>
                        </p>


                    </div>

                </div> */}

                <div className="col-12 col-lg-6 p-2 overflow_products shdows mt-3 mt-lg-0">
                    <div className="p-2 px-3 p-lg-5" >
                        {/* <h4 className="text-danger fw-bold  fs-5">Product Details</h4> */}

                        <h4 className="pt-1 text-danger fw-bold ">{ScreenName}</h4>
                        <h6 className=" py-2">Glyphosate-41 % SL</h6>
                        <h5 className=" parrot_green py-2 text-white border2 ps-3">Specifications :</h5>
                        <ul className="ps-4">
                            <li>
                                It is Post Emergence Herbicide. Herbo Strick also kills problematic and difficult weeds
                                like sedges.
                            </li>
                            <li>
                                Herbo Strick can be used both as per plant application and inter-row application.
                            </li>
                            <li>
                                Herbo Strick should preferably be used when the weed in at 6-8 leaf stage and has
                                growth up to 6-8 inch height. Inactivated in control with the soil.
                            </li>

                        </ul>
                        <h5 className=" parrot_green py-2 ps-2 text-white border2">Category :</h5>
                        <ul className="ps-4">
                            <li>
                                Organophosphate group of Herbicide.
                            </li>

                        </ul>
                        <h5 className=" parrot_green py-2  ps-3 text-white border2">Mode of Action :</h5>
                        <ul className="ps-4">
                            <li>
                                Non selective, systemic Herbicide.
                            </li>

                        </ul>
                        <h5 className=" parrot_green py-2 ps-3 text-white border2">Compatibility :</h5>
                        <ul className="ps-4">
                            <li>
                                Mixing with other herbicide may reduce the activity of Herbo Kill
                            </li>

                        </ul>
                        <h5 className=" parrot_green py-2 ps-3 text-white border2">Phytotoxicity :</h5>
                        <ul className="ps-4">
                            <li>
                                When applied in recommended dose, it does not show phytotoxic effect.
                            </li>

                        </ul>
                        <h5 className=" parrot_green py-2 ps-3 text-white border2">Availability :</h5>
                        <ul className="ps-4">
                            <li>
                                500 ml, 1 ltr., 5 ltr.
                            </li>

                        </ul>
                    </div>
                </div>

            </div>
            {/* <Review /> */}

            <Footer />


        </>
    )
}

import { useNavigate, useSearchParams } from "react-router-dom"

import { useEffect, useState } from 'react';
import mixitup from 'mixitup'

export const OurProducts = () => {
    const navigate = useNavigate()

    const [searchparams] = useSearchParams()
    const ScreenName = searchparams.get('ScreenName')



    const [type, settype] = useState('All')
    useEffect(() => {
        mixitup(".items", {
            selectors: {
                target: ".item",
            },
            animation: {
                duration: 500
            }
        });
    }, []);

    return (
        <>

            <div className="row">

                <div className="col-12 ">
                    <h2 className="text-center fw-bold green pt-lg-5 pt-4" style={{ fontSize: '26px' }} data-aos="zoom-out-up">
                        <span className="text-danger">OUR</span> PRODUCTS</h2>
                </div>

            </div>

            <div className="row px-2 px-lg-4 pt-lg-4 products_search  pb-0">
                <div className="col-12 py-3 rounded  text-center" style={{ backgroundColor: '#01723b' }}>

                    <span onClick={(() => settype("All"))}
                        className={type == "All" ? "control mixitup-control  actives1 " : "withactive control mixitup-control "} data-filter="all">
                        All </span>

                    <span onClick={(() => settype("Herbicide"))}
                        className={type == "Herbicide" ? "control mixitup-control  actives1" : "withactive control mixitup-control "}
                        data-filter=".Herbicide"> Herbicide </span>

                    <span onClick={(() => settype("Insecticides"))}
                        className={type == "Insecticides" ? "control mixitup-control  actives1" : " withactive control mixitup-control"}
                        data-filter=".Insecticides"> Insecticides </span>

                    <span onClick={(() => settype("Fungicide"))}
                        className={type == "Fungicide" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".Fungicide"> Fungicide </span>

                    <span onClick={(() => settype("Weedicide"))}
                        className={type == "Weedicide" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".Weedicide"> Weedicide </span>

                    <span onClick={(() => settype("Antibiotic"))}
                        className={type == "Antibiotic" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".Antibiotic"> Antibiotic </span>

                    <span onClick={(() => settype("Seeds"))}
                        className={type == "Seeds" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".Seeds"> Seeds </span>

                    <span onClick={(() => settype("Fertilizer"))}
                        className={type == "Fertilizer" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".Fertilizer"> Fertilizer </span>

                    <span onClick={(() => settype("MicroNutirents"))}
                        className={type == "MicroNutirents" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".MicroNutirents"> Micro-Nutirents </span>

                    <span onClick={(() => settype("PlantGrowthRegulator"))}
                        className={type == "PlantGrowthRegulator" ? "control mixitup-control  actives1" : "withactive control mixitup-control"}
                        data-filter=".PlantGrowthRegulator"> Plant Growth Regulator </span>

                    <span onClick={(() => settype("PlantGrowthPromotor"))}
                        className={type == "PlantGrowthPromotor" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".PlantGrowthPromotor"> Plant Growth Promotor </span>

                    <span onClick={(() => settype("AgriEquipments"))}
                        className={type == "AgriEquipments" ? "control mixitup-control  actives1" : "withactive control mixitup-control   "}
                        data-filter=".AgriEquipments"> Agri Equipments </span>

                    <span onClick={(() => settype("AgriAliedProducts"))}
                        className={type == "AgriAliedProducts" ? "control mixitup-control  actives1 " : "withactive control mixitup-control   "}
                        data-filter=".AgriAliedProducts"> Agri Allied Products </span>

                </div>
            </div>


            {/* <div className="row px-2 px-lg-4 pt-lg-4 products_search  pb-0">
                    <div className="col-12 py-3 rounded text-center border2 text-white" style={{ backgroundColor: '#01723b' }}>
                        <span className=" fw-bold cursor_pointer" style={{borderBottom:'1px solid white'}}>All</span>
                        <span>Herbicide</span>
                        <span>Insecticides</span>
                        <span>Fungicide</span>
                        <span>Weedicide</span>
                        <span>Antibiotic</span>
                        <span>Seeds</span>
                        <span>Fertilizer</span>
                        <span>Micro-Nutirents</span>
                        <span>Plant Growth Regulator</span>
                        <span>Plant Growth Promotor</span>
                        <span>Agri Equipments</span>
                        <span>Agri Alied Products</span>

                    </div>
                </div> */}

            <div className="items row px-3 px-lg-4 mb-5">
                {/* <div className="col-12 col-lg-2 mt-lg-5 pt-3 border2 rounded" style={{ backgroundColor: '#ebebeb' }}>
                        <label for="exampleFormControlInput1" class="form-label text-dark fw-bold">Search Here</label>
                        <br />

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Search..." />
                            <span class="input-group-text" id="basic-addon2">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                        </div>

                        <hr className="mt-4" />

                        <div className=" px-1 pb-5">
                            <h5>Company Name</h5>

                            <div class="form-check pt-2 pb-3">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Palvi Agro Chemicals & Fertilizer
                                </label>
                            </div>

                            <div class="form-check pb-3">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                <label class="form-check-label" for="flexCheckChecked">
                                    Greenfied Hi-tech Agrotechnologies
                                </label>
                            </div>

                            <div class="form-check pb-3">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                <label class="form-check-label" for="flexCheckChecked2">
                                    Palvi Dairy And Agri Equipments
                                </label>
                            </div>

                        </div>
                    </div> */}

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-lg-3 mt-5 item Herbicide" data-aos="fade-up"
                            data-aos-duration="100">
                            <div className="shdows p-2 bounce" id="hover_effect" style={{ position: 'relative' }}>
                                <div className="bg_alls_pro cursor_pointer" onClick={() => navigate(`/HERBO_STRICK_Products?ScreenName=HERBO STRICK`)} >
                                    <img src="Static/Assets/Img/products/HERBO_STRICKs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">HERBO STRICK</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate(`/HERBO_STRICK_Products?ScreenName=HERBO STRICK`)} >Read More</button></p>

                                {/* <p style={{ position: 'absolute', top: '8px' }}>
                                    <p className="img_discount pt-1 pb-1 px-2 text-center text-white" style={{ fontSize: '13px' }}>
                                        <div>
                                            10%
                                        </div>
                                        <div>Off</div>
                                    </p>
                                </p> */}
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 mt-5 item Herbicide" data-aos="fade-up"
                            data-aos-duration="200">
                            <div className="shdows p-2 bounce" id="hover_effect" style={{ position: 'relative' }}>
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/3D WEEDs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">3D WEED</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate(`/ThreeD_WEED_Products?ScreenName=3D WEED`)}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item Insecticides  mt-5" data-aos="fade-up"
                            data-aos-duration="300">
                            <div className="shdows p-2 bounce" id="hover_effect" style={{ position: 'relative' }}>
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/CHLOPALBOOSTs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green ">CHLOPAL BOOST</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/CHLOPAL_BOOST_Products')}>Read More</button></p>
                                {/* <p style={{ position: 'absolute', top: '8px' }}>
                                    <p className="img_discount pt-1 pb-1 px-2 text-center text-white" style={{ fontSize: '13px' }}>
                                        <div>
                                            10%
                                        </div>
                                        <div>Off</div>
                                    </p>
                                </p> */}
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item Insecticides  mt-5"
                            data-aos="fade-up"
                            data-aos-duration="400">
                            <div className="shdows p-2 bounce" id="hover_effect" style={{ position: 'relative' }}>
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/ChlopalDiamonds-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">CHLOPAL DIAMOND</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/Chlopal_Diamond_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item Insecticides mt-5" data-aos="fade-up"
                            data-aos-duration="500">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/CHLOPALSUPERs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">CHLOPAL SUPER</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/CHLOPAL_SUPER_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item Insecticides  mt-5" data-aos="fade-up"
                            data-aos-duration="600">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/CYMINTs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">CYMINT</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/CYMINT_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthRegulator  mt-5" data-aos="fade-up"
                            data-aos-duration="700">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/CYTOBOOSTs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">CYTO BOOST</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/CYTO_BOOST_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item Insecticides mt-5" data-aos="fade-up"
                            data-aos-duration="800">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/JETPLUSs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">JET PLUS</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/JET_PLUS_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item Herbicide  mt-5" data-aos="fade-up"
                            data-aos-duration="900">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/PARAPALs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">PARAPAL</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/PARAPAL_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item Insecticides  mt-5" data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/PESTIGOs-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">PESTIGO</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold" onClick={() => navigate('/PESTIGO_Products')}>Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="500">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/MAGISTRATE_SP-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">MAGISTRATE SP</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/MAGISTRATE_SP')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="600">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/GREEN_LUSH_SP-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">GREEN LUSH SP</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/GREEN_LUSH_SP')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 mt-5 item PlantGrowthPromotor" data-aos="fade-up"
                            data-aos-duration="700">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/Green_Spark_SP-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">GREEN SPARK SP </p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/Green_Spark_SP')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="800">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/GANNA_POWER-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">GANNA POWER</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/GANNA_POWER')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="900">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/AUSTIN_SUPER-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">AUSTIN SUPER</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/AUSTINSUPER')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="1000">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/CROP_KING-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">CROP KING</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/CROP_KING')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="500">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/LEGEND-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">LEGEND</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/LEGEND')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="600">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/NUTRI_POWER-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">NUTRI POWER</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/NUTRI_POWER')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="700">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/P.D.C.L-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">P.D.C.-L</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/P_D_C_L')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="800">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/ROOT_42_L-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">ROOT 42-L</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/ROOT_42_L')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="900">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/MAXIMA-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">MAXIMA</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/Maxima')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="100">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/SUPER_CAP-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">SUPER CAP</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/SUPER_CAP')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="500">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/GREENN_ZYME-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">GREENN ZYME</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/GREENN_ZYME')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="600">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/GREEN_LUSH-L-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">GREEN LUSH-L </p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/GREEN_LUSH_L')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="700">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/PETIOLE-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">PETIOLE</p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/PETIOLE')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="800">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/SIZE_PLUS-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">SIZE PLUS </p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/SIZE_PLUS')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="900">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/POTA_GREEN-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">POTA GREEN </p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/POTA_GREEN')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 item PlantGrowthPromotor  mt-5" data-aos="fade-up"
                            data-aos-duration="500">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/SIZE_SUPER-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">SIZE SUPER </p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/SIZE_SUPER')}
                                >Read More</button></p>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3  item PlantGrowthPromotor mt-5" data-aos="fade-up"
                            data-aos-duration="600">
                            <div className="shdows p-2 bounce" id="hover_effect">
                                <div className="bg_alls_pro ">
                                    <img src="Static/Assets/Img/products/GREEN_GOLD_40-01.png" alt="" className="products_Alls" />
                                    <p className=" text-center text-white py-2 fw-bold parrot_green">GREEN GOLD-40 </p>
                                </div>
                                <p className="text-center mb-2">  <button type="button" class="btn btn-outline-success px-5 fw-bold"
                                    onClick={() => navigate('/GREEN_GOLD_40')}
                                >Read More</button></p>
                            </div>
                        </div>
                        {(type == "All" || type == "Herbicide") || type == "Insecticides" || type == "PlantGrowthRegulator" || type == "PlantGrowthPromotor" ?
                            <></> :
                            <div className="col-12 mx-auto col-md-5 item Seeds Fertilizer Antibiotic Fungicide Weedicide AgriAliedProducts AgriEquipments MicroNutirents Antibiotic text-center fw-bold">
                                <div className=" p-2 bounce" id="hover_effect">
                                    <div className=" ">
                                        <img src="Static/Assets/Img/products/noproduct.png" alt=""
                                            className="" style={{ width: '100%' }} />
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

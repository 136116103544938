import { useNavigate } from "react-router-dom"

export const Vertical = () => {
    const navigate = useNavigate()
    return (
        <>
            <div class="row px-lg-5 align-items-center py-5 p-3 " id="">

                <div class="col-lg-12 ">
                    <h2 className=" pb-3 pt-5 pt-lg-0 fw-bold green text-center" data-aos="fade-up" style={{ fontSize: '26px' }}>
                        <span className="text-danger">Vertical  </span> Overview</h2>

                    <div className="row">

                        <div className="col-12 col-lg-4  p-4"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="300">
                            <div className="border shadow2 agrico cursor_pointer text-center bounce" id="hover_effect">
                                <img src="Static/Assets/Img/gallery/palvi_agro-01.png"
                                    style={{ width: '50%' }} className="py-3" alt=""
                                    onClick={() => navigate('/PalviAgroChemicals?ScreenName=Palvi Agro Chemicals and Fertilizers')} />
                                <div className="bg-white p-4">
                                    <h5 className="fw-bold green  pb-2">1.	Palvi Agro Chemicals and Fertilizers</h5>
                                    <p className="" style={{ textAlign: 'justify' }}>Innovative agrochemicals and plant growth regulators driving sustainability and productivity in 7 states across India.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4  p-4"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="400">
                            <div className="border shadow2 agrico cursor_pointer text-center bounce" id="hover_effect"
                                onClick={() => navigate('/PalviAgrico?ScreenName=Palvi Agrico')}>
                                <img src="Static/Assets/Img/gallery/palvo_agrico-01.png"
                                    style={{ width: '50%' }} className="py-3" alt="" />
                                <div className="bg-white p-4">
                                    <h5 className="fw-bold green pb-2">2.	Palvi Agrico</h5>
                                    <p className="" style={{ textAlign: 'justify' }}>A pioneering eCommerce platform delivering branded agricultural products to rural distributors, dealers, & retailers with fast, free delivery & digital support.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4  p-4"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="500">
                            <div className=" shadow2 agrico cursor_pointer text-center bounce" id="hover_effect"
                                onClick={() => navigate('/PalviFarmFresh?ScreenName=Palvi Farm Fresh')}>
                                <img src="Static/Assets/Img/gallery/palvi_agro-01.png"
                                    style={{ width: '50%' }} className="py-3" alt="" />
                                <div className="bg-white p-4">
                                    <h5 className="fw-bold green  pb-2">3. Palvi Farm Fresh</h5>
                                    <p className="" style={{ textAlign: 'justify' }}>Sourcing fresh fruits & vegetables directly from farmers & farmer producer companies, ensuring fresh delivery to consumers & fair pricing for farmers.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="row p-5" style={{ backgroundColor: '#ebebeb' }}>
                <div className="col-12 col-lg-4 text-center pt-lg-5 mt-lg-4" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                    <img src="Static/Assets/Img/Team/mdphoto-01.png" width={190} id="hover_effect" class="bounce" alt="" />
                    <h3 className="pt-3 fw-bold green">Rahul Jadhav</h3>
                    <p> <b>Managing Director,</b> <br /> Palvi Agro Chemicals and Fertilizers</p>
                    {/* <h3 className="fw-bold pt-3">A Message from the <br /><b className="green"> Managing Director</b></h3> */}
                </div>
                <div className="col-12 col-lg-8  mx-auto py-3 rounded px-lg-5 bg-white" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom" style={{ textAlign: 'justify' }}>
                    <h3 className="pt-3 pb-3 text-dark">A Message from the <b className="green"> Managing Director</b></h3>
                    <p> <b className="fs-2 green">"</b>  In today’s fast-changing agricultural landscape, our goal at Palvi Agro Chemicals and Fertilizers is simple—to create an ecosystem where every stakeholder thrives. Whether it’s through our innovative agrochemical products, a seamless eCommerce experience, or the freshest farm produce, we believe in unlocking potential at every stage of the agricultural process.</p>
                    <p>As we grow, so too does our commitment to sustainability, technology, and prosperity for all. At Palvi, we don’t just see agriculture as a business—we see it as a way of life. Our three verticals are built on the principles of quality, innovation, and inclusivity, and I am proud to lead a team that is as passionate about making a difference as I am.</p>
                    <p>To our partners, customers, and farmers: thank you for being a vital part of this exciting journey. Together, we are sowing the seeds of a brighter, more sustainable future in agriculture. <b className="fs-2 green">"</b></p>
                </div>
            </div>



        </>
    )
}
